import React, { useRef, useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllNews } from "../../redux/actions";
import { Link } from "react-router-dom";

const News = () => {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const itemsPerPage = 6;

  const news = useSelector((state) => state.allNews);

  const newsArr = news?.sort((a, b) => b.id - a.id);
  console.log(newsArr);

  const totalPages = Math.ceil(news.length / itemsPerPage);

  const paginate = (pageNumber) => {
    setTimeout(() => {
      window.scroll(0, 0);
    }, 0);
    setPage(pageNumber);
  };

  const nextPage = () => {
    setTimeout(() => {
      window.scroll(0, 0);
    }, 0);
    setPage((prevPage) => prevPage + 1);
  };

  const prevPage = () => {
    setTimeout(() => {
      window.scroll(0, 0);
    }, 0);
    setPage((prevPage) => prevPage - 1);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();

    const formattedDay = day < 10 ? `0${day}` : day;
    const formattedMonth = month < 10 ? `0${month}` : month;

    return `${formattedDay}/${formattedMonth}/${year}`;
  };

  useEffect(() => {
    setLoading(true);
    dispatch(getAllNews())
      .then(() => {
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });

    window.scrollTo(0, 0);
  }, [dispatch]);

  const startIndex = (page - 1) * itemsPerPage;
  const endIndex = page * itemsPerPage;

  return (
    <>
      <div className="container mt-3 p-4 bg-info d-flex flex-column">
        <h2 className="w-100 bg-info text-white m-0">NOTICIAS</h2>
        <span
          style={{ width: "100%", height: "3px", backgroundColor: "#fff" }}
        ></span>
      </div>
      {loading ? (
        <div className="container py-5 text-center">
          <p className="">Cargando...</p>
        </div>
      ) : (
        <div className="popular-cources pc2">
          <div className="container ">
            {news.length === 0 ? (
              <div>
                <h2 className="text-center w-100">Noticias no disponibles</h2>
              </div>
            ) : (
              <div className="row courc-ro">
                {newsArr.slice(startIndex, endIndex).map((n) => (
                  <div className="col-md-4" key={n.id}>
                    <div className="courc-card">
                      <img src={n.imagen} alt="" />
                      <div className="cource-det">
                        <div className="header-card">
                          <Link to={`/noticias/${n.id}`} className="text-info">
                            <h6 className="m-0">{n.titulo}</h6>
                            <span className="pt-1">{formatDate(n.fecha)}</span>
                          </Link>
                        </div>
                        <ul>
                          <li className="px-0">
                            <div
                              dangerouslySetInnerHTML={{ __html: n.resumen }}
                            />
                          </li>
                        </ul>
                        <div className="text-right">
                          <Link
                            to={`/noticias/${n.id}`}
                            className="text-primary"
                          >
                            Leer más
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}

            <div className="d-flex justify-content-center mt-4">
              <button
                className="btn btn-secondary mx-1"
                onClick={prevPage}
                disabled={page === 1}
              >
                Anterior
              </button>

              {[...Array(totalPages)].map((_, index) => (
                <button
                  key={index}
                  className={`btn btn-secondary mx-1 ${
                    page === index + 1 ? "active" : ""
                  }`}
                  onClick={() => paginate(index + 1)}
                >
                  {index + 1}
                </button>
              ))}

              <button
                className="btn btn-secondary mx-1"
                onClick={nextPage}
                disabled={page === totalPages}
              >
                Siguiente
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default News;
