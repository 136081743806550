import React from "react";
import { checkSVG } from "../../../assets/svg";

const Estructura = () => {
  return (
    <>
      <div className="">
        <div className="pb-5 border-bottom">
          <p className="py-2">
            De acuerdo a los lineamientos de la Misión Alma Mater del Ministerio
            del Poder Popular para la Educación Ministerio del Poder Popular
            para la Educación Universitaria, Ciencia y Tecnología (MPPEUCT), el
            Programa Nacional de Formación trascurre en cinco trayectos. Un
            trayecto Inicial, preparatorio para la asunción de los estudios
            superiores, sin carga crediticia, de diez semanas de duración y
            cuatro trayectos, de dos lapsos entre 14 y 16 semanas cada uno.
          </p>

          <p className="py-2">
            A lo largo de cada lapso se desarrolla un proyecto artístico
            comunitario, junto a unidades curriculares que garantiza la
            formación integral del egresado contemplada en los siguientes ejes
            transversales: 1) Epistemológico-Heurístico; 2)
            Socio-Histórico-Político; 3) Estético-Lúdico; 4) Ambiental; 5)
            Transdisciplinario; 6) Disciplinario.
          </p>

          <p className="py-2">
            Los Trayectos pares, 2 y 4, son conducentes a títulos académicos
            universitarios. El (2) Técnico Superior Universitario y el (4)
            Licenciado.
          </p>
        </div>

        <div className="pb-5 border-bottom">
          <h5 className="text-center text-info py-5">EJES DE FORMACIÓN</h5>

          <div id="accordion">
            <div class="">
              <div class="p-3" id="heading">
                <h6 class="mb-0">
                  <button
                    class="bg-transparent border-0"
                    data-toggle="collapse"
                    data-target="#collapse"
                    aria-expanded="true"
                    aria-controls="collapse"
                  >
                    Trayecto Inicial
                  </button>
                </h6>
              </div>

              <div
                id="collapse"
                class="collapse show"
                aria-labelledby="heading"
                data-parent="#accordion"
              >
                <div class="card-body">
                  <p className="pb-3">
                    Preparatorio para la asunción de los estudios superiores,
                    sin carga crediticia y una duración de diez semanas. Tiene
                    como propósito facilitar el tránsito de la educación media a
                    la educación universitaria. Uno de los objetivos del
                    trayecto inicial es la conformación y fortalecimiento de
                    grupos de estudio.
                  </p>
                  <ul className="list-unstyled pb-3">
                    <p className="pb-2">Unidad Curricular:</p>
                    <li className="">
                      <p className="pl-5">• Artes Audiovisuales</p>
                      <p className="pl-5">• Artes Plásticas</p>
                      <p className="pl-5">• Danza</p>
                      <p className="pl-5">• Educación para las Artes</p>
                      <p className="pl-5">
                        • Formación Socio Crítica para la Creación Artística
                      </p>
                      <p className="pl-5">• Música</p>
                      <p className="pl-5">• Teatro</p>
                      <p className="pl-5">• Texto, Palabra y Conocimiento</p>
                      <p className="pl-5">
                        • UNEARTE en Diálogo: Introducción al Programa de Grado
                      </p>
                    </li>
                  </ul>
                  <p>
                    La unidad curricular depende del PNF al que el estudiante
                    quedó adscrito.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div id="accordion">
            <div class="">
              <div class="p-3" id="heading">
                <h6 class="mb-0">
                  <button
                    class="bg-transparent border-0"
                    data-toggle="collapse"
                    data-target="#collapse2"
                    aria-expanded="true"
                    aria-controls="collapse2"
                  >
                    Epistemológico-Heurístico
                  </button>
                </h6>
              </div>

              <div
                id="collapse2"
                class="collapse show"
                aria-labelledby="heading"
                data-parent="#accordion"
              >
                <div class="card-body">
                  <p className="pb-3">
                    Destinado a la capacidad que tenemos los humanos de
                    descubrir cosas, inventar y resolver problemas mediante la
                    creatividad o el pensamiento. Punto de encuentro para los
                    estudiantes para fortalecimiento de la investigación.
                  </p>
                  <ul className="list-unstyled">
                    <p className="pb-2">Unidad Curricular:</p>
                    <li className="">
                      <p className="pl-5">• Expresión Oral y Escrita I</p>
                      <p className="pl-5">
                        • Fundamentos Filosóficos y Estéticos
                      </p>
                      <p className="pl-5">• Idioma Extranjero I</p>
                      <p className="pl-5">• Idioma Extranjero II</p>
                      <p className="pl-5">• Metodología de la Investigación</p>
                      <p className="pl-5">
                        • Metodología de la Investigación II
                      </p>
                      <p className="pl-5">
                        • Proyecto Integral de Investigación
                      </p>
                      <p className="pl-5">• Seminario de Investigación</p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div id="accordion">
              <div class="">
                <div class="p-3" id="heading">
                  <h6 class="mb-0">
                    <button
                      class="bg-transparent border-0"
                      data-toggle="collapse"
                      data-target="#collapse3"
                      aria-expanded="true"
                      aria-controls="collapse3"
                    >
                      Socio-Histórico Político
                    </button>
                  </h6>
                </div>

                <div
                  id="collapse3"
                  class="collapse show"
                  aria-labelledby="heading"
                  data-parent="#accordion"
                >
                  <div class="card-body">
                    <p className="pb-3">
                      Este eje se enmarca en la necesidad de generar espacios de
                      discusión acerca del acontecer cotidiano, los cambios
                      políticos y sociales a los cuales está sometido el
                      ciudadano, el principal propósito es brindarle
                      herramientas al estudiante para que se convierta en un
                      agente de cambio con información adecuada para la mejor
                      comprensión del entorno cultural del artista en formación.
                    </p>
                    <ul className="list-unstyled">
                      <p className="pb-2">Unidad Curricular:</p>
                      <li className="">
                        <p className="pl-5">• Apreciación Literaria</p>
                        <p className="pl-5">• Arte y Cultura Venezolana</p>
                        <p className="pl-5">• Teorías e Historia del Arte</p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <div id="accordion">
              <div class="">
                <div class="p-3" id="heading">
                  <h6 class="mb-0">
                    <button
                      class="bg-transparent border-0"
                      data-toggle="collapse"
                      data-target="#collapse4"
                      aria-expanded="true"
                      aria-controls="collapse4"
                    >
                      Estético Lúdico
                    </button>
                  </h6>
                </div>

                <div
                  id="collapse4"
                  class="collapse show"
                  aria-labelledby="heading"
                  data-parent="#accordion"
                >
                  <div class="card-body">
                    <p className="pb-3">
                      El Eje Estético Lúdico influye significativamente en el
                      proceso de desarrollo holístico del individuo y su entorno
                      social, mediante las múltiples situaciones de aprendizaje
                      que basadas en la exploración, la experimentación, y el
                      autodescubrimiento que éstas brindan generan una formación
                      integral.
                    </p>
                    <ul className="list-unstyled">
                      <p className="pb-2">Unidad Curricular:</p>
                      <li className="">
                        <p className="pl-5">
                          • Proyecto Artístico Comunitario I
                        </p>
                        <p className="pl-5">
                          • Proyecto Artístico Comunitario II
                        </p>
                        <p className="pl-5">
                          • Proyecto Artístico Comunitario III
                        </p>
                        <p className="pl-5">
                          • Proyecto Artístico Comunitario IV
                        </p>
                        <p className="pl-5">
                          • Proyecto Artístico Comunitario V
                        </p>
                        <p className="pl-5">
                          • Proyecto Artístico Comunitario VI
                        </p>
                        <p className="pl-5">
                          • Proyecto Artístico Comunitario VII
                        </p>
                        <p className="pl-5">
                          • Proyecto Artístico Comunitario VIII
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <div id="accordion">
              <div class="">
                <div class="p-3" id="heading">
                  <h6 class="mb-0">
                    <button
                      class="bg-transparent border-0"
                      data-toggle="collapse"
                      data-target="#collapse5"
                      aria-expanded="true"
                      aria-controls="collapse5"
                    >
                      Estético Ambiental
                    </button>
                  </h6>
                </div>

                <div
                  id="collapse5"
                  class="collapse show"
                  aria-labelledby="heading"
                  data-parent="#accordion"
                >
                  <div class="card-body">
                    <p className="pb-3">
                      Responde al saber sobre el ambiente; aquí se pretende que
                      el educando comprenda que el entorno es el resultado de la
                      interacción, tanto de fenómenos naturales como de las
                      acciones humanas, y que para estudiarlo se requiere
                      conocer los aspectos físico naturales y los factores
                      sociales. Esta dimensión ofrece la oportunidad al
                      estudiante de discutir problemas que han llegado a
                      situaciones críticas y conocer sus causas.
                    </p>
                    <ul className="list-unstyled">
                      <p className="pb-2">Unidad Curricular:</p>
                      <li className="">
                        <p className="pl-5">
                          • Bioética y Modelos de Desarrollo
                        </p>
                        <p className="pl-5">
                          • Saberes Populares y Tradiciones
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div>
          <h5 className="text-center text-info py-5">
            PROYECTO ARTÍSTICO COMUNITARIO
          </h5>

          <p className="py-2">
            Para la Misión Alma Mater el corazón de los Programas Nacionales de
            Formación de la Unearte es el Proyecto Artístico Comunitario. A
            través de éste se pretende lograr la integración multidimensional de
            los saberes y conocimientos.
          </p>

          <p className="py-2">
            En su aplicación se concretan las disposiciones legales de la Nación
            para la resolución de problemas, el desarrollo de potencialidades y
            el mejoramiento de la calidad de vida de las comunidades, las
            regiones y el país.
          </p>

          <p className="py-2">
            El espacio comunitario se constituye de este modo, en un lugar
            privilegiado para la participación desde lo cultural, por su
            diversidad y la presencia de grupos organizados que hacen de sus
            prácticas culturales populares, un punto de encuentro para la
            intersubjetividad y expresión desde una necesidad sentida de
            comunicar lo que viven y hacen
          </p>

          <div>
            <div id="accordion">
              <div class="">
                <div class="p-3" id="heading">
                  <h6 class="mb-0">
                    <button
                      class="bg-transparent border-0"
                      data-toggle="collapse"
                      data-target="#collapse6"
                      aria-expanded="true"
                      aria-controls="collapse6"
                    >
                      Objetivo
                    </button>
                  </h6>
                </div>

                <div
                  id="collapse6"
                  class="collapse show"
                  aria-labelledby="heading"
                  data-parent="#accordion"
                >
                  <div class="card-body">
                    <p className="pb-3">
                      {checkSVG} Incidir en la transformación de las condiciones
                      y calidad de vida de la ciudadanía a partir de propuestas
                      sistematizadas de carácter artístico mediante el vínculo
                      comunitario, la elaboración, ejecución y evaluación de
                      proyectos transformadores.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div id="accordion">
              <div class="">
                <div class="p-3" id="heading">
                  <h6 class="mb-0">
                    <button
                      class="bg-transparent border-0"
                      data-toggle="collapse"
                      data-target="#collapse7"
                      aria-expanded="true"
                      aria-controls="collapse7"
                    >
                      Lo que implica
                    </button>
                  </h6>
                </div>

                <div
                  id="collapse7"
                  class="collapse show"
                  aria-labelledby="heading"
                  data-parent="#accordion"
                >
                  <div class="card-body">
                    <p className="mb-3">
                      {checkSVG} Entender el rol social del arte y de la
                      creación artística como herramientas para la elevación de
                      la conciencia.
                    </p>

                    <p className="pb-3">
                      {checkSVG} Desarrollar habilidades y competencias para el
                      diagnóstico, gestión, diseño y producción de propuestas
                      artísticas de vinculación social.
                    </p>

                    <p className="pb-3">
                      {checkSVG} Trabajar conjuntamente con la comunidad en:
                      resolución de problemas, diagnóstico de situaciones,
                      satisfacer necesidades culturales, promoción de
                      potencialidades en temas de investigación para el trabajo
                      articulado y protagónico del colectivo para el desarrollo
                      local.
                    </p>

                    <p className="pb-3">
                      {checkSVG} Promover aprendizajes colectivos que conduzcan
                      a una formación ciudadana y a la reconstitución del
                      sentido común.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div id="accordion">
              <div class="">
                <div class="p-3" id="heading">
                  <h6 class="mb-0">
                    <button
                      class="bg-transparent border-0"
                      data-toggle="collapse"
                      data-target="#collapsePAC"
                      aria-expanded="true"
                      aria-controls="collapsePAC"
                    >
                      ¿Qué persigue el PAC?
                    </button>
                  </h6>
                </div>

                <div
                  id="collapsePAC"
                  class="collapse show"
                  aria-labelledby="heading"
                  data-parent="#accordion"
                >
                  <div class="card-body">
                    <p className="mb-3">
                      {checkSVG} Resaltar el carácter colaborativo, contextual y
                      social del proyecto artístico comunitario como promotor de
                      la posibilidad de alcanzar una auténtica democracia
                      cultural, una cultura más accesible, participativa,
                      descentralizada y sostenible.
                    </p>

                    <p className="pb-3">
                      {checkSVG} Reflejar las necesidades, potencialidades y
                      particularidades de las diferentes comunidades.
                    </p>

                    <p className="pb-3">
                      {checkSVG} Proponer a la creatividad, el arte y las
                      culturas como fuerzas reales de transformación social.
                    </p>

                    <p className="pb-3">
                      {checkSVG} Sostener firmemente la creencia en la
                      co-autoría de la obra y en el potencial creativo de todos
                      los sectores de la sociedad.
                    </p>

                    <p className="pb-3">
                      {checkSVG} Permitir una cohesión de conocimientos en un
                      intercambio de saberes para la formación integral.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div id="accordion">
              <div class="">
                <div class="p-3" id="heading">
                  <h6 class="mb-0">
                    <button
                      class="bg-transparent border-0"
                      data-toggle="collapse"
                      data-target="#collapseCA"
                      aria-expanded="true"
                      aria-controls="collapseCA"
                    >
                      Comunidades atendidas
                    </button>
                  </h6>
                </div>

                <div
                  id="collapseCA"
                  class="collapse show"
                  aria-labelledby="heading"
                  data-parent="#accordion"
                >
                  <div class="card-body">
                    <p className="mb-3">
                      {checkSVG} Barrios, urbanismos, consejos comunales
                      hospitales, escuelas, preescolares, liceos, casas de
                      reposo, organizaciones de base, agrupaciones culturales,
                      cooperativas, asociaciones civiles, centros culturales,
                      redes sociales, centros de atención a personas con
                      discapacidad, clubes de abuelos, comunas en construcción,
                      y otros.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="pt-5">
          <div className="row">
            <div className="col-md-6 border p-0">
              <h5
                className="text-center py-4 w-100 text-white"
                style={{ backgroundColor: "#e7654e" }}
              >
                Trayecto I
              </h5>
              <div className="p-3">
                <div className="text-center py-2">
                  <p style={{ color: "#e7654e" }}>PAC I:</p>
                  <p>Epistemológico</p>
                  <p>Jurídico</p>
                  <p>Contextual</p>
                </div>
                <div className="text-center py-2">
                  <p style={{ color: "#e7654e" }}>PAC II:</p>
                  <p>Metodológico</p>
                </div>
              </div>
            </div>

            <div className="col-md-6 border p-0">
              <h5
                className="text-center py-4 w-100 text-white"
                style={{ backgroundColor: "#c45381" }}
              >
                Trayecto II
              </h5>
              <div className="p-3">
                <div className="text-center p-2">
                  <p style={{ color: "#c45381" }}>PAC III:</p>
                  <p>
                    Vinculación Comunitaria (Establecer Relaciones de Confianza)
                  </p>
                  <p>Diagnóstico Participativo</p>
                </div>
                <div className="text-center py-2">
                  <p style={{ color: "#c45381" }}>PAC IV:</p>
                  <p>Formulación y Ejecución</p>
                </div>
              </div>
            </div>

            <div className="col-md-6 border p-0">
              <h5
                className="text-center py-4 w-100 text-white"
                style={{ backgroundColor: "#00a4b9" }}
              >
                Trayecto III
              </h5>
              <div className="p-3">
                <div className="text-center py-2">
                  <p style={{ color: "#00a4b9" }}>PAC V:</p>
                  <p>Ejecución</p>
                </div>
                <div className="text-center py-2">
                  <p style={{ color: "#00a4b9" }}>PAC VI:</p>
                  <p>Organización y Análisis de la Información</p>
                  <p>Organización y Análisis de la Información</p>
                </div>
              </div>
            </div>

            <div className="col-md-6 border p-0">
              <h5
                className="text-center py-4 w-100 text-white"
                style={{ backgroundColor: "#ac4057" }}
              >
                Trayecto IV
              </h5>
              <div className="p-3">
                <div className="text-center py-2">
                  <p style={{ color: "#ac4057" }}>PAC VI:</p>
                  <p>Vinculación y Acompañamiento</p>
                  <p>PAC III y IV</p>
                  <p>Preparadurías</p>
                </div>
                <div className="text-center py-2">
                  <p style={{ color: "#ac4057" }}>PAC VII:</p>
                  <p>Jornadas de PAC</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Estructura;
