import React, { useEffect, useState } from "react";

import saberes from "../.././assets/images/saberes_collage.jpg";
import saberesPrograma from "../.././assets/images/saberes_programa.jpg";

const Saberes = () => {
  const [activeItem, setActiveItem] = useState(null);

  const handleItemClick = (id) => {
    setActiveItem(id);
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div
        className="container mt-3 p-4 d-flex flex-column"
        style={{ backgroundColor: "#e74c5e" }}
      >
        <h2 className="w-100 text-white m-0">CREACIÓN DE SABERES</h2>
        <span
          style={{ width: "100%", height: "3px", backgroundColor: "#fff" }}
        ></span>
      </div>
      <div className="container">
        <div className="row py-5">
          <div id="list-example" className="list-group col-md-3 ">
            <div className="sticky-top ">
              <button
                className={`list-group-item list-group-item-action ${
                  activeItem === "list-item-1" ? "active" : ""
                }`}
                onClick={() => handleItemClick("list-item-1")}
              >
                Dirección General
              </button>
              <button
                className={`list-group-item list-group-item-action ${
                  activeItem === "list-item-2" ? "active" : ""
                }`}
                onClick={() => handleItemClick("list-item-2")}
              >
                Coordinaciones
              </button>
              <button
                className={`list-group-item list-group-item-action ${
                  activeItem === "list-item-3" ? "active" : ""
                }`}
                onClick={() => handleItemClick("list-item-3")}
              >
                Observatorio de las Artes y las Culturas de Venezuela
              </button>
              <button
                className={`list-group-item list-group-item-action ${
                  activeItem === "list-item-4" ? "active" : ""
                }`}
                onClick={() => handleItemClick("list-item-4")}
              >
                Líneas de Investigación
              </button>
              <button
                className={`list-group-item list-group-item-action ${
                  activeItem === "list-item-5" ? "active" : ""
                }`}
                onClick={() => handleItemClick("list-item-5")}
              >
                Centros de Investigación
              </button>
              <button
                className={`list-group-item list-group-item-action ${
                  activeItem === "list-item-6" ? "active" : ""
                }`}
                onClick={() => handleItemClick("list-item-6")}
              >
                Núcleos de Investigación
              </button>
              <button
                className={`list-group-item list-group-item-action ${
                  activeItem === "list-item-7" ? "active" : ""
                }`}
                onClick={() => handleItemClick("list-item-7")}
              >
                Programa de Apoyo a la Producción y Creación de Saberes Maestra
                Sonia Sanoja
              </button>
              <button
                className={`list-group-item list-group-item-action ${
                  activeItem === "list-item-8" ? "active" : ""
                }`}
                onClick={() => handleItemClick("list-item-8")}
              >
                Registro Nacional de Investigación Unearte 2017
              </button>
            </div>
          </div>
          <div
            data-spy="scroll"
            data-target="#list-example"
            data-offset="0"
            className="scrollspy-example col-md-9"
          >
            <div>
              <img className="mb-4" src={saberes} alt="" />

              <p className="pb-3">
                En UNEARTE entendemos la Investigación como un proceso amplio de
                construcción de conocimientos; como un conjunto de procesos
                epistemológicos y metodológicos continuos, que ejercen los
                colectivos de investigación constituidos por estudiantes,
                docentes, trabajadores y trabajadoras, cultores y cultoras,
                maestras y maestros honorarios y los miembros de las
                comunidades, quienes parten del principio de que toda creación
                nace de un proceso recreativo sobre objetos y epistemologías
                previas. Así, abordamos la producción y Creación de Saberes en
                el marco de la Defensa de la Soberanía Cultural y el
                fortalecimiento de la Identidad nacional, prestado especial
                atención en la producción de nuevas epistemologías,
                metodologías, filosofías y pensamiento crítico y estético para
                las artes y las culturas Venezolanas y Nuestramericanas.
                Reconocemos estos ámbitos en todas las etapas de la vida humana,
                incluyendo la atención a la diversidad en todas sus
                manifestaciones y en todos los niveles de formación, coadyuvando
                a la construcción del Sistema Nacional de Formación para las
                Artes y las Culturas.
              </p>
              <p className="pb-3">
                En UNEARTE nos hemos planteado el propósito de producir y
                promover la construcción de nuevos conocimientos y saberes en el
                quehacer artístico y cultural, y desde la diversidad de
                abordajes y perspectivas de las disciplinas artísticas, en un
                ámbito de acción con alcance local, regional, nacional,
                nuestroamericano y mundial.
              </p>
              <p className="pb-3">
                Así mismo, fomentamos de manera especial el desarrollo de
                proyectos de investigación gestionados conjuntamente con redes
                de investigación nacionales e internacionales, con
                organizaciones del Poder Popular y espacios de vinculación
                social entre los Pueblos del Sur.
              </p>
            </div>
            <h4 className="text-info pb-2" id="list-item-1">
              Dirección General de Producción y Creación de Saberes
            </h4>
            <p className="pb-3">
              Es la instancia encargada de formular, planificar, coordinar y
              evaluar el desarrollo de las políticas, planes, programas y
              proyectos a partir del diálogo de saberes y la producción,
              transformación, divulgación e intercambio de conocimientos
              tecnológicos, humanísticos, artísticos y culturales. También es
              responsable de asesorar en materia de investigación y creación de
              saberes a las autoridades encargadas de crear el plan integral
              político-académico de la UNEARTE vinculado al saber artístico y
              cultural. Tiene la función de convocar el talento humano necesario
              para enriquecer todos los programas de formación, conformar y
              vigorizar comunidades y grupos concentrados en la investigación,
              producción y creación de saberes, impulsar redes de innovación y
              mejoramiento de los programas académicos, producción de textos y
              materiales educativos en el ámbito de las artes y las culturas,
              contribuyendo así a la revolución del conocimiento y a la defensa
              de nuestra soberanía cultural.
            </p>
            <p className="pb-3">
              Tiene por objetivo crear y fortalecer las condiciones
              intelectuales y materiales para propiciar, facilitar, coordinar,
              socializar y difundir conocimiento científico y cultural de los
              Núcleos de Investigación organizados en torno a Centros de
              Investigación y a las líneas de investigación.
            </p>
            <h4 className="text-info pb-2" id="list-item-2">
              Coordinaciones de Producción y Creación de Saberes
            </h4>
            <p className="pb-3">
              En cada Centro de Estudios y Creación Artística de la UNEARTE
              existe una Coordinación de Producción y Creación de Saberes, que
              son instancias académico-administrativas que tienen la finalidad
              de gestionar, articular, registrar y operativizar los procesos de
              investigación, producción y creación de saberes del Centro de
              Estudios. Ejecutan las políticas emanadas de la Dirección General
              de Producción y Creación de Saberes, registran las actividades de
              los Centros de Investigación, Núcleos de Investigación y Grupos de
              Investigación, sirviendo como articulación entre estas instancias.
            </p>
            <h4 className="text-info pb-2" id="list-item-3">
              Observatorio de las Artes y las Culturas de Venezuela
            </h4>
            <p className="pb-3">
              Es una instancia político-académica colegiada encargada de
              observar y analizar la realidad cultural, artística y política de
              Venezuela con el fin de conocer los problemas y las realidades
              sociales en sus múltiples dimensiones y proponer posibles vías de
              acercamiento y conocimiento. Está encargado de producir y difundir
              documentos, informes y estudios técnicos dirigidos al diseño y la
              gestión de las políticas públicas en materia de artes y culturas
              venezolanas, y específicamente en lo que refiere a la
              investigación artística y cultural. Es la instancia de vinculación
              con otras realidades culturales y artísticas existentes en la
              región. Además debe ofrecer, divulgar y compartir los recursos
              profesionales, formativos e informativos que genere. Está
              conformado por el Director o Directora General de Producción y
              Creación de Saberes de la UNEARTE, los Directores de los Centros
              de Investigación, los Coordinadores Generales de cada Núcleo de
              Investigación y un miembro de cada proyecto de investigación.
            </p>

            <p className="pb-3">
              Todos los procesos de producción y creación de saberes en la
              UNEARTE se organizarán en el marco de Centros de Investigación,
              que responden a las líneas de investigación de la Universidad, a
              través de Núcleos de Investigación, para contribuir con la
              eficiencia en la ejecución de proyectos de investigación y
              creación, la formación de investigadores e investigadoras y la
              socialización del resultado y los procesos de las investigaciones.
            </p>

            <p className="pb-3">
              El Centro de Investigación de Soberanía Cultural agrupa los
              siguientes Núcleos de Investigación:
            </p>

            <ul>
              <li>
                <p className="pl-5 py-2">
                  • Núcleo de Investigación de Patrimonio Cultural
                </p>
              </li>
              <li>
                <p className="pl-5 py-2">
                  • Núcleo de Investigación de Economía Cultural
                </p>
              </li>
              <li>
                <p className="pl-5 py-2">
                  • Núcleo de Investigación de Políticas Culturales
                </p>
              </li>
            </ul>

            <p className="pb-3">
              El Centro de Investigación de Creación Artística y Cultural agrupa
              los siguientes Núcleos de Investigación:
            </p>

            <ul>
              <li>
                <p className="pl-5 py-2">
                  • Núcleo de Investigación de Prácticas Artísticas y Culturales
                </p>
              </li>
              <li>
                <p className="pl-5 py-2">
                  • Núcleo de Investigación de Culturas Populares
                </p>
              </li>
              <li>
                <p className="pl-5 py-2">
                  • Núcleo de Investigación de Epistemes Descolonizadoras
                </p>
              </li>
            </ul>

            <p className="pb-3">
              El Centro de Investigación de Arte y Transformación Social agrupa
              los siguientes Núcleos de Investigación:
            </p>

            <ul>
              <li>
                <p className="pl-5 py-2">
                  • Núcleo de Investigación de Arte y Comunidad
                </p>
              </li>
              <li>
                <p className="pl-5 py-2">
                  • Núcleo de Investigación de Educación para las Artes
                </p>
              </li>
              <li>
                <p className="pl-5 py-2">
                  • Núcleo de Investigación de Arte y Salud
                </p>
              </li>
            </ul>

            <h4 className="text-info pb-2" id="list-item-4">
              Líneas de Investigación
            </h4>
            <p className="pb-3">
              Las líneas de investigación de UNEARTE son el espacio de discusión
              y reflexión permanente sobre la construcción, renovación y
              desarrollo de las artes y las culturas en Venezuela y de nuestros
              pueblos nuestroamericanos y caribeños, constituyéndose en el
              soporte para la gestión del conocimiento en los programas de
              pregrado, educación avanzada, vinculación social, cátedras libres,
              laboratorios disciplinarios, comunidades creativas y otros
              programas de socialización del conocimiento.
            </p>

            <p className="pb-3">
              Son tres: 1) Soberanía Cultural; 2) Arte y transformación social;
              3) Creación artística y cultural.
            </p>

            <p className="pb-3">
              Se sustentan en los principios establecidos en la Constitución de
              la República Bolivariana de Venezuela, la Ley Orgánica de Cultura,
              Ley Orgánica de Educación y Misión Alma Mater.
            </p>

            <p className="pb-3">
              El objetivo general de las líneas de investigación es fortalecer
              los procesos de creación de bienes comunes artísticos y
              culturales, las prácticas de intercambio de valor cultural y
              simbólico, originados en el proceso social del trabajo, y el
              ejercicio pleno del buen vivir y de la salud espiritual y
              psicosocial de las personas, las subjetividades y las comunidades.
            </p>

            <p className="pb-3">
              Cada línea responde a la ética y a los principios de la educación
              popular, así como a las especificidades técnicas y metodológicas
              de cada disciplina artística, y a los desafíos de la
              descolonización epistemológica. Se fundan en la práctica de la
              investigación cultural y en la producción artística como actos de
              soberanía espiritual, psicológica, económica y política de las
              personas y los pueblos, y como herramientas al servicio de los
              procesos emancipatorios y del buen vivir en lo personal, lo
              comunitario, lo social, lo nacional y lo regional.
            </p>

            <h4 className="text-info pb-2" id="list-item-5">
              Centros de Investigación
            </h4>
            <p className="pb-3">
              Los Centros de Investigación son colectivos integrados por
              docentes-investigadores, personal administrativo y obrero,
              estudiantes y Maestros Honorarios de la UNEARTE que se agrupan en
              torno a cada una de las líneas de investigación a través de los
              Núcleos de Investigación, y complementan los procesos de
              investigación de los Programas Nacionales de Formación (PNF), las
              Especializaciones, Maestrías y Doctorados de los Programas
              Nacionales de Formación Avanzada (PNFA) de la UNEARTE. Así mismo,
              hacen seguimiento de proyectos de investigación asociados a los
              estudios de los PNF y PNFA, coordinan actividades formativas y los
              eventos científicos e intelectuales a escala Nacional e
              Internacional mediante la organización de congresos, eventos y un
              cronograma de actividades académicas. Fomentan el trabajo trans,
              inter, multi y disciplinario en materia de Creación de Saberes.
              Acompañan los procesos investigativos de las Cátedras libres y
              Grupos Colegiados de investigación. Están dedicados y
              comprometidos con la observación, investigación, creación
              artística razonada, interpretación, transformación, generación de
              contenidos y formación de cuarto nivel en materia de Artes y
              Culturas. Realizan y gestionan publicaciones relacionadas con sus
              líneas de investigación. Están adscritos a la Dirección General de
              Producción y Creación de Saberes.
            </p>

            <h4 className="text-info pb-2" id="list-item-6">
              Núcleos de Investigación
            </h4>
            <p className="pb-3">
              Los Núcleos de Investigación son espacios conformados por equipos
              colegiados de investigación integrados por Docentes, Auxiliares,
              Estudiantes y Maestros Honorarios con intereses comunes,
              destinados a potenciar la investigación en distintos campos
              temáticos relevantes para la sociedad y la línea de investigación
              que los agrupa, partiendo del trabajo trans, inter, multi y
              disciplinario. Tiene entre sus objetivos generar, planificar,
              organizar, ejecutar y difundir actividades de formación e
              investigación, así como grupos de estudio y proyectos de
              investigación (individuales y/o colectivos) para la generación de
              conocimientos.
            </p>
            <img src={saberesPrograma} alt="" />

            <h4 className="text-info pb-2 pt-4" id="list-item-7">
              Programa de Apoyo a la Producción y Creación de Saberes Maestra
              Sonia Sanoja
            </h4>
            <p className="pb-3">
              La Universidad Nacional Experimental de las Artes para fortalecer
              la promoción de la creación artística y la investigación en el
              marco de los principios éticos, vinculada a la educación popular y
              a los desafíos de la descolonización epistemológica, histórica,
              poética y estética, crea el Programa de Apoyo a la Producción de
              Saberes Maestra Sonia Sanoja. Rindiendo un sentido homenaje a
              quién no solo creó una obra en diálogo con los referentes y el
              imaginario nuestroamericano, sino que asumió la reflexión escrita
              como ámbito necesario en la fundamentación de su quehacer
              artístico.
            </p>
            <p className="pb-3">
              El Programa de Apoyo a la Producción de Saberes promueve el
              desarrollo de la investigación en toda la comunidad uneartista,
              incentiva la cultura de investigación en todas las áreas de
              conocimiento adscritas a las líneas de investigación y de creación
              artística y cultural de los PNF y PNFA, y fomenta la consolidación
              de grupos de investigadores e investigadoras, que mantienen una
              actividad de indagación, producción y socialización de saberes.
            </p>
            <p className="pb-3">
              El Programa de Apoyo a la Producción de Saberes está dirigido a
              sus profesores y profesoras, colectivos y grupos de investigación,
              que desarrollan investigaciones en el marco de las líneas de
              investigación: Soberanía cultural, Arte y Transformación Social y
              Creación artística y cultural, orientadas al fortalecimiento de
              los valores identitarios, del estudio de las tramas de la
              diversidad cultural, con especial atención en la producción de
              nuevos paradigmas de conocimiento, metodologías, filosofías y
              pensamientos estéticos para las artes y las culturas venezolanas y
              nuestramericanas. Fomenta de manera especial, el desarrollo de
              proyectos gestionados a través de redes de investigación,
              organizaciones del Poder Popular y espacios de vinculación social
              entre los pueblos del sur. Para participar se debe responder al
              Mapeo de Investigación del año en curso, inscribir el proyecto de
              investigación digital a través del correo
              creaciondesaberes@unearte.edu.ve y enviar un ejemplar impreso a la
              Dirección General de Producción y Creación de Saberes. Para el año
              2017 se financiarán hasta 9 proyectos por un monto máximo de hasta
              1.000.000 bsf. El comité académico designara un comité evaluador
              calificado por línea de investigación que tomará en cuenta la
              calidad y la originalidad de la investigación, la conformación del
              equipo de trabajo, la factibilidad de ejecución del proyecto y la
              justificación de los recursos solicitados.
            </p>
            <div className="">
              <a className="d-block text-primary mb-3" href="">
                Descarga Bases del Programa{" "}
              </a>
              <a
                className="d-block text-primary"
                href="https://docs.google.com/forms/d/e/1FAIpQLSfEA5PKN2ga7OT-vgc49vSx4gWdXfKHpdfj3-k1JDQiqFIgmQ/viewform?c=0&w=1"
                target="_blank"
              >
                Regístrase a travéz de este enlace{" "}
              </a>
            </div>

            <h4 className="text-info pb-2 pt-4" id="list-item-8">
              Registro Nacional de Investigación Unearte 2017
            </h4>
            <p className="pb-3">
              El Registro Nacional de Investigación Unearte 2017 está dirigido a
              todos los trabajadores académicos, administrativos, obreros, así
              como a los estudiantes y las comunidades que mantienen una
              relación con Proyecto Artístico Comunitario, grupos de
              investigación, cátedras o a través de los equipos de Vinculación
              social.
            </p>
            <p className="pb-3">
              Con esta herramienta y los resultados que arrojen cada año, se
              pueden generar directrices de Unearte, de la Dirección General y
              las Coordinaciones de Producción y Creación de Saberes de cada
              CECA.
            </p>
            <p className="pb-3">
              Asimismo, servirá como apoyo para trazar estrategias conjuntas que
              impulsen la creación de los centros y núcleos de investigación, el
              Observatorio de las Artes y las Culturas, en esta nueva etapa que
              avanza en la ejecución de los contenidos del nuevo Reglamento de
              Producción y Creación de Saberes.
            </p>
            <p className="pb-3">
              Esta herramienta además espera proveer a la comunidad en general
              de la información necesaria para generar sinergias, unir grupos de
              investigación afines o complementarios de todo el país, fortalecer
              las Jornadas de Investigación de cada CECA y las Jornadas
              Nacionales, la red de talentos y desarrollos que pueden generar
              unidades curriculares en los Programas Nacionales de Formación
              Avanzada (PNFA) y en los Programas Nacionales de Formación (PNF).
            </p>
            <p className="pb-3">
              Igualmente, brindará apoyos para los concursos, asesorías
              especializadas de los Trabajos Especiales de Grado, opciones
              editoriales, digitales y musicales, así como perspectivas en torno
              al Programa de Apoyo a la Producción de Saberes, entre otros
              aspectos de carácter estratégico.
            </p>

            <a
              className="text-primary"
              href="https://docs.google.com/forms/d/e/1FAIpQLSfEA5PKN2ga7OT-vgc49vSx4gWdXfKHpdfj3-k1JDQiqFIgmQ/viewform?c=0&w=1"
              target="_blank"
            >
              Regístrese a través de este enlace
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default Saberes;
