import React from "react";

import rector from "../../assets/images/rector.png";
import vicerector from "../../assets/images/vicerector2.jpg";
import secretarioGeneral from "../../assets/images/secretarioGeneral.jpg";
import vicePoderPopular from "../../assets/images/vicerectora_poder_popular.jpg";
import viceDesarrolloTerritorial from "../../assets/images/vicerectora_desarrollo_territorial.jpg";
import vision from "../../assets/images/telescopio.png";
import mision from "../../assets/images/mision.png";

const AboutUs = () => {
  return (
    <>
      <div className="container mt-3 p-4 bg-danger d-flex flex-column">
        <h2 className="w-100 text-white m-0">SOBRE NOSOTROS</h2>
        <span
          style={{ width: "100%", height: "3px", backgroundColor: "#fff" }}
        ></span>
      </div>
      <section className="with-medical">
        <div className="container">
          <div className="row">
            <div className="col-lg col-md-12 txtr">
              <p className="mt-3">
                La{" "}
                <strong>
                  Universidad Nacional Experimental de las Artes-UNEARTE
                </strong>{" "}
                fue creada en el año 2008, dentro del marco de la Misión Alma
                Mater y es cotutelada por el Ministerio del Poder Popular para
                la Educación Universitaria junto con el Ministerio del Poder
                Popular para la Cultura de la República Bolivariana de
                Venezuela.
              </p>
              <p className="mt-3">
                Surge con el fin de reivindicar los procesos relacionados con la
                intuición, imaginación, sensibilidad, sentimiento y emoción como
                elementos fundamentales de la experiencia del arte, entendiendo
                que la enseñanza y la valoración de éstas son parte
                indispensable en el proceso de construcción de ciudadanía con
                vocación humanista.
              </p>
              <p className="mt-3">
                La <strong>UNEARTE</strong> contribuye con espacios para el
                intercambio cultural y artístico entre nuestros pueblos,
                propicia la formación integral, el conocimiento, el saber y la
                praxis artística en todas sus manifestaciones, formas y
                enfoques, además, busca desarrollar el potencial creativo de los
                participantes garantizando el carácter plural y la vocación de
                servicio social de la institución.
              </p>

              <p className="mt-3">
                La{" "}
                <strong> Universidad Nacional Experimental de las Artes</strong>
                , como red nacional, ofrece la posibilidad de desarrollar áreas
                de conocimiento vinculadas con las artes, convocar el talento
                humano necesario para enriquecer los programas de formación,
                conformar y vigorizar comunidades concentradas en la
                investigación, contribuir con el desarrollo profesional de los
                docentes, impulsar redes de innovación y mejoramiento de los
                programas académicos, producción de textos y materiales
                educativos en el ámbito de las artes plásticas, la danza, la
                música, el teatro, las artes audiovisuales y la educación para
                las artes.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* ################# University Number Starts Here####################### */}

      <div className="doctor-message">
        <div className="inner-lay">
          <div className="container">
            <div className="row session-title">
              <img
                src={vision}
                style={{ width: "100px", textAlign: "center" }}
                alt=""
              />
              <h2>Visión</h2>
              <p>
                La UNEARTE es un espacio de discusión y reflexión permanente
                sobre la construcción, renovación y desarrollo de las artes así
                como de las culturas de nuestros pueblos. Es una universidad
                abierta a todas las corrientes de pensamiento, así como al
                legado y transmisión de las tradiciones latinoamericanas,
                caribeñas y universales. Reconocida por la formación integral de
                humanistas con vocación, sensibilidad artística y social, que
                considera la intuición y la emoción como factores inseparables
                del artista, capaz de responder a los grandes cambios
                socio-históricos y culturales del siglo XXI y de coadyuvar en la
                construcción de una sociedad regida por los principios de una
                democracia protagónica, participativa, inclusiva, multiétnica y
                pluricultural.
              </p>
            </div>
            <div className="row session-title">
              <img src={mision} style={{ width: "100px" }} alt="" />
              <h2>Misión</h2>
              <p>
                UNEARTE, a partir de los valores de nuestra cultura, está
                destinada a formar profesionales en el campo artístico,
                humanistas críticos, comprometidos con el país, creativos,
                participativos y protagónicos, sensibles ante los procesos de
                transformación social, capaces de enfrentar, desde el terreno
                artístico, los nuevos retos y cambios históricos, a través de
                una construcción colectiva, transdisciplinaria, democrática y de
                diálogo de saberes que involucre a la comunidad universitaria y
                a todos los actores sociales, para hacer de la educación y el
                arte hechos sociales transformadores, con énfasis en la creación
                intelectual y cultural, el poder popular y la visión
                geoestratégica del desarrollo territorial.
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* ################# Our Instructors Starts Here####################### */}

      <div className="our-instructor">
        <div className="container">
          <div className="session-title row">
            <h2>Rectorado</h2>
          </div>

          <div className="instruct-row row">
            <div className="col-md-2">
              <div className="instruct-card">
                <div className="img_container">
                  <img src={rector} alt="" />
                </div>
                <h6 className="p-1">Ignacio Barreto Esnal</h6>
                <p>Rector</p>
              </div>
            </div>

            <div className="col-md-2">
              <div className="instruct-card">
                <div className="img_container">
                  <img src={vicerector} alt="" />
                </div>
                <h6 className="p-1">Nelson Enrique Hurtado</h6>
                <p>Vicerrectorado Académico</p>
              </div>
            </div>

            <div className="col-md-2">
              <div className="instruct-card">
                <div className="img_container">
                  <img src={vicePoderPopular} alt="" />
                </div>
                <h6 className="p-1">Lisbeth Coromoto Villalba Pérez</h6>
                <p>Vicerrectorado del Poder Popular</p>
              </div>
            </div>

            <div className="col-md-2">
              <div className="instruct-card">
                <div className="img_container">
                  <img src={viceDesarrolloTerritorial} alt="" />
                </div>
                <h6>Yahaira Salazar Arismendi</h6>
                <p>Vicerrectorado de Desarrollo Territorial</p>
              </div>
            </div>

            <div className="col-md-2">
              <div className="instruct-card">
                <div className="img_container">
                  <img src={secretarioGeneral} alt="" />
                </div>
                <h6>Carlos Alfonzo Franco Gil</h6>
                <p>Secretario General</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutUs;
