import React from "react";

const OpinionesUNEARTE = () => {
  return (
    <>
      <div>
        <p>
          Espacio universitario para el debate de las ideas circunscritas al
          ámbito de las artes y cultura venezolana, constituyendo un espacio de
          la Unearte en diálogo con el fin de fortalecer una "formación
          integral, el conocimiento, el saber y la práxis artística en todas sus
          manifestaciones, formas y enfoques, así como el desarrollo de las
          potencialidades creativa de la interacción entre las artes,
          garantizando su carácter plural y de servicio social, con sentido de
          corresponsabilidad, conciencia colectiva e individual, reconociendo la
          tradición de la enseñanza de las artes en nuestro país y en{" "}
          <strong>
            sintonía con los planes de desarrollo local, regional y nacional"
          </strong>
          .
        </p>
      </div>
    </>
  );
};

export default OpinionesUNEARTE;
