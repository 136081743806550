import React, { useEffect } from "react";
import CatedrasLibres from "./Catedras/CatedrasLibres";
import GruposEstables from "./GruposEstables/GruposEstables";
import Diplomados from "./Diplomados/Diplomados";
import OpinionesUNEARTE from "./OpinionesUNEARTE/OpinionesUNEARTE";
import MaestrosHonorarios from "./MaestrosHonorarios/MaestrosHonorarios";

const PoderPopular = () => {
  useEffect(() => {
    window.scrollTo(0, 1);
  }, []);

  return (
    <>
      <div
        className="container mt-3 p-4 d-flex flex-column"
        style={{ backgroundColor: "#e74c5e" }}
      >
        <h2 className="w-100 text-white m-0">PODER POPULAR</h2>
        <span
          style={{ width: "100%", height: "3px", backgroundColor: "#fff" }}
        ></span>
      </div>
      <div className="container py-5">
        <div className="row">
          <div className="col-md-3 border">
            <div
              className="nav flex-column nav-pills sticky-top"
              id="v-pills-tab"
              role="tablist"
              aria-orientation="vertical"
            >
              <a
                className="nav-link active"
                id="v-pills-maestros-tab"
                data-toggle="pill"
                href="#v-pills-maestros"
                role="tab"
                aria-controls="v-pills-maestros"
                aria-selected="true"
              >
                Maestros Honorarios
              </a>
              <a
                className="nav-link"
                id="v-pills-catedras-tab"
                data-toggle="pill"
                href="#v-pills-catedras"
                role="tab"
                aria-controls="v-pills-catedras"
                aria-selected="false"
              >
                Cátedras Libres
              </a>
              <a
                className="nav-link"
                id="v-pills-grupos-tab"
                data-toggle="pill"
                href="#v-pills-grupos"
                role="tab"
                aria-controls="v-pills-grupos"
                aria-selected="false"
              >
                Grupos Estables
              </a>
              <a
                className="nav-link"
                id="v-pills-programacion-tab"
                data-toggle="pill"
                href="#v-pills-programacion"
                role="tab"
                aria-controls="v-pills-programacion"
                aria-selected="false"
              >
                Programación Artística
              </a>
              <a
                className="nav-link"
                id="v-pills-talleres-tab"
                data-toggle="pill"
                href="#v-pills-talleres"
                role="tab"
                aria-controls="v-pills-talleres"
                aria-selected="false"
              >
                Talleres Libres
              </a>
              <a
                className="nav-link"
                id="v-pills-vinculacion-tab"
                data-toggle="pill"
                href="#v-pills-vinculacion"
                role="tab"
                aria-controls="v-pills-vinculacion"
                aria-selected="false"
              >
                Vinculación Social
              </a>
              <a
                className="nav-link"
                id="v-pills-diplomados-tab"
                data-toggle="pill"
                href="#v-pills-diplomados"
                role="tab"
                aria-controls="v-pills-diplomados"
                aria-selected="false"
              >
                Diplomados
              </a>
              <a
                className="nav-link"
                id="v-pills-opiniones-tab"
                data-toggle="pill"
                href="#v-pills-opiniones"
                role="tab"
                aria-controls="v-pills-opiniones"
                aria-selected="false"
              >
                Opiniones UNEARTE
              </a>
            </div>
          </div>

          <div className="tab-content col-md px-5" id="v-pills-tabContent">
            <div
              className="tab-pane fade show active"
              id="v-pills-maestros"
              role="tabpanel"
              aria-labelledby="v-pills-maestros-tab"
            >
              <MaestrosHonorarios />
            </div>
            <div
              className="tab-pane fade"
              id="v-pills-catedras"
              role="tabpanel"
              aria-labelledby="v-pills-catedras-tab"
            >
              <CatedrasLibres />
            </div>
            <div
              className="tab-pane fade"
              id="v-pills-grupos"
              role="tabpanel"
              aria-labelledby="v-pills-grupos-tab"
            >
              <GruposEstables />
            </div>
            <div
              className="tab-pane fade"
              id="v-pills-programacion"
              role="tabpanel"
              aria-labelledby="v-pills-programacion-tab"
            >
              ...
            </div>
            <div
              className="tab-pane fade"
              id="v-pills-talleres"
              role="tabpanel"
              aria-labelledby="v-pills-talleres-tab"
            >
              ...
            </div>
            <div
              className="tab-pane fade"
              id="v-pills-vinculacion"
              role="tabpanel"
              aria-labelledby="v-pills-vinculacion-tab"
            >
              ...
            </div>
            <div
              className="tab-pane fade"
              id="v-pills-diplomados"
              role="tabpanel"
              aria-labelledby="v-pills-diplomados-tab"
            >
              <Diplomados />
            </div>
            <div
              className="tab-pane fade"
              id="v-pills-opiniones"
              role="tabpanel"
              aria-labelledby="v-pills-opiniones-tab"
            >
              <OpinionesUNEARTE />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PoderPopular;
