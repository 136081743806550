import { Route, Routes } from "react-router-dom";

import Header from "./layouts/header/header";
import Footer from "./layouts/footer/footer";
import Home from "./pages/home";
import AboutUs from "./pages/about";
import Gallery from "./pages/gallery";
import Contact from "./pages/contact";
import Publications from "./pages/publications";
import News from "./pages/news";
import NewsDescription from "./pages/newsDescription";
import AlmaMater from "./pages/AlmaMater";
import AcademicCalendar from "./pages/AcademicCalendar";
import Accountability from "./pages/Accountability";

import axios from "axios";
import Ingreso from "./pages/ingreso";
import Formacion from "./pages/formacion";
import EstudiosAvanzados from "./pages/EstudiosAvanzados";
import Saberes from "./pages/Saberes";
import Reglamentos from "./pages/Reglamentos/Reglamentos";
import PoderPopular from "./pages/PoderPopular/PoderPopular";
import Gaceta from "./sections/Gaceta/Gaceta";

// axios.defaults.baseURL = "http://localhost:8000/api";
axios.defaults.baseURL = "https://admin.unearte.edu.ve/api";

function App() {
  return (
    <div className="App">
      <Header />
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/sobre_nosotros" element={<AboutUs />} />
        <Route exact path="/servivios" element={<AboutUs />} />
        <Route exact path="/noticias" element={<News />} />
        <Route exact path="/noticias/:id" element={<NewsDescription />} />
        <Route exact path="/publicaciones" element={<Publications />} />
        <Route exact path="/galeria" element={<Gallery />} />
        <Route exact path="/contacto" element={<Contact />} />
        <Route exact path="/alma_mater" element={<AlmaMater />} />
        <Route exact path="/ingreso" element={<Ingreso />} />
        <Route exact path="/pnf" element={<Formacion />} />
        <Route exact path="/saberes" element={<Saberes />} />
        <Route exact path="/reglamentos" element={<Reglamentos />} />
        <Route exact path="/poder_popular" element={<PoderPopular />} />
        <Route exact path="/gaceta" element={<Gaceta />} />
        <Route
          exact
          path="/estudios_avanzados"
          element={<EstudiosAvanzados />}
        />
        <Route
          exact
          path="/rendicion_de_cuentas"
          element={<Accountability />}
        />
        <Route
          exact
          path="/calendario_academico"
          element={<AcademicCalendar />}
        />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
