import React from "react";

import almaMater from "../../assets/images/alma_mater_illustration.png";
import almaMaterPDF from "../../assets/files/mision_alma_mater.pdf";

const AlmaMater = () => {
  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-md-6 py-5">
            <p className="py-2">
              La <strong>Misión Alma Mater</strong> se propone impulsar la
              transformación de la educación universitaria venezolana y
              propulsar su articulación institucional y territorial, en función
              de las líneas estratégicas del{" "}
              <strong>Proyecto Nacional Simón Bolívar</strong>, garantizando el
              derecho de todas y todos a una educación universitaria de calidad
              sin exclusiones.
            </p>

            <p className="py-2">
              Esta Misión se constituye como referencia de una nueva
              institucionalidad, caracterizada por la cooperación solidaria,
              cuyo eje es la generación, transformación y socialización de
              conocimiento pertinente a nuestras realidades y retos culturales,
              ambientales, políticos, económicos y sociales, en el marco de la
              transformación del país.
            </p>

            <p className="py-2">
              La <strong>Misión Alma Mater</strong> y la{" "}
              <strong>Misión Sucre</strong> son un todo articulado para
              favorecer el enraizamiento de la educación universitaria en todo
              el territorio nacional, comprometida con el desarrollo humano
              integral basado en la participación protagónica de las
              comunidades. Las <strong>Universidades Nacionales</strong>{" "}
              agrupadas en la{" "}
              <strong>Asociación de Rectores Bolivarianos (ARBOL)</strong> son
              actores fundamentales en la Misión.
            </p>
            <a
              className="btn btn-danger mt-2"
              href={almaMaterPDF}
              download={"Misión Alma Mater"}
            >
              CONOCE MÁS
            </a>
          </div>
          <div className="col-md-6 p-5">
            <img src={almaMater} alt="" />
          </div>
        </div>
      </div>
    </>
  );
};

export default AlmaMater;
