import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getGallery } from "../../redux/actions";

const Gallery = () => {
  const dispatch = useDispatch();
  const gallery = useSelector((state) => state.gallery);

  useEffect(() => {
    dispatch(getGallery());
  }, []);

  return (
    <>
      <div className="gallery">
        <div className="container">
          <div className="row">
            <div className="gallery-filter d-none d-sm-block">
              <button
                className="btn btn-default filter-button"
                data-filter="all"
              >
                All
              </button>
              <button
                className="btn btn-default filter-button"
                data-filter="hdpe"
              >
                Finance
              </button>
              <button
                className="btn btn-default filter-button"
                data-filter="sprinkle"
              >
                Digital Marketing
              </button>
              <button
                className="btn btn-default filter-button"
                data-filter="spray"
              >
                {" "}
                Money
              </button>
              <button
                className="btn btn-default filter-button"
                data-filter="irrigation"
              >
                Business Alaysis
              </button>
            </div>
            <br />

            {gallery?.map((g) => (
              <div
                key={g.id}
                className="gallery_product col-lg-4 col-md-4 col-sm-4 col-xs-6 filter hdpe"
              >
                <img src={g.imagen} className="img-responsive" />
              </div>
            ))}

            <div className="gallery_product col-lg-4 col-md-4 col-sm-4 col-xs-6 filter hdpe">
              <img
                src="assets/images/gallery/gallery_01.jpg"
                className="img-responsive"
              />
            </div>

            <div className="gallery_product col-lg-4 col-md-4 col-sm-4 col-xs-6 filter sprinkle">
              <img
                src="assets/images/gallery/gallery_02.jpg"
                className="img-responsive"
              />
            </div>

            <div className="gallery_product col-lg-4 col-md-4 col-sm-4 col-xs-6 filter hdpe">
              <img
                src="assets/images/gallery/gallery_03.jpg"
                className="img-responsive"
              />
            </div>

            <div className="gallery_product col-lg-4 col-md-4 col-sm-4 col-xs-6 filter irrigation">
              <img
                src="assets/images/gallery/gallery_04.jpg"
                className="img-responsive"
              />
            </div>

            <div className="gallery_product col-lg-4 col-md-4 col-sm-4 col-xs-6 filter spray">
              <img
                src="assets/images/gallery/gallery_05.jpg"
                className="img-responsive"
              />
            </div>

            <div className="gallery_product col-lg-4 col-md-4 col-sm-4 col-xs-6 filter spray">
              <img
                src="assets/images/gallery/gallery_06.jpg"
                className="img-responsive"
              />
            </div>

            <div className="gallery_product col-lg-4 col-md-4 col-sm-4 col-xs-6 filter irrigation">
              <img
                src="assets/images/gallery/gallery_06.jpg"
                className="img-responsive"
              />
            </div>

            <div className="gallery_product col-lg-4 col-md-4 col-sm-4 col-xs-6 filter irrigation">
              <img
                src="assets/images/gallery/gallery_08.jpg"
                className="img-responsive"
              />
            </div>

            <div className="gallery_product col-lg-4 col-md-4 col-sm-4 col-xs-6 filter hdpe">
              <img
                src="assets/images/gallery/gallery_09.jpg"
                className="img-responsive"
              />
            </div>

            <div className="gallery_product col-lg-4 col-md-4 col-sm-4 col-xs-6 filter spray">
              <img
                src="assets/images/gallery/gallery_10.jpg"
                className="img-responsive"
              />
            </div>

            <div className="gallery_product col-lg-4 col-md-4 col-sm-4 col-xs-6 filter sprinkle">
              <img
                src="assets/images/gallery/gallery_11.jpg"
                className="img-responsive"
              />
            </div>
            <div className="gallery_product col-lg-4 col-md-4 col-sm-4 col-xs-6 filter sprinkle">
              <img
                src="assets/images/gallery/gallery_12.jpg"
                className="img-responsive"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Gallery;
