import React, { useState } from "react";
import { maestros } from "./maestros";
import defaultImg from "../../../assets/images/default.png";

const MaestrosHonorarios = () => {
  const [selectedMaestro, setSelectedMaestro] = useState(null);

  const handleMaestroClick = (maestro) => {
    setSelectedMaestro(maestro);
  };

  return (
    <>
      <div>
        <p className="py-3">
          Los Maestros y Maestras Honorarias son baluartes del medio artístico
          venezolano a los que la Universidad Nacional Experimental de las
          Artes, reconoce en su trayectoria y obra méritos especiales alcanzados
          en sus labores culturales, artísticas o profesionales.
        </p>

        <div className="py-3">
          <h6>Lista de Maestros</h6>
          <div className="dropdown">
            <button
              className="btn btn-secondary dropdown-toggle"
              type="button"
              id="dropdownMenuButton"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              Seleccionar Maestro
            </button>
            <div
              className="dropdown-menu"
              aria-labelledby="dropdownMenuButton"
              style={{ maxHeight: "200px", overflowY: "scroll" }}
            >
              {maestros?.map((maestro) => (
                <a
                  key={maestro.name}
                  className="dropdown-item"
                  onClick={() => handleMaestroClick(maestro)}
                  href="#"
                >
                  {maestro.name}
                </a>
              ))}
            </div>
          </div>
        </div>

        <div className="py-3">
          <div className="col-12 p-0">
            <div className="tab-content" id="nav-tabContent">
              {selectedMaestro && (
                <div className="tab-pane fade show active">
                  <div className="row">
                    <div className="col-md-4">
                      <img
                        src={
                          !selectedMaestro.image
                            ? defaultImg
                            : selectedMaestro.image
                        }
                        alt=""
                      />
                    </div>
                    <div className="col-md-8">
                      <h4 className="text-info">{selectedMaestro.name}</h4>
                      <p className="py-3">{selectedMaestro.description}</p>
                      <p className="py-3">
                        <strong>Disiplina:</strong> {selectedMaestro.discipline}
                      </p>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MaestrosHonorarios;
