// IMAGENES
import imgGacetaVIII from "../../assets/images/img-gaceta.png";
import imgGacetaIX from "../../assets/images/gaceta09.png";

// LINKS
const gacetaVIII = "/assets/files/GACETA-VIII.pdf";
const gacetaIX = "/assets/files/GACETA-IX.pdf";

export const GACETAS = [
  {
    id: 1,
    imagen: imgGacetaVIII,
    titulo: "Gaceta VIII",
    descripcion: "Caracas, Febrero - Abril de 2024",
    edicion: "AÑO XV Edición Nº008",
    link: gacetaVIII,
  },
  {
    id: 2,
    imagen: imgGacetaIX,
    titulo: "Gaceta IX",
    descripcion: "CARACAS, JUNIO-JULIO DE 2024",
    edicion: "AÑO IX EDICIÓN N°009",
    link: gacetaIX,
  },
];
