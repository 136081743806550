import armoniaI from "../../assets/images/armonia1.jpg";
import armoniaII from "../../assets/images/armonia2.jpg";
import contrapunto from "../../assets/images/contrapunto.jpg";
import cuerpoRebelde from "../../assets/images/cuerpo_rebelde.jpg";
import papelesBiograficos from "../../assets/images/papeles_biograficos.jpg";
import vidasMaestras from "../../assets/images/vidas_maestras.jpg";
import tradicionesMusicales from "../../assets/images/tradiciones_musicales.jpg";
import bolivarVive from "../../assets/images/bolivarvive.png";
import universidadDelPueblo from "../../assets/images/hacia.png";
import manuelas from "../../assets/images/manuelas.png";
import quintetoIMG from "../../assets/images/quinteto.jpg";
import trioIMG from "../../assets/images/trio.jpg";
import antologia from "../../assets/images/antologia.png";
import arquitectosVenezolanos from "../../assets/images/arquitectos.jpg";
import cuadernosPensamiento from "../../assets/images/cuadernos_pensamiento.jpg";
import acordesPatrios from "../../assets/images/acordes_patrios.jpg";
import manifestacionesProfanas from "../../assets/images/tradiciones_musicales2.jpg";
import imgTiyoctios02 from "../../assets/images/tiyoctios02.png";
import imgTiyoctios01 from "../../assets/images/tiyoctios01.png";

import theatron1 from "../../assets/images/theatron1.jpg";
import theatron2 from "../../assets/images/theatron2.jpg";
import theatron3 from "../../assets/images/theatron3.jpg";
import theatron26 from "../../assets/images/teatlon26.png";
import theatron27 from "../../assets/images/teatlon27.png";
import theatron28 from "../../assets/images/teatlon28.png";
import theatron29 from "../../assets/images/teatlon29.png";
import oportunidades from "../../assets/images/oportunidades.jpg";
import jornadas2015 from "../../assets/images/jornadas2015.jpg";
import jornadas2016 from "../../assets/images/jornadas2016.jpg";
import jornadas2017 from "../../assets/images/jornadas2017.png";
import manifiesto from "../../assets/images/preparadores.png";
import pnfa_img from "../../assets/images/pnfa_img.png";

import fetiche from "../../assets/images/la_cultura_fetiche_de_la_modernidad.png";
import pasos from "../../assets/images/tras_los_pasos_de_glauber.png";
import escriturasDelSerDoliente from "../../assets/images/escritura_del_ser_doliente.png";
import cantarDesdeElSur from "../../assets/images/cantar_desde_el_sur.png";
import expresionesDeResistencia from "../../assets/images/expresiones_de_resistencia.png";

import {
  antologia_del_desorden,
  armonia1,
  armonia2,
  bolivar_vive,
  contrapunto_para_hoy,
  cuerpo_rebelde,
  jornadas_de_investigacion_2015,
  manifestaciones_religiosas,
  manuelas_y_camaradas,
  oportunidades_de_estudio,
  papeles_biograficos,
  quinteto,
  theatron_20_21,
  theatron_22_23,
  theatron_24_25,
  theatron_26_27,
  theatron_27_2,
  trio,
  universidad_del_pueblo,
  vidas_maestras,
  manifiesto_de_los_preparadores,
  arquitectos_venezolanos,
  cuadernos_de_pensamiento_y_creacion,
  acordes_patrios,
  manifestaciones_profanas,
  Documento_PNFA,
  la_cultura_fetiche,
  tras_los_pasos_de_glauder,
  escrituras_del_ser_doliente,
  cantar_desde_el_sur,
  expresiones_de_resistencia,
  tiyoctios,
  tiyoctios01,
} from "../../assets/files";

export const BOOKS = [
  {
    id: 17,
    title: "Expresiones de resistencia de la cultura campesina en Sanare",
    autor: "Edsijual Mirabal Cova",
    descriction:
      "ISBN: 978-980-7244-26-8. UNEARTE Ediciones. Depósito legal: DC2022000637, Caracas. 2022. 266 págs.",
    image: expresionesDeResistencia,
    link: expresiones_de_resistencia,
  },
  {
    id: 16,
    title: "Cantar desde el Sur",
    autor: "Fabiola José González",
    descriction:
      "ISBN: 978-980-7244-25-1. UNEARTE Ediciones. Depósito legal: DC2022000614, Caracas. 2022. 150 págs.",
    image: cantarDesdeElSur,
    link: cantar_desde_el_sur,
  },
  {
    id: 15,
    title: "Escritura del ser doliente",
    autor: "Roma Rappa",
    descriction:
      "ISBN: 978-980-7244-24-4. UNEARTE Ediciones. Depósito legal: DC2022000613, Caracas. 2022. 172 págs.",
    image: escriturasDelSerDoliente,
    link: escrituras_del_ser_doliente,
  },
  {
    id: 14,
    title: "Tras los pasos de Glauber",
    autor: "Goar Sánchez",
    descriction:
      "ISBN: 978-980-7244-23-7. UNEARTE Ediciones. Depósito legal: DC2022000612. Caracas. 2022. 190 págs.",
    image: pasos,
    link: tras_los_pasos_de_glauder,
  },
  {
    id: 13,
    title: "La cultura ¿fetiche de la modernidad? Una visión descolonial",
    autor: "Rosa Elena Mujica Verasmendi",
    descriction:
      "ISBN: 978-980-7244-22-0. UNEARTE Ediciones. Depósito legal: DC2022000610, Caracas. 2022. 214 págs.",
    image: fetiche,
    link: la_cultura_fetiche,
  },
  {
    id: 7,
    title: "Antologia del Desorden",
    autor: "Efraín Valenzuela",
    descriction:
      "SBN 978-980-7244-20-6. UNEARTE Ediciones. Caracas, 2017. Primera edición. 106 págs.",
    image: antologia,
    link: antologia_del_desorden,
  },
  {
    id: 1,
    title: "Armonía I",
    autor: "Violeta Lárez",
    descriction:
      "ISBN 978-980 -12-2445-7. UNEARTE Ediciones. Caracas, 2011. Primera edición. 106 págs.",
    image: armoniaI,
    link: armonia1,
  },
  {
    id: 2,
    title: "Armonía II",
    autor: "Violeta Lárez",
    descriction:
      "ISBN 978-980-12-2697-0. UNEARTE Ediciones. Caracas, 2011. Primera edición. 232 págs.",
    image: armoniaII,
    link: armonia2,
  },
  {
    id: 8,
    title: "Bolívar Vive",
    autor: "José Gregorio Linares",
    descriction:
      "ISBN 978-980-7244-19-0. UNEARTE Ediciones. Caracas, 2017. Primera edición. 275 págs.",
    image: bolivarVive,
    link: bolivar_vive,
  },
  {
    id: 3,
    title: "Contrapunto para Hoy",
    autor: "Miguel Astor",
    descriction:
      "ISBN 978-980-12-2696-3. UNEARTE Ediciones. Caracas, 2016. Segunda edición revisada y aumentada. 457 págs.",
    image: contrapunto,
    link: contrapunto_para_hoy,
  },
  {
    id: 4,
    title: "El Cuerpo Como Territorio de la Rebeldía",
    autor: "Julie Barsley",
    descriction:
      "ISBN 980-6998-03-3. UNEARTE Ediciones. Caracas, 2013. Segunda edición revisada y aumentada. 244 págs.",
    image: cuerpoRebelde,
    link: cuerpo_rebelde,
  },
  {
    id: 9,
    title: "Hacia la Universidad del Pueblo",
    autor: "Alexandra Mulino (Compiladora)",
    descriction:
      "SBN 978-980-7244-21-3. UNEARTE Ediciones. Caracas, 2017. Primera edición. 432 págs.",
    image: universidadDelPueblo,
    link: universidad_del_pueblo,
  },
  {
    id: 10,
    title: "Manuelas y Camaradas",
    autor: "Luis Delgado Arria",
    descriction:
      "ISBN 978-980-7244-18-3. UNEARTE Ediciones. Caracas, 2017. Primera edición. 126 págs.",
    image: manuelas,
    link: manuelas_y_camaradas,
  },
  {
    id: 5,
    title: "Papeles Biográficos",
    autor: "Alejandro Otero",
    descriction:
      "ISBN 980-6050-82-7. UNEARTE Ediciones. Caracas, 2014. Segunda edición. 160 págs.",
    image: papelesBiograficos,
    link: papeles_biograficos,
  },
  {
    id: 6,
    title: "Vidas Maestras. Algunos Maestros de la Danza en Venezuela I",
    autor:
      "Miguel Issa (compilador). Textos y entrevistas de: María Isabel Franco, María Teresa García, Margarita Morales, Akaida Orozco, Marcela Rodríguez",
    descriction: "UNEARTE Ediciones. Caracas, 2010. Primera edición. 86 págs.",
    image: vidasMaestras,
    link: vidas_maestras,
  },
  {
    id: 7,
    title:
      "Panorama de Tradiciones Musicales de Venezuela. Tomo Manifestaciones Religiosas",
    autor:
      "Katrin Lengwinat, Ruth Suniaga. Con la colaboración de María Teresa Hernández",
    descriction:
      "ISBN 978-980-399-046-6. Colección de Musicología Latinoamericana Francisco Curt Lange (CELARG). Coedición CELARG-CENDIS-UNEARTE Ediciones. Caracas, 2013. Impreso julio 2014. Primera edición. 380 págs.",
    image: tradicionesMusicales,
    link: manifestaciones_religiosas,
  },
  {
    id: 11,
    title:
      "Quinteto para Violín, Viola, Violoncello, Contrabajo y Piano Opus 76",
    autor: "Federico Villena",
    descriction:
      "UNEARTE Ediciones. Depósito legal CP45320084. Caracas 2010, Primera edición digital 2017. 70 págs",
    image: quintetoIMG,
    link: quinteto,
  },
  {
    id: 12,
    title: "Trío Nº 2 para Violín, Violoncello y Piano Opus 138",
    autor: "Felipe Larrazábal",
    descriction:
      "UNEARTE Ediciones. Depósito legal CP45320085. Caracas 2010. Primera edición digital 2017. 58 págs.",
    image: trioIMG,
    link: trio,
  },
];

export const JOURNALS = [
  {
    id: 1,
    title: "Theatrón Nº 20 y 21. Año 16",
    description:
      "Número doble dedicado a José Ignacio Cabrujas. ISSN 1315-3250. UNEARTE Ediciones. Caracas, julio 2010. Primera edición. 148 págs.",
    image: theatron1,
    link: theatron_20_21,
  },
  {
    id: 2,
    title: "Theatrón Nº 22 y 23. Año 17",
    description:
      "Número doble dedicado al Teatro universitario venezolano. ISSN 1315-3250. UNEARTE Ediciones. Caracas, febrero 2013. Primera edición. 148 págs.",
    image: theatron2,
    link: theatron_22_23,
  },
  {
    id: 3,
    title: "Theatrón Nº 24 y 25. Año 18",
    description:
      "Número doble dedicado a Román Chalbaut. ISSN 1316-3250. UNEARTE Ediciones. Caracas, abril 2014. Primera edición. 241 págs.",
    image: theatron3,
    link: theatron_24_25,
  },
  {
    id: 7,
    title: "Theatrón Nº 26. Año 18",
    description:
      "Número doble dedicado a César Rengifo. ISSN 1315-3250. UNEARTE Ediciones. Caracas, abril 2016. Primera edición. 254 págs.",
    image: theatron26,
    link: theatron_26_27,
  },
  {
    id: 8,
    title: "Theatrón Nº 27. Año 20",
    description:
      "Número teatro venezolano hoy. ISSN 1315-3250. UNEARTE Ediciones. Caracas, abril 2017. Primera edición. 289 págs.",
    image: theatron27,
    link: theatron_27_2,
  },
  {
    id: 9,
    title: "Theatrón Nº 28. Año 20",
    description:
      "Número dedicado a Rodolfo Santana. ISSN 1315-3250. UNEARTE Ediciones. Caracas, octubre 2019. Primera edición. 260 págs.",
    image: theatron28,
    link: "",
  },
  {
    id: 10,
    title: "Theatrón Nº 29. Año 21",
    description:
      "Número dedicado al teatro en coedición con la Compañia Nacional de Teatro. ISSN 1315-3250. UNEARTE Ediciones. Caracas, noviembre 2020. Primera edición. 212 págs.",
    image: theatron29,
    link: "",
  },
  {
    id: 4,
    title:
      "Cuaderno de Oportunidades de Estudio de la Universidad Nacional Experimental de las Artes (UNEARTE)",
    description: "UNEARTE Ediciones. Caracas, octubre 2014.",
    image: oportunidades,
    link: oportunidades_de_estudio,
  },
  {
    id: 5,
    title:
      "Recopilación de Algunas Ponencias Presentadas en las Jornadas de Investigación de la UNEARTE Edición 2015",
    description:
      "ISBN 978-980-7244-14-5. UNEARTE Ediciones. Caracas, julio 2016. Primera edición. 530 págs.",
    image: jornadas2015,
    link: jornadas_de_investigacion_2015,
  },
  {
    id: 6,
    title:
      "Cuadernillo de Programación y Resúmenes de Ponencias de las Jornadas de Investigación de la UNEARTE Edición 2016",
    description: "UNEARTE Ediciones. Caracas, julio 2016. Primera edición.",
    image: jornadas2016,
    link: "",
  },
  {
    id: 11,
    title:
      "Cuadernillo de Programación y Resúmenes de Ponencias de las Jornadas de Investigación de la UNEARTE Edición 2017",
    description: "UNEARTE Ediciones. Caracas, julio 2017. Primera edición.",
    image: jornadas2017,
    link: "",
  },
  {
    id: 12,
    title: "MANIFIESTO DE LOS PREPARADORES",
    description:
      "ISBN 978-980-7244-15-2. UNEARTE Ediciones. Caracas, diciembre 2017. Primera edición.",
    image: manifiesto,
    link: manifiesto_de_los_preparadores,
  },
  {
    id: 13,
    title: "Tiyoctios Nº 01. Revista de artes y culturas del Sur de la UNEARTE",
    autor: "",
    description:
      "Revista de artes y culturas del Sur, de la Universidad Nacional Experimental de las Artes. Año 1, número 1. 2023.",
    image: imgTiyoctios01,
    link: tiyoctios01,
  },
  {
    id: 14,
    title: "Tiyoctios Nº 02. Revista de artes y culturas del Sur de la UNEARTE",
    autor: "",
    description:
      "Revista de artes y culturas del Sur, de la Universidad Nacional Experimental de las Artes. Año 1, número 2. 2024.",
    image: imgTiyoctios02,
    link: tiyoctios,
  },
];

export const NEWS = [
  {
    id: 1,
    title: "Arquitectos Venezolanos Contemporáneos",
    autor: "Edgar Cruz",
    description:
      "ISBN 978-980-7244-13-8. UNEARTE Ediciones. Caracas, 2016. Primera edición venezolana. 168 págs.",
    image: arquitectosVenezolanos,
    link: arquitectos_venezolanos,
  },
  {
    id: 3,
    title: "Cuadernos de Pensamiento y Creación Nº 3. Año 7",
    autor: "",
    description:
      "Número dedicado al Centenario de César Rengifo. ISSN 18568424. UNEARTE Ediciones. Caracas, abril 2015. Primera edición digital. 144 págs.",
    image: cuadernosPensamiento,
    link: cuadernos_de_pensamiento_y_creacion,
  },
  {
    id: 4,
    title: "Acordes Patrios. Cancionero de Alí Primera",
    autor: "",
    description:
      "Recopilación de canciones de Alí primera con sus partituras, acompañadas de textos del cantor del pueblo. ISBN 980-6050-82-7. Coedición MINCI-UNEARTE Ediciones. Caracas, 2015. Segunda edición. 150 págs.",
    image: "http://unearte.edu.ve/img/publications/acordes_patrios.jpg",
    link: acordes_patrios,
  },
  {
    id: 5,
    title:
      "Panorama de Tradiciones Musicales de Venezuela. Tomo Manifestaciones Profanas",
    autor: "Katrin Lengwinat. Con la colaboración de Sofía Barreto",
    description:
      "ISBN 978-980-7244-08-4. Colección de Musicología Latinoamericana Francisco Curt Lange (CELARG). Coedición CELARG-CENDIS-UNEARTE Ediciones. Caracas, 2015. 380 págs.",
    image: manifestacionesProfanas,
    link: manifestaciones_profanas,
  },
  {
    id: 2,
    title: "Programa Nacional de Formación en Artes y Culturas del Sur",
    autor: "",
    description:
      "Programa Nacional de Formación Avanzada en Artes y Culturas del Sur. Sedes del programa: Universidad Nacional Experimental de las Artes. Avenida México, Calle Tito Salas, Edificio Santa María, Mezanina, Bellas Artes, Caracas, 2016. 230 págs.",
    image: pnfa_img,
    link: Documento_PNFA,
  },
];
