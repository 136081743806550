import React from "react";

const CatedrasLibres = () => {
  return (
    <>
      <div>
        <div className="py-3 border-bottom">
          <p>
            Las cátedras libres son un espacio de intercambio de saberes de la
            comunidad universitaria y el Poder Popular, con el propósito de
            generar espacios de construcción colectiva.
          </p>
        </div>

        <div className="py-3 border-bottom">
          <h6 className="text-info py-3">
            Cátedra Libre Nuestroamericana Hugo Chávez
          </h6>

          <p className="py-3">
            Tiene como objetivo reflexionar y sistematizar conocimiento para su
            divulgación, acerca de los fundamentos e impacto del proyecto
            político de la Revolución Bolivariana en materia cultural, tomando
            como eje de análisis el pensamiento del Comandante Hugo Chávez. Para
            lo cual se desarrollarán mecanismos de promoción de líneas de
            investigación acerca del pensamiento bolivariano en materia
            cultural.
          </p>

          <p className="py-3">
            Coordinador: Jonathan Montilla /{" "}
            <a
              className="text-primary"
              href="mailto:jonathanmontilla@unearte.edu.ve"
            >
              jonathanmontilla@unearte.edu.ve
            </a>
          </p>

          <div id="accordion">
            <div class="py-3">
              <div class="" id="headingOne">
                <h5 class="mb-0">
                  <button
                    class="bg-transparent border-0 h6"
                    data-toggle="collapse"
                    data-target="#collapseOne"
                    aria-expanded="true"
                    aria-controls="collapseOne"
                  >
                    PROGRAMACIÓN
                  </button>
                </h5>
              </div>

              <div
                id="collapseOne"
                class="collapse show"
                aria-labelledby="headingOne"
                data-parent="#accordion"
              >
                <div class="card-body"></div>
              </div>
            </div>
          </div>
        </div>

        <div className="py-3 border-bottom">
          <h6 className="text-info py-3">
            Cátedra Libre para las Culturas Populares
          </h6>

          <p className="py-3">
            Tiene como objetivo sistematizar, producir y socializar conocimiento
            a partir del acercamiento a la diversidad de formas expresivas, así
            como con los modos de producción de ideas y bienes vinculados con
            las culturas populares; al tiempo que lograr vincular a la comunidad
            Uneartista con estas experiencias y saberes. El producto final debe
            ser conocimiento y formación, como elementos integradores,
            constructores y potenciadores del aparato social, cultural y
            productivo.
          </p>

          <p className="py-3">
            Coordinador: Fidel Barbarito /{" "}
            <a
              className="text-primary"
              href="mailto:fidelbarbarito@unearte.edu.ve"
            >
              fidelbarbarito@unearte.edu.ve
            </a>
          </p>

          <div id="accordion">
            <div class="py-3">
              <div class="" id="headingTwo">
                <h5 class="mb-0">
                  <button
                    class="bg-transparent border-0 h6"
                    data-toggle="collapse"
                    data-target="#collapseTwo"
                    aria-expanded="true"
                    aria-controls="collapseTwo"
                  >
                    PROGRAMACIÓN
                  </button>
                </h5>
              </div>

              <div
                id="collapseTwo"
                class="collapse show"
                aria-labelledby="headingTwo"
                data-parent="#accordion"
              >
                <div class="card-body"></div>
              </div>
            </div>
          </div>
        </div>

        <div className="py-3 border-bottom">
          <h6 className="text-info py-3">
            Cátedra Libre de Teatro Venezolano "José Gabriel Núñez"
          </h6>

          <p className="py-3">
            Tiene como objetivo ofrecer una alternativa académica para conocer,
            analizar y divulgar el teatro venezolano a través de la dramaturgia
            venezolana, tanto el histórico como el actual, visto desde todas sus
            facetas actuación, dirección, promoción, producción, investigación,
            educación, crítica, desde una perspectiva enraizada estructuralmente
            con el Poder Popular --esto es, en vinculación con las comunidades
            organizadas-- y en correspondencia con los objetivos del Plan de la
            Patria 2013-2019, en el sentido de: a) Garantizar la participación
            del Poder Popular, b) Potenciar las expresiones culturales
            liberadoras del pueblo, c) Incrementar sostenidamente la producción
            y distribución de bienes culturales a nivel nacional, d) Aumentar
            los espacios y la infraestructura cultural a disposición del pueblo,
            que permitan el desarrollo local de las artes.
          </p>

          <p className="py-3">
            Coordinador: Yumarsi Ovalles /{" "}
            <a
              className="text-primary"
              href="mailto:yumarsiovalles@unearte.edu.ve"
            >
              yumarsiovalles@unearte.edu.ve
            </a>
          </p>

          <div id="accordion">
            <div class="py-3">
              <div class="" id="headingThree">
                <h5 class="mb-0">
                  <button
                    class="bg-transparent border-0 h6"
                    data-toggle="collapse"
                    data-target="#collapseThree"
                    aria-expanded="true"
                    aria-controls="collapseThree"
                  >
                    PROGRAMACIÓN
                  </button>
                </h5>
              </div>

              <div
                id="collapseThree"
                class="collapse show"
                aria-labelledby="headingThree"
                data-parent="#accordion"
              >
                <div class="card-body"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CatedrasLibres;
