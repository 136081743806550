import React from "react";
import pdfImg from "../../assets/images/pdf_download_icon.png";

const Reglamentos = () => {
  return (
    <>
      <div className="container">
        <div className="container mt-3 p-4 bg-info d-flex flex-column">
          <h2 className="w-100 bg-info text-white m-0">REGLAMENTOS</h2>
          <span
            style={{ width: "100%", height: "3px", backgroundColor: "#fff" }}
          ></span>
        </div>

        <div className="container p-5">
          <table className="table table-bordered">
            <thead class="thead-dark">
              <tr>
                <th scope="col" className="text-center">
                  DESCARGA
                </th>
                <th scope="col" className="text-center">
                  DESCRIPCIÓN
                </th>
              </tr>
            </thead>

            <tbody>
              <tr>
                <th scope="" className="text-center align-middle">
                  <img src={pdfImg} alt="" />
                </th>
                <td className="align-middle">
                  <p>
                    Reglamento para la elaboración, entrega y evaluación del
                    trabajo especial de grado de los programas nacionales de
                    formación (PNF) y programas nacionales de formación avanzada
                    (PNFA) de la Universidad Nacional Experimental de las Artes
                  </p>
                </td>
              </tr>
              <tr>
                <th scope="" className="text-center align-middle">
                  <img src={pdfImg} alt="" />
                </th>
                <td className="align-middle">
                  <p>
                    Reglamento de ingreso, permanencia y egreso para los y las
                    estudiantes de los programas nacionales de formación
                  </p>
                </td>
              </tr>
              <tr>
                <th scope="" className="text-center align-middle">
                  <img src={pdfImg} alt="" />
                </th>
                <td className="align-middle">
                  <p>Reglamento disciplinario de los estudiantes</p>
                </td>
              </tr>
              <tr>
                <th scope="" className="text-center align-middle">
                  <img src={pdfImg} alt="" />
                </th>
                <td className="align-middle">
                  <p>Reglamento del personal docente</p>
                </td>
              </tr>
              <tr>
                <th scope="" className="text-center align-middle">
                  <img src={pdfImg} alt="" />
                </th>
                <td className="align-middle">
                  <p>Reglamento ejecutivo universitario</p>
                </td>
              </tr>
              <tr>
                <th scope="" className="text-center align-middle">
                  <img src={pdfImg} alt="" />
                </th>
                <td className="align-middle">
                  <p>
                    {" "}
                    Reglamento para la creación y funcionamiento de las Cátedras
                    Libres en la Universidad Nacional Experimiental de las Artes
                  </p>
                </td>
              </tr>
              <tr>
                <th scope="" className="text-center align-middle">
                  <img src={pdfImg} alt="" />
                </th>
                <td className="align-middle">
                  <p>
                    Reglamento para el funcionamiento de líneas de
                    investigación, producción y creación de saberes para las
                    artes y las culturas
                  </p>
                </td>
              </tr>
              <tr>
                <th scope="" className="text-center align-middle">
                  <img src={pdfImg} alt="" />
                </th>
                <td className="align-middle">
                  <p> Ley de Universidades</p>
                </td>
              </tr>
              <tr>
                <th scope="" className="text-center align-middle">
                  <img src={pdfImg} alt="" />
                </th>
                <td className="align-middle">
                  <p>
                    {" "}
                    Ley de servicio comunitario del estudiante de educación
                    superior
                  </p>
                </td>
              </tr>
              <tr>
                <th scope="" className="text-center align-middle">
                  <img src={pdfImg} alt="" />
                </th>
                <td className="align-middle">
                  <p>Decreto creación UNEARTE - 1</p>
                </td>
              </tr>
              <tr>
                <th scope="" className="text-center align-middle">
                  <img src={pdfImg} alt="" />
                </th>
                <td className="align-middle">
                  <p>Decreto creación UNEARTE - 2</p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default Reglamentos;
