import React, { useEffect, useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import { CEDES } from "./data";

const Contact = () => {
  const [form, setForm] = useState({
    nombre: "",
    email: "",
    phone: "",
    mensajes: "",
  });

  const formData = (event) => {
    const { name, value } = event.target;

    setForm(() => {
      return {
        ...form,
        [name]: value,
      };
    });
  };

  const sendForm = async (e) => {
    e.preventDefault();
    try {
      const { data } = await axios.post("/contacto", form);
      data.status === "success" &&
        Swal.fire({
          title: "Mensaje Enviado!",
          text: "Gracias por contactarnos!",
          icon: "success",
          confirmButtonText: "Ok",
        });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <div style={{ marginTop: "0px" }} className="row no-margin">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d62767.57612386667!2d-66.93890035547051!3d10.502752666929952!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8c2a592bc32c495b%3A0xdcd3f541b4182196!2sUniversidad%20Nacional%20Experimental%20de%20las%20Artes!5e0!3m2!1ses!2sve!4v1714753069850!5m2!1ses!2sve"
          width="100%"
          height="500"
          style={{ border: "0" }}
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>

      <div className="row contact-row no-margin">
        <div className="container">
          <div className="row">
            <form
              style={{ padding: "20px" }}
              className="col-sm-12"
              onSubmit={sendForm}
            >
              <h2 style={{ fontSize: "18px" }}>Formulario de Contacto</h2>
              <div className="row display-flex align-items-center mb-4">
                <div style={{}} className="col-sm-3">
                  <label>Nombre:</label>
                </div>
                <div className="col-sm-8 display-flex align-items-center ">
                  <input
                    type="text"
                    name="nombre"
                    placeholder="Ingrese su nombre"
                    required
                    className="form-control input-sm mb-0"
                    onChange={formData}
                  />
                </div>
              </div>
              <div
                className="row display-flex align-items-center mb-4"
                style={{ marginTop: "10px" }}
              >
                <div style={{}} className="col-sm-3">
                  <label>Correo Electrónico:</label>
                </div>
                <div className="col-sm-8 display-flex align-items-center">
                  <input
                    type="email"
                    name="email"
                    required
                    placeholder="Ingrese su correo electrónico"
                    className="form-control input-sm mb-0"
                    onChange={formData}
                  />
                </div>
              </div>
              <div
                className="row display-flex align-items-center mb-4"
                style={{
                  marginTop: "10px",
                }}
              >
                <div style={{}} className="col-sm-3">
                  <label>Teléfono:</label>
                </div>
                <div className="col-sm-8">
                  <input
                    type="text"
                    name="phone"
                    required
                    placeholder="Ingrese su número de teléfono"
                    className="form-control input-sm mb-0"
                    onChange={formData}
                  />
                </div>
              </div>
              <div
                className="row mb-4"
                style={{
                  marginTop: "10px",
                }}
              >
                <div style={{}} className="col-sm-3">
                  <label>Mensaje:</label>
                </div>
                <div className="col-sm-8">
                  <textarea
                    name="mensajes"
                    required
                    rows="5"
                    maxlength="255"
                    placeholder="Ingrese su mensaje"
                    className="form-control input-sm mb-0"
                    style={{ resize: "none" }}
                    onChange={formData}
                  ></textarea>
                </div>
              </div>
              <div
                className="row"
                style={{
                  marginTop: "10px",
                  display: "flex",
                  alignContent: "center",
                }}
              >
                <div style={{}} className="col-sm-3">
                  <label></label>
                </div>
                <div className="col-sm-8">
                  <button type="submit" className="btn btn-success btn-sm">
                    Enviar Mensaje
                  </button>
                </div>
              </div>
            </form>
            <div className="">
              <h1 className="text-center mt-5">Centros de Estudio</h1>
              <div className="row">
                {CEDES?.map((cede) => (
                  <div className="col-md-6" key={cede.id}>
                    <div style={{ margin: "30px" }} className="serv">
                      <div>
                        <p className="h5 font-weight-bold text-center">
                          {cede.name}
                        </p>
                        <p className="mt-2">
                          ● Dirección: <br />
                          <a
                            href={cede.addressLink}
                            className="text-info"
                            target="_blank"
                          >
                            {cede.address}
                          </a>
                        </p>
                        <p className="mt-2">
                          ● Email:
                          <br />
                          <a
                            href={`mailto:${cede.email}`}
                            className="text-info"
                          >
                            {" "}
                            {cede.email}
                          </a>
                        </p>
                        <p className="mt-2">
                          ● Coordinación Académica:
                          <br />
                          <a
                            href={`mailto:${cede.academicCoordination}`}
                            className="text-info"
                          >
                            {cede.academicCoordination}
                          </a>
                        </p>
                        <p className="mt-2">
                          ● Control de Estudios:
                          <br />
                          <a
                            href={`mailto:${cede.studyControl}`}
                            className="text-info"
                          >
                            {cede.studyControl}
                          </a>
                        </p>
                        {cede.phone === "" ? (
                          ""
                        ) : (
                          <p className="mt-2">
                            ● Teléfonos: <br />
                            <a href="#">{cede.phone}</a>
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Contact;
