import React from "react";
import { Link } from "react-router-dom";

import Logo from "../../assets/images/logo.png";

import {
  FaFacebookF,
  FaInstagram,
  FaXTwitter,
  FaYoutube,
} from "react-icons/fa6";

function Header() {
  return (
    <>
      <header className="position-relative">
        <div id="nav-head" className="header-nav relative">
          <div className="container">
            <div className="row">
              <div className="col-md-2 col-sm-12 nav-img d-md-flex align-items-center">
                <img src={Logo} alt="Logo" />
                <a data-toggle="collapse" data-target="#menu" to="#menu">
                  <i className="fas d-block d-md-none small-menu fa-bars"></i>
                </a>
              </div>
              <div id="menu" className="col-md d-none d-md-block nav-item ">
                <div className="nav_social d-md-flex d-block justify-content-md-end">
                  <Link
                    to="https://www.facebook.com/CulturasUnearteOficial"
                    target="_blank"
                  >
                    <FaFacebookF />
                  </Link>
                  <Link
                    to="https://www.instagram.com/culturasunearte"
                    target="_blank"
                  >
                    <FaInstagram />
                  </Link>
                  <Link
                    to="https://twitter.com/CulturasUNEARTE"
                    target="_blank"
                  >
                    <FaXTwitter />
                  </Link>
                  <Link
                    to="https://www.youtube.com/@CulturasUNEARTETV"
                    target="_blank"
                  >
                    <FaYoutube />
                  </Link>
                </div>

                <ul className="nav d-md-flex d-block justify-content-md-end align-items-center mt-3">
                  <li>
                    <Link to="/">Inicio</Link>
                  </li>

                  <li>
                    <Link to="/sobre_nosotros">Nosotros</Link>
                  </li>

                  <li>
                    <Link to="/noticias">Noticias</Link>
                  </li>

                  <li>
                    <Link to="/publicaciones">Publicaciones</Link>
                  </li>

                  <li>
                    <Link to="/contacto">Contactanos</Link>
                  </li>

                  <li className="li-btn-unearte">
                    <div class="btn-group">
                      <button
                        type="button"
                        class="btn dropdown-toggle btn-unearte"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        Mi UNEARTE
                      </button>
                      <div class="dropdown-menu dropdown-menu-left">
                        <Link
                          class="dropdown-item"
                          to="http://mi.unearte.edu.ve/inet/"
                        >
                          Inet
                        </Link>

                        <Link class="dropdown-item" to="/gaceta">
                          Gacetas
                        </Link>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
}

export default Header;
