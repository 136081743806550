import axios from "axios";

import {
  GET_ALL_NEWS,
  GET_BANNER,
  GET_BLOG,
  GET_CONTACT,
  GET_EVENTS,
  GET_GALLERY,
  GET_NEWS,
} from "./action-type";

export const getBanner = () => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get("/banner");
      dispatch({
        type: GET_BANNER,
        payload: data,
      });
    } catch (error) {
      console.error(error);
    }
  };
};

export const getBlog = () => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get("/blog");
      dispatch({
        type: GET_BLOG,
        payload: data,
      });
    } catch (error) {
      console.error(error);
    }
  };
};

export const getGallery = () => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get("/galeria");
      dispatch({
        type: GET_GALLERY,
        payload: data,
      });
    } catch (error) {
      console.error(error);
    }
  };
};

export const getEvents = () => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get("/eventos");
      dispatch({
        type: GET_EVENTS,
        payload: data,
      });
    } catch (error) {
      console.error(error);
    }
  };
};

export const getContact = () => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get("/contacto");
      dispatch({
        type: GET_CONTACT,
        payload: data,
      });
    } catch (error) {
      console.error(error);
    }
  };
};

export const getAllNews = () => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get(`/noticias`);
      dispatch({
        type: GET_ALL_NEWS,
        payload: data,
      });
    } catch (error) {
      console.error(error);
    }
  };
};

export const getNews = (id) => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get(`/noticias/${id}`);
      dispatch({
        type: GET_NEWS,
        payload: data.data[0],
      });
    } catch (error) {
      console.error(error);
    }
  };
};
