import React from "react";

const Accountability = () => {
  return (
    <>
      <div className="container p-5 table-responsive">
        <table className=" table table-bordered">
          <thead className="table-dark">
            <tr className="">
              <th scope="col" className="text-center">
                AÑO
              </th>
              <th scope="col">DESCARGA</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th scope="row" className="text-center align-middle">
                2024
              </th>
              <td>
                <p>ARCHIVO</p>
                <p>ARCHIVO</p>
                <p>ARCHIVO</p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
};

export default Accountability;
