export const CEDES = [
  {
    id: 1,
    name: "CECA Armando Reverón - Caracas",
    address:
      "Calle los Jabillos, Edificio UNEARTE Artes Plásticas Armando Reverón - Caracas - Código Postal: 1030",
    addressLink: "https://maps.app.goo.gl/ZziB6XQEtESNceZc9",
    phone: "+58 (212) 482-8991 / 482-0664 /482-1189",
    email: "centrodeestudios.armandoreveron@unearte.edu.ve",
    academicCoordination: "academico.armandoreveron@unearte.edu.ve",
    studyControl: "controldeestudios.armandoreveron@unearte.edu.ve",
  },
  {
    id: 2,
    name: "CECA Aquiles Nazoa - Caracas",
    address:
      "Edificio UNEARTE, cruce Avenida México con Avenida Norte - Sur 25 Urbanización El Conde Parroquia San Agustín, Caracas, Venezuela - Código Postal: 1010",
    addressLink: "",
    phone: "+58 (212) 571-2296 / 573-3208 / 577-6965",
    email: "centrodeestudios.plazamorelos@unearte.edu.ve",
    academicCoordination: "academico.plazamorelos@unearte.edu.ve",
    studyControl: "controldeestudios.plazamorelos@unearte.edu.ve",
  },
  {
    id: 3,
    name: "CECA Modesta Bor - Caracas",
    address:
      "Carretera Nacional Hoyo de la Puerta, Entrada de Tecnópolis USB Sartenejas, Baruta - Caracas, Venezuela - Código Postal: 1080",
    addressLink: "",
    phone: "+58 (212) 962-0378 / 962-0004 / 962-0710",
    email: "centrodeestudios.sartenejas@unearte.edu.ve",
    academicCoordination: "academico.sartenejas@unearte.edu.ve",
    studyControl: " controldeestudios.sartenejas@unearte.edu.ve",
  },
  {
    id: 4,
    name: "CECA Candelario Aray - Anzoátegui",
    address:
      "Avenida Constitución, Dentro de las Instalaciones del Parque Andrés Eloy Blanco, Puerto La Cruz - Estado Anzoátegui, Venezuela",
    addressLink: "",
    phone: "",
    email: "centrodeestudios.anzoategui@unearte.edu.ve",
    academicCoordination: "academico.anzoategui@unearte.edu.ve",
    studyControl: "controldeestudios.anzoategui@unearte.edu.ve",
  },
  {
    id: 5,
    name: "CECA Las Heroínas - Merída",
    address:
      "Módulo El Espejo, Calle 22 entre Avenida 7 y 8, Diagonal a la Plaza El Espejo, Parroquia El Sagrario - Estado Mérida, Venezuela",
    addressLink: "",
    phone: "",
    email: " centrodeestudios.merida@unearte.edu.ve",
    academicCoordination: "academico.merida@unearte.edu.ve",
    studyControl: "controldeestudios.merida@unearte.edu.ve",
  },
  {
    id: 6,
    name: "CECA Santiago Mariño - Nueva Esparta",
    address:
      "Valle del Espíritu Santo, Avenida Concepción Mariño, Frente a Casa Museo Mariño, Parroquia García - Estado Nueva Esparta, Venezuela",
    addressLink: "",
    phone: "",
    email: "centrodeestudios.nuevaesparta@unearte.edu.ve",
    academicCoordination: "academico.nuevaesparta@unearte.edu.ve",
    studyControl: "controldeestudios.nuevaesparta@unearte.edu.ve",
  },
  {
    id: 7,
    name: "CECA Argimiro Gabaldón - Portuguesa",
    address:
      "Avenida 5 de Diciembre, detrás de la Plaza Páez, Aldea Metropolitana, Árbol de las Tres Raíces, Acarigua - Estado Portuguesa, Venezuela",
    addressLink: "",
    phone: "",
    email: "centrodeestudios.portuguesa@unearte.edu.ve",
    academicCoordination: "academico.portuguesa@unearte.edu.ve",
    studyControl: "controldeestudios.portuguesa@unearte.edu.ve",
  },
];
