import {
  GET_ALL_NEWS,
  GET_BANNER,
  GET_BLOG,
  GET_CONTACT,
  GET_EVENTS,
  GET_GALLERY,
  GET_NEWS,
} from "./action-type";

const initialState = {
  banners: [],
  blog: [],
  gallery: [],
  events: [],
  contact: [],
  allNews: [],
  news: {},
  loading: true
};

const rootReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_BANNER:
      return {
        ...state,
        banners: action.payload.data,
      };
    case GET_BLOG: {
      return {
        ...state,
        blog: action.payload.data,
      };
    }
    case GET_GALLERY: {
      return {
        ...state,
        gallery: action.payload.data,
      };
    }
    case GET_EVENTS: {
      return {
        ...state,
        events: action.payload.data,
      };
    }
    case GET_CONTACT: {
      return {
        ...state,
        contact: action.payload.data,
      };
    }
    case GET_ALL_NEWS: {
      return {
        ...state,
        allNews: action.payload.data,
        loading: false
      };
    }
    case GET_NEWS: {
      return {
        ...state,
        news: action.payload,
      };
    }

    default:
      return state;
  }
};

export default rootReducer;
