import React from "react";
import img_left from "../../assets/images/cintillo_left.png";
import img_right from "../../assets/images/cintillo_right.png";
import logo from "../../assets/images/logo-footer-white.png";
import { Link } from "react-router-dom";
import organigrama from "../../assets/files/organigrama.pdf";

function Footer() {
  return (
    <>
      <footer>
        <div className="container">
          <div className="row">
            <div className="col-md-3 about">
              <img src={logo} />
              {/*
                <p className="mt-3">
                Phasellus scelerisque ornare nisl sit amet pulvinar. Nunc non
                scelerisque augue. Proin et sollicitudin velit.{" "}
              </p>
                 */}
            </div>

            <div className="col-md-3 glink">
              <h2 className="mb-2">La Universidad</h2>
              <ul>
                <li className="px-0 py-1">
                  <Link to="/contacto">Conctáctanos</Link>
                </li>
                <li className="px-0 py-1">
                  <Link
                    to={organigrama}
                    download={"Organigrama"}
                    target="_blank"
                  >
                    Organigrama
                  </Link>
                </li>
                <li className="px-0 py-1">
                  <Link to="/calendario_academico">Calendario Académico</Link>
                </li>
                <li className="px-0 py-1">
                  <Link to="/alma_mater">Misión Alma Mater</Link>
                </li>
                <li className="px-0 py-1">
                  <Link to="/reglamentos">Reglamento</Link>
                </li>
                <li className="px-0 py-1">
                  <Link to="/rendicion_de_cuentas">Rendición de Cuentas</Link>
                </li>
              </ul>
            </div>

            <div className="col-md-3 glink">
              <h2 className="mb-2">Enlaces</h2>
              <ul>
                <li className="px-0 py-1">
                  <Link to="http://www.mppeuct.gob.ve/" target="_blank">
                    MPP para la Educación Universitaria
                  </Link>
                </li>
                <li className="px-0 py-1">
                  <Link to="" target="_blank">
                    MPP para la Cultura
                  </Link>
                </li>
                <li className="px-0 py-1">
                  <Link to="http://ingreso.opsu.gob.ve/" target="_blank">
                    OPSU
                  </Link>
                </li>
                <li className="px-0 py-1">
                  <Link to="http://www.misionsucre.gob.ve/" target="_blank">
                    Misión Sucre
                  </Link>
                </li>
                <li className="px-0 py-1">
                  <Link to="www.gobiernoenlinea.gob.ve" target="_blank">
                    Gobierno en Línea
                  </Link>
                </li>
                <li className="px-0 py-1">
                  <Link to="http://mi.unearte.edu.ve/inet/" target="_blank">
                    Mi UNEARTE
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </footer>
      <div className="copy">
        <div className="container">
          <img src={img_left} alt="" />
          <span>
            <img src={img_right} alt="" />
          </span>
        </div>
      </div>
    </>
  );
}

export default Footer;
