import React from "react";

import acs from "../../../assets/images/pnfa_acs.jpg";
import pdfImg from "../../../assets/images/pdf_download_icon.png";

import { asteriskSVG } from "../../../assets/svg";
import { folleto_pnfa, tutorial_postulado } from "../../../assets/files";

const PostGrados = () => {
  return (
    <>
      <div className="">
        <img className="w-100" src={acs} alt="" />

        <div id="accordion">
          <div class="pt-5">
            <div class="p-3" id="heading">
              <h6 class="mb-0">
                <button
                  class="bg-transparent border-0"
                  data-toggle="collapse"
                  data-target="#collapseCA"
                  aria-expanded="true"
                  aria-controls="collapseCA"
                >
                  Artes y Cultura del Sur
                </button>
              </h6>
            </div>

            <div
              id="collapseCA"
              class="collapse show"
              aria-labelledby="heading"
              data-parent="#accordion"
            >
              <div class="card-body">
                <p className="mb-3">
                  El Programa Nacional de Formación Avanzada Artes y las
                  Culturas del Sur es un programa de estudios de postgrado
                  concebido como un sistema de formación concatenada y flexible.
                  Desde estudios de especialización en áreas específicas del
                  quehacer vinculado a la producción, reflexión, análisis de los
                  hechos artísticos y culturales, pasando por la formación de
                  maestría hasta llegar al doctorado en Artes y Culturas del
                  Sur, cuyo alcance aspira a consolidar la producción
                  teórico-práctica innovadora que fortalezca los procesos
                  socioculturales en la región, a fortalecer ele­mentos
                  orientadores en el complejo universo en disputa de las
                  experiencias sensi­bles y de la producción simbólica y
                  subjetiva.
                </p>

                <p>
                  El Programa se funda en tres (3) líneas de investigación que
                  son su columna vertebral. Han sido desarrolladas en
                  consonancia con las demandas y las oportuni­dades
                  identificadas en el campo cultural, tanto en Venezuela como en
                  Nuestramérica, a partir de diversos estudios y documentos
                  producidos por equipos de trabajo, y por investigadoras e
                  investigadores de UNEARTE.
                </p>

                <ul>
                  <p className="py-3">Las líneas son:</p>
                  <li className="py-2 pl-5">
                    <p>
                      {" "}
                      <strong> Soberanía cultural</strong>
                    </p>
                  </li>
                  <li className="py-2 pl-5">
                    <p>
                      {" "}
                      <strong> Arte y transformación social</strong>
                    </p>
                  </li>
                  <li className="py-2 pl-5">
                    <p>
                      <strong>Creación artística y cultural</strong>
                    </p>
                  </li>
                </ul>
                <p className="py-3">
                  Estas las líneas abrazan los procesos de creación de
                  conocimientos de la Universidad Experimental de las Artes, y
                  dibujan un territorio de experiencias, conoci­mientos e
                  investigaciones muy amplio, que va desde la vinculación y la
                  producción esté­tica comunitaria, hasta el registro personal
                  de esta vinculación; de lo considerado como artístico y
                  disciplinar hasta las manifestaciones más integrales de lo
                  cultural; de la pro­ducción de valor simbólico y material
                  hasta la circulación y el proceso social del trabajo; del
                  resguardo patrimonial al empoderamiento comunitario de los
                  excedentes simbólicos; de las experiencias estéticas
                  contextualizadas, disciplinarias y populares a la
                  investiga­ción socio-biopolítica y etnográfica del campo
                  cultural; del arte como herramienta de movilización social, al
                  arte como experiencia de salud personal y comunitaria.
                </p>

                <ul className="px-5 pb-4">
                  <li className="py-2">
                    <p>
                      <strong>Objetivos</strong>
                    </p>
                    <ul className="px-5">
                      <li className="py-2">
                        <p>
                          {asteriskSVG} Formar profesionales comprometidos con
                          la generación de procesos culturales y artísticos
                          liberadores, para quienes la creación permanente y la
                          reflexión crítica sean pilares fundamentales de la
                          construcción del ethos social.
                        </p>
                      </li>
                      <li className="py-2">
                        <p>
                          {asteriskSVG} Crear espacios de reflexión orientados
                          por los principios fundamentales de la Constitución de
                          la República Bolivariana de Venezuela, especialmente
                          los referidos al carácter irrenunciable del derecho a
                          los valores de la cultura del pueblo venezolano.
                        </p>
                      </li>
                      <li className="py-2">
                        <p>
                          {asteriskSVG} Aportar a Venezuela y a los pueblos
                          nuestroamericanos experiencias artísticas y culturales
                          que consoliden soberanía, hegemonía, independencia e
                          identidades locales, nacionales y regionales, para la
                          transformación de las realidades socioculturales
                          locales, nacionales y regionales.
                        </p>
                      </li>
                      <li className="py-2">
                        <p>
                          {asteriskSVG} Ejercer los principios del Estado
                          Docente y del pueblo-investigador de sus procesos
                          artísticos y culturales, a través de la
                          democratización de insumos y herramientas para la
                          creación e investigación artística y cultural.
                        </p>
                      </li>
                      <li className="py-2">
                        <p>
                          {asteriskSVG} Coadyuvar a la integración geopolítica
                          nuestramericana desde las prácticas culturales, en
                          tanto que formas orgánicas de reconocimiento y
                          construcción de un tejido común.
                        </p>
                      </li>
                      <li className="py-2">
                        <p>
                          {asteriskSVG} Promover la reflexión y el intercambio
                          cultural en el amplio Sur-Sur de los pueblos del
                          mundo.
                        </p>
                      </li>
                    </ul>
                  </li>

                  <li className="py-2">
                    <p className="pb-2">
                      <strong>Oferta Académica</strong>
                    </p>

                    <p className="px-5">
                      El PNFA en Artes y Culturas del Sur ofrece tres etapas
                      progresivas de formación conducentes a titulación de
                      cuarto nivel. El plan de prosecución se estructura en
                      abanico desde la especificidad de asuntos técnicos,
                      metodológicos y discursivos de los procesos artísticos y
                      culturales y de producción de significación y
                      empoderamiento regional. Avan­za hacia el desarrollo de
                      investigaciones en profundidad hasta el nivel doctoral,
                      donde se logra la producción de teorías renovadas y
                      contextualizadas, pertinentes, innovadoras y fundamentadas
                      en las propias concepciones nuestroamericanas de lo
                      artístico-cultural, las dinámicas sociales y los aparatos
                      epistémicos de liberación.
                    </p>
                  </li>
                </ul>

                <h6>Pre-inscripción</h6>
                <p className="mb-3">03 de mayo 2017 al 30 de junio de 2017</p>

                <h6>Inscripción</h6>
                <p className="mb-3">06 al 19 de julio 2017.</p>

                <h6>Inicio</h6>
                <p className="mb-3">04 de septiembre 2017.</p>

                <h6 className="pt-3">Especializaciones</h6>
                <p className="mb-3">
                  Las especializaciones de este PNFA desarrollan algunos de los
                  objetivos planteados en las líneas de investigación, a saber:
                  Economía cultural (de la línea de investigación en Sobe­ranía
                  Cultural), Arte y salud, y Educación para las artes y las
                  culturas (de la línea Arte y Trans­formación Social), y
                  Prácticas artísticas y culturales (de la línea Creación
                  artística y cultural).
                </p>

                <p className="mb-3">
                  Las especializaciones le dan continuidad a los estudios en
                  artes y culturas tanto de los PNF de UNEARTE como de los
                  programas universitarios de tercer nivel en artes y
                  pro­ducción simbólica del país, de de Nuestramerica y los
                  pueblos del sur. Son concebidas como estudios
                  transdisciplianarios para el abordaje de las funciones
                  económicas, edu­cativas, sanadoras y sicosociales de las
                  artes, así como para profundizar en las distintas naturalezas
                  de las prácticas propiamente artísticas, contextualizadas y
                  críticas.
                </p>

                <p className="mb-3">
                  Las especializaciones son el inicio y la base del PNFA Artes y
                  Culturas del Sur, y por ello son fundamentales para lograr la
                  continuidad de las y los estudiantes a lo largo de los
                  distintos niveles del programa.
                </p>

                <ul className="pb-4">
                  <p className="pb-3">Las especializaciones son:</p>
                  <li className="px-5 py-2">
                    <p>
                      <strong>Economía Cultural</strong>
                    </p>
                    <a className="text-info" href="">
                      Descargar Folleto
                    </a>
                  </li>

                  <li className="px-5 py-2">
                    <p>
                      <strong>Artes, educación y comunidad</strong>
                    </p>
                    <a className="text-info" href="">
                      Descargar Folleto
                    </a>
                  </li>

                  <li className="px-5 py-2">
                    <p>
                      <strong>Arte y salud</strong>
                    </p>
                    <a className="text-info" href="">
                      Descargar Folleto
                    </a>
                  </li>

                  <li className="px-5 py-2">
                    <p>
                      <strong>Prácticas artísticas y culturales</strong>
                    </p>
                    <a className="text-info" href="">
                      Descargar Folleto
                    </a>
                  </li>
                </ul>

                <h6 className="pb-3">Maestría en Artes y Culturas del Sur</h6>
                <p className="pb-3">
                  La Maestría en Artes y Culturas del Sur está orientada al
                  estudio de la experiencia estética y de la producción
                  simbólica y cultural en tanto que procesos y operadores
                  so­ciales complejos para la producción de soberanía cultural.
                  En lo específico, comporta el estudio transdisciplinario de la
                  creación artística y cultural en el abordaje de las realidades
                  psíquicas-sociales y de subjetivación comunitaria, así como de
                  la economía política cultu­ral, las políticas culturales y los
                  procesos educativos de las artes.
                </p>

                <p className="pb-3">
                  Esta maestría es concebida como una etapa de desarrollo de
                  investigaciones a profundidad que constituyan aportes
                  conceptuales, metodológicos y epistémicos con po­tencialidad
                  para fortalecer procesos y dinámicas de creación,
                  interpretación, formación, integración comunitaria y
                  transformación social, economía cultural y salud social y
                  perso­nal, vinculadas a las experiencias artísticas y
                  culturales de Venezuela y la región.
                </p>

                <p className="pb-3">
                  El programa permite la prosecución de cualquiera de las
                  especializaciones que pue­den avanzar hacia la maestría donde
                  serán validadas las U.C. cursadas durante la espe­cialización,
                  y se ofrecerá un abanico de UC optativas con un perfil
                  teórico-metodológico que contribuya a la investigación local,
                  nacional y regional en artes y culturas del Sur.
                </p>

                <p className="pb-3">
                  El sistema flexible permite que la y el participante construya
                  su perfil, pudiendo dar continuidad a sus estudios en una
                  línea de investigación diferente a la de la especializa­ción,
                  siempre y cuando curse la UC especializada de la línea a la
                  que desea incorporarse.
                </p>

                <div className="container p-5">
                  <table class="table table-bordered ">
                    <thead class="thead-dark">
                      <tr>
                        <th scope="col" className="text-center">
                          LAPSO (16 SESIONES)
                        </th>
                        <th scope="col" className="text-center">
                          Unidades curriculares
                        </th>
                        <th scope="col" className="text-center">
                          Unidades de crédito
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th scope="row" className="text-center align-middle">
                          1
                        </th>

                        <td className="align-middle">
                          <ul>
                            <li>
                              <p>2 UC obligatorias </p>
                            </li>
                            <li>
                              <p>1. Marcos legales y políticas </p>
                            </li>
                            <li>
                              <p>2. Espistemes culturales descolonizadoras </p>
                            </li>
                            <li>
                              <p>Seminario de investigación </p>
                            </li>
                          </ul>
                        </td>
                        <td className="text-center align-middle">9</td>
                      </tr>

                      <tr>
                        <th scope="row" className="text-center align-middle">
                          2
                        </th>

                        <td className="align-middle">
                          <ul>
                            <li>
                              <p>
                                Epistemes comparadas del sur (UC Obligatoria){" "}
                              </p>
                            </li>
                            <li>
                              <p>1 UC optativa </p>
                            </li>
                            <li>
                              <p>Seminario de investigación </p>
                            </li>
                          </ul>
                        </td>
                        <td className="text-center align-middle">9</td>
                      </tr>

                      <tr>
                        <th scope="row" className="text-center align-middle">
                          3
                        </th>

                        <td className="align-middle">
                          <ul>
                            <li>
                              <p>2 UC optativas</p>
                            </li>
                            <li>
                              <p>Seminario de investigación</p>
                            </li>
                          </ul>
                        </td>
                        <td className="text-center align-middle">9</td>
                      </tr>

                      <tr>
                        <th scope="row" className="text-center align-middle">
                          4
                        </th>

                        <td className="align-middle">
                          <ul>
                            <li>
                              <p>2 UC electivas / acreditables </p>
                            </li>
                            <li>
                              <p>Seminario de investigación</p>
                            </li>
                          </ul>
                        </td>
                        <td className="text-center align-middle">9</td>
                      </tr>

                      <tr>
                        <th scope="row" className="text-center align-middle">
                          Total
                        </th>
                        <td></td>
                        <td className="text-center align-middle">36</td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <div>
                  <h6 className="pb-3">
                    Doctorado en Artes y Culturas del Sur
                  </h6>

                  <p className="pb-3">
                    La formación doctoral tiene en sí misma la responsabilidad
                    de generar reflexión so­bre los debates y desafíos en torno
                    a las artes y las culturas del Sur. Aspira a fomentar
                    re­elaboraciones profundas y filosóficas, contextualizadas y
                    actuales de la función simbólica y las operaciones de lo
                    artístico y cultural en las comunidades de Venezuela y
                    Nuestramé­rica actual, incorporando los cuestionamientos
                    vigentes que interrogan sobre el propio sentido de la
                    actividad artística y simbólica, sobre los ámbitos de
                    registro y agencia de la acción artística y cultural, así
                    como sobre la naturaleza y propiedades de sus actores.
                    Procura el mayor grado de desarrollo teórico en el área de
                    las artes y las culturas del sur, con base en las
                    investigaciones desarrolladas en articulaciones dinámicas
                    con los parti­cipantes en el programa.
                  </p>

                  <p className="pb-3">
                    La propia actividad de producción docente tiene en este
                    nivel un ámbito para el de­bate y la conceptualización de
                    mayor alcance en la propia construcción de seminarios y UC
                    transdisciplinarias, terreno en el cual se fundan los
                    proyectos de investigación de los y las doctorantes, para
                    construir una fuerza de producción teórica en el área.
                  </p>

                  <div className="container p-5">
                    <table class="table table-bordered ">
                      <thead class="thead-dark">
                        <tr>
                          <th scope="col" className="text-center">
                            LAPSO (16 SESIONES)
                          </th>
                          <th scope="col" className="text-center">
                            Unidades curriculares
                          </th>
                          <th scope="col" className="text-center">
                            Unidades de crédito
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <th scope="row" className="text-center align-middle">
                            1
                          </th>

                          <td className="align-middle">
                            <ul>
                              <li>
                                <p>2 UC obligatorias </p>
                              </li>
                              <li>
                                <p>1. Marcos legales y políticas </p>
                              </li>
                              <li>
                                <p>
                                  2. Espistemes culturales descolonizadoras{" "}
                                </p>
                              </li>
                              <li>
                                <p>Seminario de investigación </p>
                              </li>
                            </ul>
                          </td>
                          <td className="text-center align-middle">9</td>
                        </tr>

                        <tr>
                          <th scope="row" className="text-center align-middle">
                            2
                          </th>

                          <td className="align-middle">
                            <ul>
                              <li>
                                <p>
                                  Epistemes comparadas del sur (UC Obligatoria){" "}
                                </p>
                              </li>
                              <li>
                                <p>1 UC optativa </p>
                              </li>
                              <li>
                                <p>Seminario de investigación </p>
                              </li>
                            </ul>
                          </td>
                          <td className="text-center align-middle">9</td>
                        </tr>

                        <tr>
                          <th scope="row" className="text-center align-middle">
                            3
                          </th>

                          <td className="align-middle">
                            <ul>
                              <li>
                                <p>2 UC optativas</p>
                              </li>
                              <li>
                                <p>Seminario de investigación</p>
                              </li>
                            </ul>
                          </td>
                          <td className="text-center align-middle">9</td>
                        </tr>

                        <tr>
                          <th scope="row" className="text-center align-middle">
                            4
                          </th>

                          <td className="align-middle">
                            <ul>
                              <li>
                                <p>2 UC electivas / acreditables </p>
                              </li>
                              <li>
                                <p>Seminario de investigación</p>
                              </li>
                            </ul>
                          </td>
                          <td className="text-center align-middle">9</td>
                        </tr>

                        <tr>
                          <th scope="row" className="text-center align-middle">
                            Total
                          </th>
                          <td></td>
                          <td className="text-center align-middle">45</td>
                        </tr>
                      </tbody>
                    </table>

                    <table className="table table-bordered">
                      <thead class="thead-dark">
                        <tr>
                          <th scope="col" className="text-center">
                            DESCARGA
                          </th>
                          <th scope="col" className="text-center">
                            DESCRIPCIÓN
                          </th>
                        </tr>
                      </thead>

                      <tbody>
                        <tr>
                          <th scope="" className="text-center align-middle">
                            <a href={folleto_pnfa} download={"Folleto PNFA"}>
                              <img src={pdfImg} alt="" />
                            </a>
                          </th>

                          <td className="align-middle">
                            <p>Folleto del PNFA en Artes y Cultural del Sur</p>
                          </td>
                        </tr>
                        <tr>
                          <th scope="" className="text-center align-middle">
                            <a
                              href={tutorial_postulado}
                              download={"Tutorial_Postulado"}
                            >
                              <img src={pdfImg} alt="" />
                            </a>
                          </th>

                          <td className="align-middle">
                            <p>
                              Instructivo para el registro, postulación e
                              inscripción
                            </p>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PostGrados;
