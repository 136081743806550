import React, { useState } from "react";
import QRCode from "react-qr-code";

import ofercaDeEstudio from "../../assets/images/oferta-de-estudio.jpg";
import ceca from "../../assets/images/ceca.jpg";
import imgGaceta from "../../assets/images/img-gaceta.png";

import { FaXmark } from "react-icons/fa6";

const gaceta = "/assets/files/GACETA-IX.pdf";
const file = "/assets/files/Descripción-de-carreras-de-pregrado.pdf";

const OfertaDeEstudio = () => {
  const images = [ofercaDeEstudio, ceca];
  const [image, setImage] = useState(null);

  const handleClick = (img) => {
    setImage(img);
  };

  const handleClose = () => {
    setImage(null);
  };

  return (
    <div className="container pt-5">
      <h1 className="text-center mb-5">Oferta Académica</h1>

      <div className="img-gaceta">
        <img src={imgGaceta} alt="" />

        <a href={gaceta} target="_blank">
          Descarga la última gaceta <span>aquí</span>
        </a>
      </div>

      <div className="images_container mt-5">
        <div className="image_container">
          {images?.map((image, index) => (
            <img
              key={index}
              src={image}
              className="img_thumbnail"
              alt={`img-${index}`}
              onClick={() => handleClick(image)}
            />
          ))}
        </div>

        <div className="image-expanded-container">
          {image && (
            <div className="image_expanded" onClick={handleClose}>
              <button className="close-img">
                <FaXmark />
              </button>
              <img
                className="image_large"
                src={image}
                alt="selected"
                onClick={(e) => e.stopPropagation()}
              />
            </div>
          )}
        </div>

        <div className="image-qr-container">
          <div>
            <QRCode className="img-qr" value={window.location.origin + file} />
          </div>

          <span>
            <h2>Para más información, escanea el código QR</h2>o descargalo
            haciendo click{" "}
            <a href={file} target="_blank">
              aquí
            </a>
          </span>
        </div>
      </div>
    </div>
  );
};

export default OfertaDeEstudio;
