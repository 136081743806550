import React from "react";

import ea from "../../../assets/images/pnfa_ea.jpg";
import pdfImg from "../../../assets/images/pdf_download_icon.png";
import {
  folleto_pnfa,
  triptico_economia,
  triptico_educacion,
  tutorial_postulado,
} from "../../../assets/files";

const CursoDePerfeccionamiento = () => {
  return (
    <>
      <div>
        <img className="w-100" src={ea} alt="" />

        <div>
          <div id="accordion">
            <div class="pt-5">
              <div class="p-3" id="heading">
                <h6 class="mb-0">
                  <button
                    class="bg-transparent border-0"
                    data-toggle="collapse"
                    data-target="#collapseEA"
                    aria-expanded="true"
                    aria-controls="collapseEA"
                  >
                    Educación para las artes y las culturas del Sur
                  </button>
                </h6>
              </div>

              <div
                id="collapseEA"
                class="collapse show"
                aria-labelledby="heading"
                data-parent="#accordion"
              >
                <div class="card-body">
                  <div className="pb-3">
                    <p>
                      <strong>Pre-inscripción</strong>
                    </p>
                    <p className="pl-5">
                      03 de mayo 2017 al 30 de junio de 2017
                    </p>
                  </div>
                  <div className="pb-3">
                    <p>
                      <strong>Inscripción</strong>
                    </p>
                    <p className="pl-5">06 al 19 de julio 2017</p>
                  </div>
                  <div className="pb-3">
                    <p>
                      <strong>Inicio</strong>
                    </p>
                    <p className="pl-5">04 de septiembre 2017</p>
                  </div>

                  <div>
                    <h6 className="py-3">Sinopsis</h6>
                    <p className="pb-3">
                      La educación para las artes y las culturas fortalece el
                      quehacer de educadoras y educadores vinculados a los
                      procesos de educación formal en todos sus niveles y
                      modalidades. Se busca potenciar los procesos de
                      investigación, creación de saberes, fortalecimiento y
                      capacitación docente, desde una perspectiva integral y
                      transdisciplinaria, con profundas bases filosóficas y
                      curriculares fundadas en la pertinencia social, la
                      formación integral, la constitución de redes, la
                      articulación extra-académica, la investigación y la
                      innovación.
                    </p>

                    <p className="pb-3">
                      Este curso de perfeccionamiento profesional, como
                      propuesta de estudios avanzados no conducente a grado,
                      desarrolla temáticas de interés y actualidad en el campo
                      de la educación liberadora y descolonial, desde lo
                      epistémico hasta lo que tiene que ver con la gestión, las
                      didácticas y la relación entre lo comunitario y lo
                      pedagógico.
                    </p>

                    <p className="pb-3">
                      El curso responde a la necesidad de crear puentes entre
                      las dinámicas artísticas-culturales y los procesos
                      educativos, en una sinergia necesaria para la
                      transformación de las tramas sociales, la visibilización
                      de los mecanismos de opresión simbólica y las estrategias
                      de emancipación de las subjetividades.
                    </p>

                    <p className="pb-3">
                      En el caso específico del PNFA Artes y Culturas del Sur de
                      la Unearte, el plan de estudio de este curso representa un
                      componente importante de la especialización en “Artes,
                      educación y comunidad”.
                    </p>
                  </div>

                  <div>
                    <h6 className="py-3">Propósitos</h6>

                    <ul className="list-unstyled">
                      <li className="pb-3">
                        <p className="pl-5">
                          Profundizar en los estudios y las prácticas educativas
                          enfocadas en los procesos artísticos y culturales
                          descoloniales.
                        </p>
                      </li>
                      <li className="pb-3">
                        <p className="pl-5">
                          Coadyuvar al desarrollo profesional de las y los
                          trabajadores docentes en el área de la educación para
                          las artes y las culturas, situada desde los ámbitos de
                          producción y existencia de nuestros pueblos.
                        </p>
                      </li>
                      <li className="pb-3">
                        <p className="pl-5">
                          Formar profesionales comprometidos con la generación
                          de procesos educativos, culturales y artísticos
                          liberadores, para quienes la creación permanente y la
                          reflexión crítica sean pilares fundamentales de la
                          construcción de las tramas sociales.
                        </p>
                      </li>
                    </ul>
                  </div>

                  <div>
                    <h6 className="py-3">Oferta académica</h6>

                    <table className="table table-bordered">
                      <thead class="thead-dark">
                        <tr>
                          <th scope="col" className="text-center">
                            UNIDAD CURRICULAR
                          </th>
                          <th scope="col" className="text-center">
                            N° DE HORAS
                          </th>
                          <th scope="col" className="text-center">
                            UNIDADES DE CRÉDITO
                          </th>
                        </tr>
                      </thead>

                      <tbody>
                        <tr>
                          <th scope="" className="text-center align-middle">
                            ESPISTEMES EDUCACIONALES DESCOLONIZADORAS
                          </th>

                          <td className="text-center align-middle">
                            <p>32</p>
                          </td>

                          <td className="text-center align-middle">
                            <p>3</p>
                          </td>
                        </tr>

                        <tr>
                          <th scope="" className="text-center align-middle">
                            ADMINISTRACIÓN Y GESTIÓN EDUCATIVA PARA LAS ARTES Y
                            LAS CULTURAS
                          </th>

                          <td className="text-center align-middle">
                            <p>32</p>
                          </td>

                          <td className="text-center align-middle">
                            <p>3</p>
                          </td>
                        </tr>

                        <tr>
                          <th scope="" className="text-center align-middle">
                            PEDAGOGIAS NUESTRAMERICANAS PARA LAS ARTES Y LAS
                            CULTURAS
                          </th>

                          <td className="text-center align-middle">
                            <p>32</p>
                          </td>

                          <td className="text-center align-middle">
                            <p>3</p>
                          </td>
                        </tr>

                        <tr>
                          <th scope="" className="text-center align-middle">
                            ARTE, CULTURA Y COMUNIDAD
                          </th>

                          <td className="text-center align-middle">
                            <p>32</p>
                          </td>

                          <td className="text-center align-middle">
                            <p>3</p>
                          </td>
                        </tr>

                        <tr>
                          <th scope="" className="text-center align-middle">
                            Total
                          </th>

                          <td className="text-center align-middle">
                            <p>192</p>
                          </td>

                          <td className="text-center align-middle">
                            <p>12</p>
                          </td>
                        </tr>
                      </tbody>
                    </table>

                    <table className="table table-bordered">
                      <thead class="thead-dark">
                        <tr>
                          <th scope="col" className="text-center">
                            DESCARGA
                          </th>
                          <th scope="col" className="text-center">
                            DESCRIPCIÓN
                          </th>
                        </tr>
                      </thead>

                      <tbody>
                        <tr>
                          <th scope="" className="text-center align-middle">
                            <a
                              href={triptico_educacion}
                              download={"Triptico_educacion"}
                            >
                              <img src={pdfImg} alt="" />
                            </a>
                          </th>

                          <td className="align-middle">
                            <p>
                              Tríptico CP Educación para las Artes y las
                              Cultural del Sur
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <th scope="" className="text-center align-middle">
                            <a
                              href={tutorial_postulado}
                              download={"tutorial_postulado"}
                            >
                              <img src={pdfImg} alt="" />
                            </a>
                          </th>

                          <td className="align-middle">
                            <p>
                              Instructivo para el registro, postulación e
                              inscripción
                            </p>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div id="accordion">
            <div class="pt-5">
              <div class="p-3" id="heading">
                <h6 class="mb-0">
                  <button
                    class="bg-transparent border-0"
                    data-toggle="collapse"
                    data-target="#collapseEC"
                    aria-expanded="true"
                    aria-controls="collapseEC"
                  >
                    Economía Cultural
                  </button>
                </h6>
              </div>

              <div
                id="collapseEC"
                class="collapse show"
                aria-labelledby="heading"
                data-parent="#accordion"
              >
                <div class="card-body">
                  <div className="pb-3">
                    <p>
                      <strong>Pre-inscripción</strong>
                    </p>
                    <p className="pl-5">
                      03 de mayo 2017 al 30 de junio de 2017
                    </p>
                  </div>
                  <div className="pb-3">
                    <p>
                      <strong>Inscripción</strong>
                    </p>
                    <p className="pl-5">06 al 19 de julio 2017</p>
                  </div>
                  <div className="pb-3">
                    <p>
                      <strong>Inicio</strong>
                    </p>
                    <p className="pl-5">04 de septiembre 2017</p>
                  </div>

                  <div>
                    <h6 className="py-3">Sinopsis</h6>
                    <p className="pb-3">
                      Al mirar en su completa dimensión a la cultura, pero
                      poniendo especial atención en su aspecto asociado a la
                      economía, detectamos un gigantesco potencial capaz de
                      tributar a la nación, no sólo en soberanía e identidad,
                      con la creación de imaginarios y estéticas, sino en
                      riqueza producto de la circulación y comercialización de
                      bienes y servicios culturales, tanto en el mercado
                      interno, como en el externo. La cultura como centro del
                      mercado de industrias culturales y creativas, a escala
                      regional y continental se transforma en un importante
                      motor dinamizador de la economía, además es capaz de
                      generar una gran cantidad de puestos de trabajo. Para que
                      todo esto ocurra es imprescindible formar el talento
                      humano, contribuir desde la academia a dotarlo de las
                      herramientas adecuadas y acompañarlo en el desarrollo de
                      las capacidades que requiere para estos tiempos.
                    </p>

                    <p className="pb-3">
                      Las y los egresados de este curso estarán la capacidad de
                      comprender el entorno en el cual desempeña su actividad
                      cultural, sean bienes o servicios, vinculados a los
                      aspectos económicos, sociales, políticos y legales del
                      mercado cultural, tendrá herramientas necesarias para
                      desarrollar actividades características o conexas a las
                      industrias culturales y creativas; reconociéndose como
                      agente económico y corresponsable de la construcción del
                      nuevo modelo productivo nacional.
                    </p>
                  </div>

                  <div>
                    <h6 className="py-3">Propósitos</h6>

                    <ul className="list-unstyled">
                      <li className="pb-3">
                        <p className="pl-5">
                          Coadyuvar en la formación de un gestor cultural que
                          ponga el conocimiento de la economía al servicio de la
                          cultura, entendida desde la diversidad, la identidad y
                          la soberanía. Gestor que logre reconocerse como un
                          nuevo agente económico, con su participación consolide
                          el sector cultural, haciendo de éste una fuente de
                          riqueza nacional desde una perspectiva opuesta a la
                          lógica neoliberal
                        </p>
                      </li>
                    </ul>
                  </div>

                  <div>
                    <h6 className="py-3">Oferta académica</h6>

                    <table className="table table-bordered">
                      <thead class="thead-dark">
                        <tr>
                          <th scope="col" className="text-center">
                            UNIDAD CURRICULAR
                          </th>
                          <th scope="col" className="text-center">
                            N° DE HORAS
                          </th>
                          <th scope="col" className="text-center">
                            UNIDADES DE CRÉDITO
                          </th>
                        </tr>
                      </thead>

                      <tbody>
                        <tr>
                          <th scope="" className="text-center align-middle">
                            CULTURA, CREACIÓN ESTÉTICA, PRODUCCIÓN Y CONSUMO
                            SIMBÓLICO
                          </th>

                          <td className="text-center align-middle">
                            <p>32</p>
                          </td>

                          <td className="text-center align-middle">
                            <p>3</p>
                          </td>
                        </tr>

                        <tr>
                          <th scope="" className="text-center align-middle">
                            ECONOMÍA Y CULTURA
                          </th>

                          <td className="text-center align-middle">
                            <p>32</p>
                          </td>

                          <td className="text-center align-middle">
                            <p>3</p>
                          </td>
                        </tr>

                        <tr>
                          <th scope="" className="text-center align-middle">
                            GESTIÓN Y POLÍTICAS CULTURALES
                          </th>

                          <td className="text-center align-middle">
                            <p>32</p>
                          </td>

                          <td className="text-center align-middle">
                            <p>3</p>
                          </td>
                        </tr>

                        <tr>
                          <th scope="" className="text-center align-middle">
                            MÉTODOS CUANTITATIVOS
                          </th>

                          <td className="text-center align-middle">
                            <p>32</p>
                          </td>

                          <td className="text-center align-middle">
                            <p>3</p>
                          </td>
                        </tr>

                        <tr>
                          <th scope="" className="text-center align-middle">
                            Total
                          </th>

                          <td className="text-center align-middle">
                            <p>192</p>
                          </td>

                          <td className="text-center align-middle">
                            <p>12</p>
                          </td>
                        </tr>
                      </tbody>
                    </table>

                    <table className="table table-bordered">
                      <thead class="thead-dark">
                        <tr>
                          <th scope="col" className="text-center">
                            DESCARGA
                          </th>
                          <th scope="col" className="text-center">
                            DESCRIPCIÓN
                          </th>
                        </tr>
                      </thead>

                      <tbody>
                        <tr>
                          <th scope="" className="text-center align-middle">
                            <a
                              href={triptico_economia}
                              download={"triptico_economia"}
                            >
                              <img src={pdfImg} alt="" />
                            </a>
                          </th>

                          <td className="align-middle">
                            <p>Tríptico CP Economía Cultural</p>
                          </td>
                        </tr>
                        <tr>
                          <th scope="" className="text-center align-middle">
                            <a
                              href={tutorial_postulado}
                              download={"tutorial_postulado"}
                            >
                              <img src={pdfImg} alt="" />
                            </a>
                          </th>

                          <td className="align-middle">
                            <p>
                              Instructivo para el registro, postulación e
                              inscripción
                            </p>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div id="accordion">
            <div class="pt-5">
              <div class="p-3" id="heading">
                <h6 class="mb-0">
                  <button
                    class="bg-transparent border-0"
                    data-toggle="collapse"
                    data-target="#collapseCC"
                    aria-expanded="true"
                    aria-controls="collapseCC"
                  >
                    Creación de contenidos comunicacionales
                  </button>
                </h6>
              </div>

              <div
                id="collapseCC"
                class="collapse show"
                aria-labelledby="heading"
                data-parent="#accordion"
              >
                <div class="card-body">
                  <div className="pb-3">
                    <p>
                      <strong>Pre-inscripción</strong>
                    </p>
                    <p className="pl-5">
                      03 de mayo 2017 al 30 de junio de 2017
                    </p>
                  </div>
                  <div className="pb-3">
                    <p>
                      <strong>Inscripción</strong>
                    </p>
                    <p className="pl-5">06 al 19 de julio 2017</p>
                  </div>
                  <div className="pb-3">
                    <p>
                      <strong>Inicio</strong>
                    </p>
                    <p className="pl-5">04 de septiembre 2017</p>
                  </div>

                  <div>
                    <h6 className="py-3">Sinopsis</h6>
                    <p className="pb-3">
                      Los procesos comunicaciones requieren ser abordados desde
                      la creación estética y cultural. Es necesario potenciar
                      los procesos de investigación, creación de saberes,
                      fortalecimiento y capacitación de comunicadores y
                      productores de imaginarios y sentidos comunes liberadores,
                      desde una perspectiva integral y transdisciplinaria, con
                      profundas bases filosóficas y curriculares fundadas en la
                      pertinencia social, la formación integral, la constitución
                      de redes, la articulación extra-académica, la
                      investigación y la innovación.
                    </p>

                    <p className="pb-3">
                      Este curso de perfeccionamiento profesional, como
                      propuesta de estudios avanzados no conducente a grado,
                      desarrolla temáticas de interés y actualidad en el campo
                      de la producción de contenidos comunicacionales, desde sus
                      implicaciones económicas y productivas, hasta las
                      estrategias de registro e investigación, pasando por las
                      políticas de producción de subjetividades y las narrativas
                      y matrices. La implementación de esta propuesta contribuye
                      con el fortalecimiento de las y los comunicadores y
                      promotores culturales, y responde a una necesidad sentida
                      de perfeccionamiento en esta área manifestada por las y
                      los profesionales de la comunicación en todo el país.
                    </p>

                    <p className="pb-3">
                      El curso responde a la necesidad de fortalecer las
                      competencias de profesionales de la comunicación para la
                      creación de contenidos liberadores, que surjan del estudio
                      constante y profundo de las realidades de nuestros
                      pueblos, para la defensa y potenciación de las
                      subjetividades del pueblo venezolano. Todo ello desde una
                      sinergia necesaria para la transformación de las tramas
                      sociales, la visibilización de los mecanismos de opresión
                      simbólica y las estrategias de emancipación de las
                      subjetividades.
                    </p>
                  </div>

                  <div>
                    <h6 className="py-3">Propósitos</h6>

                    <ul className="list-unstyled">
                      <li className="pb-3">
                        <p className="pl-5">
                          Profundizar en la creación y socialización de
                          narrativas, imaginarios y sentidos comunes para
                          afianzar la dignidad espiritual del pueblo venezolano.
                        </p>
                      </li>
                      <li className="pb-3">
                        <p className="pl-5">
                          Coadyuvar con el desarrollo profesional de las y los
                          trabajadores de la comunicación y la producción de
                          bienes simbólicos, situadas desde los ámbitos y las
                          realidades de nuestros pueblos.
                        </p>
                      </li>
                      <li className="pb-3">
                        <p className="pl-5">
                          Formar profesionales comprometidos con la creación de
                          productos, procesos y experiencias culturales
                          liberadoras, para quienes la investigación, la
                          creación permanente y la reflexión crítica sean
                          pilares fundamentales de la construcción de nuevos
                          sentidos comunes.
                        </p>
                      </li>
                    </ul>
                  </div>

                  <div>
                    <h6 className="py-3">Oferta académica</h6>

                    <table className="table table-bordered">
                      <thead class="thead-dark">
                        <tr>
                          <th scope="col" className="text-center">
                            UNIDAD CURRICULAR
                          </th>
                          <th scope="col" className="text-center">
                            N° DE HORAS
                          </th>
                          <th scope="col" className="text-center">
                            UNIDADES DE CRÉDITO
                          </th>
                        </tr>
                      </thead>

                      <tbody>
                        <tr>
                          <th scope="" className="text-center align-middle">
                            PRODUCCIÓN Y ECONOMÍA DE LA COMUNICACIÓN
                          </th>

                          <td className="text-center align-middle">
                            <p>32</p>
                          </td>

                          <td className="text-center align-middle">
                            <p>3</p>
                          </td>
                        </tr>

                        <tr>
                          <th scope="" className="text-center align-middle">
                            CREACIÓN DE NARRATIVAS, MATRICES Y SENTIDOS COMUNES
                          </th>

                          <td className="text-center align-middle">
                            <p>32</p>
                          </td>

                          <td className="text-center align-middle">
                            <p>3</p>
                          </td>
                        </tr>

                        <tr>
                          <th scope="" className="text-center align-middle">
                            SUBJETIVIDADES, MEMORIAS Y SABERES
                          </th>

                          <td className="text-center align-middle">
                            <p>32</p>
                          </td>

                          <td className="text-center align-middle">
                            <p>3</p>
                          </td>
                        </tr>

                        <tr>
                          <th scope="" className="text-center align-middle">
                            INVESTIGACIÓN, TÉCNICAS Y MODOS DE REGISTRO
                          </th>

                          <td className="text-center align-middle">
                            <p>32</p>
                          </td>

                          <td className="text-center align-middle">
                            <p>3</p>
                          </td>
                        </tr>

                        <tr>
                          <th scope="" className="text-center align-middle">
                            Total
                          </th>

                          <td className="text-center align-middle">
                            <p>192</p>
                          </td>

                          <td className="text-center align-middle">
                            <p>12</p>
                          </td>
                        </tr>
                      </tbody>
                    </table>

                    <table className="table table-bordered">
                      <thead class="thead-dark">
                        <tr>
                          <th scope="col" className="text-center">
                            DESCARGA
                          </th>
                          <th scope="col" className="text-center">
                            DESCRIPCIÓN
                          </th>
                        </tr>
                      </thead>

                      <tbody>
                        <tr>
                          <th scope="" className="text-center align-middle">
                            <a
                              href={tutorial_postulado}
                              download={"tutorial_postulado"}
                            >
                              <img src={pdfImg} alt="" />
                            </a>
                          </th>

                          <td className="align-middle">
                            <p>
                              Instructivo para el registro, postulación e
                              inscripción
                            </p>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CursoDePerfeccionamiento;
