import React from "react";

const AcademicCalendar = () => {
  return (
    <>
      <div className="container">Calendario Académico</div>
    </>
  );
};

export default AcademicCalendar;
