import mujabo from "../../assets/images/centro_de_estudio/mujabo.jpg";
import plazaMorelos from "../../assets/images/centro_de_estudio/plazamorelos.jpg";
import sartenejas from "../../assets/images/centro_de_estudio/sartenejas.jpg";
import armandoReveron from "../../assets/images/centro_de_estudio/armandoreveron.jpg";
import anazategui from "../../assets/images/centro_de_estudio/anzoategui.jpg";
import merida from "../../assets/images/centro_de_estudio/merida.jpg";
import nuevaesparta from "../../assets/images/centro_de_estudio/nuevaesparta.jpg";
import portuguesa from "../../assets/images/centro_de_estudio/portuguesa.jpg";

export const CECA = [
  {
    title: "Museo Jacobo Borges - Caracas",
    description:
      'Avenida Sucre, Parque del Oeste "Alí Primera", a 20 metros de la Estación Metro Gato Negro, Caracas',
    link: "",
    img: mujabo,
  },
  {
    title: "CECA Aquiles Nazoa - Caracas",
    description:
      "Edificio UNEARTE, cruce Avenida México con Avenida Norte - Sur 25 Urbanización El Conde, Parroquia San Agustín, Caracas",
    link: "",
    img: plazaMorelos,
  },
  {
    title: "CECA Modesta Bor - Caracas",
    description:
      "Carretera Nacional Hoyo de la Puerta, Entrada de Tecnópolis USB Sartenejas, Baruta, Caracas",
    link: "",
    img: sartenejas,
  },
  {
    title: "CECA Armando Reverón - Caracas",
    description:
      "Calle los Jabillos, Edificio UNEARTE Artes Plásticas Armando Reverón, Caño Amarillo, Caracas",
    link: "",
    img: armandoReveron,
  },
  {
    title: "CECA Candelario Aray - Anzoátegui",
    description:
      "Avenida Constitución, Dentro de las Instalaciones del Parque Andrés Eloy Blanco, Puerto La Cruz",
    link: "",
    img: anazategui,
  },
  {
    title: "CECA Las Heroínas - Merída",
    description:
      "Módulo El Espejo, Calle 22 entre Avenida 7 y 8, Diagonal a la Plaza El Espejo, Parroquia El Sagrario",
    link: "",
    img: merida,
  },
  {
    title: "CECA Santiago Mariño - Nueva Esparta",
    description:
      "Valle del Espíritu Santo, Avenida Concepción Mariño, Frente a Casa Museo Mariño, Parroquia García, Nueva Esparta",
    link: "",
    img: nuevaesparta,
  },
  {
    title: "CECA Argimiro Gabaldón - Portuguesa",
    description:
      "Avenida 5 de Diciembre, detrás de la Plaza Páez, Aldea Metropolitana, Árbol de las Tres Raíces, Acarigua",
    link: "",
    img: portuguesa,
  },
];
