import React from "react";

import { GACETAS } from "./data";

const Gaceta = () => {
  return (
    <>
      <div className="container mt-3 p-4 bg-danger d-flex flex-column">
        <h2 className="w-100 text-white m-0">GACETA</h2>
        <span
          style={{ width: "100%", height: "3px", backgroundColor: "#fff" }}
        ></span>
      </div>

      <div className="our-blog bb3 pc2">
        <div className="container">
          <div className="row-blog row">
            <div className="col-md vbf mx-auto">
              <div className="border-bottom text-info mt-3 mb-4">
                <div className="row">
                  {GACETAS.map((gaceta) => (
                    <div className="col-lg-6">
                      <div key={gaceta.id} class="card mb-3 p-3">
                        <div class="d-flex">
                          <div class="">
                            <img
                              src={gaceta.imagen}
                              class="img-fluid rounded-start object-fit-cover"
                              alt=""
                              style={{
                                minWidth: "120px",
                                height: "180px",
                                objectFit: "fill",
                              }}
                            />
                          </div>

                          <div class="">
                            <div class="card-body p-0 pl-3">
                              <h5 class="card-title mb-0">{gaceta.titulo}</h5>
                              <p className="mb-2">
                                <small style={{ color: "#aaa" }}>
                                  {gaceta.descripcion}
                                </small>
                              </p>
                              <p
                                class="card-text"
                                style={{ fontSize: ".8rem" }}
                              >
                                {gaceta.edicion}
                              </p>
                            </div>
                          </div>
                        </div>

                        <div className=" mt-3 border-top w-100  pt-3 d-flex align-items-center justify-content-between">
                          <a
                            href={gaceta.link}
                            download={gaceta.titulo}
                            className="btn btn-danger"
                            target="_blank"
                          >
                            Descargar
                          </a>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Gaceta;
