import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { getNews } from "../../redux/actions";

const NewsDescription = () => {
  const { id } = useParams();

  const navigation = useNavigate();

  const [loading, setLoading] = useState(true);

  const dispatch = useDispatch();

  const news = useSelector((state) => state.news);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();

    const formattedDay = day < 10 ? `0${day}` : day;
    const formattedMonth = month < 10 ? `0${month}` : month;

    return `${formattedDay}/${formattedMonth}/${year}`;
  };

  const goBack = () => {
    navigation("/noticias");
  };

  useEffect(() => {
    setLoading(true);
    dispatch(getNews(id))
      .then(() => {
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });

    window.scrollTo(0, 0);
  }, [dispatch, id]);

  return (
    <>
      {loading ? (
        <div className="container py-5 text-center">
          <p>Cargando...</p>
        </div>
      ) : (
        <div className="container pb-5 px-5">
          <button className="btn btn-secondary mt-3 mb-2" onClick={goBack}>
            Volver
          </button>
          <div>
            <h1>{news.titulo}</h1>
            <h6 className="text-muted font-weight-lighter">
              {formatDate(news.fecha)}
            </h6>
            <img src={news.imagen} alt="" className="w-100 mb-2 img-fluid" />
            <div dangerouslySetInnerHTML={{ __html: news.resumen }} />
            <div dangerouslySetInnerHTML={{ __html: news.contenido }} />
          </div>
          <button className="btn btn-secondary mt-3 mb-2" onClick={goBack}>
            Volver
          </button>
        </div>
      )}
    </>
  );
};

export default NewsDescription;
