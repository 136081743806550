import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

// Images
import ingreso from "../../assets/images/ingreso.png";
import pnf from "../..//assets/images/pnf.png";
import comunidad from "../../assets/images/comunidad.png";
import avanzado from "../../assets/images/avanzado.png";
import saberes from "../../assets/images/saberes.png";
import { getAllNews, getBanner } from "../../redux/actions";
import FormatDate from "../../components/FormatDate";
import { Link } from "react-router-dom";
import { CECA } from "./CECA";
import OfertaDeEstudio from "../../sections/OfertaDeEstudio/OfertaDeEstudio";
import Gaceta from "../../sections/Gaceta/Gaceta";

const Home = () => {
  const dispatch = useDispatch();

  const banners = useSelector((state) => state.banners);
  const news = useSelector((state) => state.allNews);
  const loading = useSelector((state) => state.loading);

  // Filter out non-featured news, sort by ID in descending order, and take the first 3
  const arrNews = news
    ?.filter((e) => e.destacado != 1)
    .sort((a, b) => b.id - a.id)
    .slice(0, 3);

  // Sort banners so that those with a title appear first.
  const arrBanners = banners?.sort((a, b) => {
    const titleExist = (b.titulo ? 1 : 0) - (a.titulo ? 1 : 0);

    return b.id - a.id;
  });

  console.log(arrBanners);
  useEffect(() => {
    dispatch(getAllNews());
    dispatch(getBanner());
  }, [dispatch]);

  return (
    <>
      <div className="container">
        <div
          id="carouselExampleIndicators"
          class="carousel slide my-sm-5"
          data-ride="carousel"
        >
          <div class="carousel-inner">
            {arrBanners?.map((banner, index) => (
              <div
                key={index}
                className={"carousel-item" + (index === 0 ? " active" : "")}
              >
                <img
                  src={banner.imagen}
                  alt={"IMG " + (index + 1)}
                  className="carousel-img"
                />
                {banner.titulo && (
                  <div class="carousel-caption d-md-block text-white">
                    <Link to={`/noticias/${banner.id}`}>
                      <h6 className="text-white">{banner.titulo}</h6>
                    </Link>
                  </div>
                )}
              </div>
            ))}
          </div>
          <a
            class="carousel-control-prev"
            href="#carouselExampleIndicators"
            role="button"
            data-slide="prev"
          >
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="sr-only">Previous</span>
          </a>
          <a
            class="carousel-control-next"
            href="#carouselExampleIndicators"
            role="button"
            data-slide="next"
          >
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="sr-only">Next</span>
          </a>
        </div>
      </div>

      {/* <!-- ################# Key Features Starts Here#######################---> */}
      <div className="key-features">
        <div className="container">
          <div className="row key-row no-margin">
            <Link to={"/ingreso"} className="box">
              <img src={ingreso} alt="" />
              <p className="">Ingreso</p>
            </Link>
            <Link to={"/pnf"} className="box">
              <img src={pnf} alt="" />
              <p className="">Programa Nacional de Formación</p>
            </Link>
            <Link to="/estudios_avanzados" className="box c1">
              <img src={avanzado} alt="" />
              <p className="">Estudios Avanzados</p>
            </Link>
            <Link to="/poder_popular" className="box">
              <img src={comunidad} alt="" />
              <p className="">Poder Popular</p>
            </Link>
            <Link to={"/saberes"} className="box">
              <img src={saberes} alt="" />
              <p className="">Creación de Saberes</p>
            </Link>
          </div>
        </div>
      </div>

      {/* <!-- ################# NOTICIAS Starts Here#######################---> */}

      <div className="doctor-message my-sm-5">
        <div className=" d-flex flex-column">
          <div className="container">
            <div className="row session-title">
              <h1>Noticias</h1>
            </div>
            {arrNews.length === 0 ? (
              <div className="text-center">
                {loading === true ? (
                  <h3 className="">Cargando...</h3>
                ) : (
                  <h3 className="text-danger">Noticias no disponibles</h3>
                )}
              </div>
            ) : (
              <div className="d-flex flex-column">
                <div className="row">
                  {arrNews?.map((n) => (
                    <div className="col-md-4 mb-3" key={n.id}>
                      <div className="courc-card">
                        <img
                          src={n.imagen}
                          alt={n.titulo}
                          style={{
                            height: "180px",
                            width: "100%",
                            objectFit: "cover",
                          }}
                        />
                        <div className="cource-det mt-2">
                          <Link to={`/noticias/${n.id}`}>
                            <h6 className="m-0 text-info">{n.titulo}</h6>
                          </Link>
                          <span className="d-block border-bottom py-1">
                            {<FormatDate fecha={n.fecha} />}
                          </span>
                          <ul>
                            <li className="px-0 pt-2">
                              <div
                                dangerouslySetInnerHTML={{ __html: n.resumen }}
                              />
                            </li>
                            <li className="text-right">
                              <Link
                                to={`/noticias/${n.id}`}
                                className="text-primary"
                              >
                                Leer mas
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>

                <div className="text-center mt-4">
                  <Link
                    to="/noticias"
                    className="mt-5 py-1 px-3 h3 btb btn-info"
                  >
                    Ver más...
                  </Link>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      <OfertaDeEstudio />

      {/* <!-- ################# Centros de Estudio y Creación Artística Starts Here#######################---> */}
      <div className="popular-cources">
        <div className="container">
          <div className="session-title row">
            <h2>Centros de Estudio y Creación Artística</h2>
          </div>
          <div className="row courc-ro">
            {CECA?.map((data, index) => (
              <a key={index} href={data.link} className="col-md-4">
                <div className="courc-card">
                  <img src={data.img} alt={data.title} />
                  <div className="cource-det">
                    <h6>{data.title}</h6>
                    <ul>
                      <li>
                        <p>{data.description}</p>
                      </li>
                    </ul>
                  </div>
                </div>
              </a>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
