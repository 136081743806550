import React from "react";

const GruposEstables = () => {
  return (
    <>
      <div>
        <div className="py-3 border-bottom">
          <p>
            Los grupos estables son espacios Artísticos de desarrollo de toda la
            comunidad universitaria con el objeto de contribuir con una
            formación integral y nuestra americana.
          </p>
        </div>

        <div className="py-3 border-bottom">
          <h6 className="text-info py-3">Orfeón Universitario</h6>

          <p className="py-3"></p>

          <p className="py-3">
            Centro de Estudio y Creación Artística: Plaza Morelos
          </p>

          <p className="py-3">Director: Orlando Sandoval</p>

          <div id="accordion">
            <div class="py-3">
              <div class="" id="headingOU">
                <h5 class="mb-0">
                  <button
                    class="bg-transparent border-0 h6"
                    data-toggle="collapse"
                    data-target="#collapseOU"
                    aria-expanded="true"
                    aria-controls="collapseOU"
                  >
                    DETALLES
                  </button>
                </h5>
              </div>

              <div
                id="collapseOU"
                class="collapse show"
                aria-labelledby="headingOU"
                data-parent="#accordion"
              >
                <div class="card-body"></div>
              </div>
            </div>
          </div>
        </div>

        <div className="py-3 border-bottom">
          <h6 className="text-info py-3">Manifestaciones Tradicionales</h6>

          <p className="py-3"></p>

          <p className="py-3">
            Centro de Estudio y Creación Artística: Plaza Morelos
          </p>

          <p className="py-3">Directora: Alicia Núñez</p>

          <div id="accordion">
            <div class="py-3">
              <div class="" id="headingMT">
                <h5 class="mb-0">
                  <button
                    class="bg-transparent border-0 h6"
                    data-toggle="collapse"
                    data-target="#collapseMT"
                    aria-expanded="true"
                    aria-controls="collapseMT"
                  >
                    DETALLES
                  </button>
                </h5>
              </div>

              <div
                id="collapseMT"
                class="collapse show"
                aria-labelledby="headingMT"
                data-parent="#accordion"
              >
                <div class="card-body"></div>
              </div>
            </div>
          </div>
        </div>

        <div className="py-3 border-bottom">
          <h6 className="text-info py-3">Estudiantina</h6>

          <p className="py-3"></p>

          <p className="py-3">
            Centro de Estudio y Creación Artística: Portuguesa
          </p>

          <p className="py-3">Director: Yumarsi PérEz</p>

          <div id="accordion">
            <div class="py-3">
              <div class="" id="headingE">
                <h5 class="mb-0">
                  <button
                    class="bg-transparent border-0 h6"
                    data-toggle="collapse"
                    data-target="#collapseE"
                    aria-expanded="true"
                    aria-controls="collapseE"
                  >
                    DETALLES
                  </button>
                </h5>
              </div>

              <div
                id="collapseE"
                class="collapse show"
                aria-labelledby="headingE"
                data-parent="#accordion"
              >
                <div class="card-body"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default GruposEstables;
