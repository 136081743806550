import React, { useEffect, useState } from "react";

import { BOOKS, JOURNALS, NEWS } from "./data";

const Publications = () => {
  return (
    <>
      <div className="container mt-3 p-4 bg-danger d-flex flex-column">
        <h2 className="w-100 text-white m-0">PUBLICACIONES</h2>
        <span
          style={{ width: "100%", height: "3px", backgroundColor: "#fff" }}
        ></span>
      </div>

      <div className="our-blog bb3 pc2">
        <div className="container">
          <div className="row-blog row">
            <p
              className="text-center pb-5 px-5 "
              style={{ fontSize: "1.5rem" }}
            >
              La <strong>Universidad Nacional Experimental de las Artes</strong>{" "}
              pone a disposición de la comunidad universitaria las obras
              publicadas en formato digital con el propósito de visibilizar y
              difundir la creación y producción investigativa que se genera en
              nuestra casa de estudios. Descarga de forma gratuita tanto
              nuestras colecciones como las novedades editoriales.
            </p>

            <div className="col-md vbf mx-auto">
              <div>
                <h1 className="border-bottom text-info mt-3 mb-4">Libros</h1>
                <div className="row">
                  {BOOKS?.map((book) => (
                    <div className="col-lg-6" key={book.id}>
                      <div class="card mb-3 p-4">
                        <div class="d-flex">
                          <div class="">
                            <img
                              src={book.image}
                              class="img-fluid rounded-start object-fit-cover"
                              alt={book.title}
                              style={{
                                minWidth: "120px",
                                height: "180px",
                                objectFit: "fill",
                              }}
                            />
                          </div>

                          <div class="">
                            <div class="card-body p-0 pl-3">
                              <h5 class="card-title mb-0">{book.title}</h5>
                              <p className="mb-2">
                                <small style={{ color: "#aaa" }}>
                                  {book.autor}
                                </small>
                              </p>
                              <p
                                class="card-text"
                                style={{ fontSize: ".8rem" }}
                              >
                                {book.descriction}
                              </p>
                            </div>
                          </div>
                        </div>

                        <div className=" mt-3 border-top w-100  pt-3 d-flex align-items-center justify-content-between">
                          <span className="text-success">Gratis</span>
                          <a
                            href={book.link}
                            download={book.title}
                            className="btn btn-danger"
                            target="_blank"
                          >
                            Descargar
                          </a>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>

              {/* REVISTAS */}
              <div>
                <h1 className="border-bottom text-info mt-5 mb-4">Revistas</h1>
                <div className="row">
                  {JOURNALS?.sort((a, b) => b.id - a.id).map((journal) => (
                    <div className="col-lg-6" key={journal.id}>
                      <div
                        class="card mb-3 p-4 justify-content-between"
                        style={{ minHeight: "290px" }}
                      >
                        <div class="d-flex">
                          <div class="">
                            <img
                              src={journal.image}
                              class="img-fluid rounded-start object-fit-cover"
                              alt={journal.title}
                              style={{ minWidth: "120px" }}
                            />
                          </div>
                          <div class="">
                            <div class="card-body p-0 pl-3">
                              <h5 class="card-title mb-0">{journal.title}</h5>
                              <p
                                class="card-text mt-3"
                                style={{ fontSize: ".8rem" }}
                              >
                                {journal.description}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className=" mt-3 border-top w-100 h-25  pt-3 d-flex align-items-center justify-content-between">
                          <span className="text-success">Gratis</span>
                          <a
                            href={journal.link}
                            download={journal.title}
                            className="btn btn-danger"
                          >
                            Descargar
                          </a>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>

                {/* NOVEDADES */}
                <div>
                  <h1 className="border-bottom text-info mt-3 mb-4 mt-5">
                    Novedades Editoriales
                  </h1>
                  <div className="row">
                    {NEWS?.map((n) => (
                      <div className="col-lg-6">
                        <div class="card mb-3 p-4">
                          <div class="d-flex">
                            <div class="">
                              <img
                                src={n.image}
                                class="img-fluid rounded-start object-fit-cover"
                                alt={n.title}
                                style={{ minWidth: "120px" }}
                              />
                            </div>
                            <div class="">
                              <div class="card-body p-0 pl-3">
                                <h5 class="card-title mb-0">{n.title}</h5>
                                <p className="mb-2">
                                  <small style={{ color: "#aaa" }}>
                                    {n.autor}
                                  </small>
                                </p>
                                <p
                                  class="card-text"
                                  style={{ fontSize: ".8rem" }}
                                >
                                  {n.description}
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className=" mt-3 border-top w-100  pt-3 d-flex align-items-center justify-content-between">
                            <span className="text-success">Gratis</span>
                            <a
                              href={n.link}
                              download={n.title}
                              className="btn btn-danger"
                            >
                              Descargar
                            </a>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>

            <div className="container-fluid mt-5">
              <div className="row">
                <div className="col-md-6 pl-5 border-right">
                  <h2 className="pl-md-5 mb-4">Próximas Ediciones</h2>

                  <div className="px-md-5">
                    <p className="mb-2 text-secondary">
                      Caminos del Cuerpo. Una visión de la danza escénica
                      venezolana del siglo XX Carlos Paolillo
                    </p>
                    <p className="mb-2 text-secondary">
                      Contrapunto para hoy. Miguel Astor
                    </p>
                    <p className="mb-2 text-secondary">
                      El arte de educar el habla y la voz. VVAA
                    </p>
                    <p className="mb-2 text-secondary">
                      Armonía I. Violeta Lares. Reedición ampliada
                    </p>
                    <p className="mb-2 text-secondary">
                      Armonía II. Violeta Lares. Reedición ampliada
                    </p>
                  </div>
                </div>

                <div className="col-md-6 px-5 mt-md-0 mt-5">
                  <h2 className="5 mb-4">Guías de Estudio</h2>

                  <div className="pr-md-5">
                    <p className="mb-2 text-secondary">
                      Cómo escribir para batería. Willy Díaz
                    </p>
                    <p className="mb-2 text-secondary">
                      Docentes Dramaturgos venezolanos
                    </p>
                    <p className="mb-2 text-secondary">
                      La vitalidad del Joropo Mirandino y las seis cuerdas del
                      maestro honorario Gabriel Rodríguez. Franluis Castro y
                      Katrin Lengwinat
                    </p>
                    <p className="mb-2 text-secondary">
                      Ideología, Cultura, Educación y Formación para las Artes.
                      Fredy Moncada
                    </p>
                    <p className="mb-2 text-secondary">
                      Claudia Capriles. POR DEFINIR TITULO
                    </p>
                    <p className="mb-2 text-secondary">
                      18 Óperas venezolanas. Ada María Palomino
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Publications;
