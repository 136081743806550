import React from "react";

import dip from "../../../assets/images/dip_ll.jpg";
import pdfImg from "../../../assets/images/pdf_download_icon.png";
import { triptico_edicion, tutorial_postulado } from "../../../assets/files";

const Diplomados = () => {
  return (
    <>
      <div className="p-5">
        <img className="w-100" src={dip} alt="" />

        <div id="accordion">
          <div class="pt-5">
            <div class="p-3" id="heading">
              <h6 class="mb-0">
                <button
                  class="bg-transparent border-0"
                  data-toggle="collapse"
                  data-target="#collapseDIP"
                  aria-expanded="true"
                  aria-controls="collapseDIP"
                >
                  Edición y promoción del libro y la lectura
                </button>
              </h6>
            </div>

            <div
              id="collapseDIP"
              class="collapse show"
              aria-labelledby="heading"
              data-parent="#accordion"
            >
              <div class="card-body">
                <div className="pb-3">
                  <p>
                    <strong>Pre-inscripción</strong>
                  </p>
                  <p className="pl-5">03 de mayo 2017 al 29 de mayo de 2017</p>
                </div>
                <div className="pb-3">
                  <p>
                    <strong>Inscripción</strong>
                  </p>
                  <p className="pl-5">29 de mayo al 02 de junio 2017</p>
                </div>
                <div className="pb-3">
                  <p>
                    <strong>Inicio</strong>
                  </p>
                  <p className="pl-5">06 de junio 2017</p>
                </div>
                <div className="pb-3">
                  <p>
                    <strong>Detalles:</strong>
                  </p>
                  <ul>
                    <li>
                      <p className="pl-5 pb-2">115 horas académicas</p>
                    </li>
                    <li>
                      <p className="pl-5 pb-2">
                        Del 6 de junio al 14 de noviembre de 2017
                      </p>
                    </li>
                    <li>
                      <p className="pl-5 pb-2">
                        Martes y Jueves de 3:00 – 7:00
                      </p>
                    </li>
                    <li>
                      <p className="pl-5 pb-2">
                        CECA-Armando Reverón-Caño Amarillo-Universidad Nacional
                        Experimental de las Artes
                      </p>
                    </li>
                  </ul>
                </div>

                <div>
                  <h6 className="py-3">Sinopsis</h6>
                  <p className="pb-3">
                    El sector editorial venezolano ha tenido a lo largo de los
                    años una labor destacada que lo ha llevado ser reconocido
                    por su valioso aporte a la vida nacional. Asimismo, su
                    actividad constante y la calidad de los bienes y servicios
                    producidos han contribuido con el desarrollo cultural del
                    país al servir de registro de la vida intelectual
                    venezolana. Si bien el sector editorial nacional ha sabido
                    adaptarse a todos los cambios y exigencias que han surgido a
                    través de décadas, la transformación de este sector a nivel
                    mundial, impulsada por los avances tecnológicos, ha sido tan
                    radical que se han creado muchos y nuevos procedimientos y
                    maneras de abordar la edición en todos los países del mundo.
                    La transformación de esta actividad ha implicado cambios
                    tanto en la producción como en los resultados del producto
                    mismo ya que son cada vez más corrientes soportes distintos
                    al papel. De esta manera, el cambio experimentado no se ha
                    limitado exclusivamente a una evolución de la técnica sino a
                    una nueva manera de pensar la edición y todo lo relacionado
                    con la producción de libros y circulación de bienes
                    culturales en el mercado editorial. Así, nuevas maneras y
                    nuevos productos requieren nuevos oficios y nuevos
                    profesionales, además de las nuevas formas de experiencia
                    lectora que los cambios tecnológicos han generado.
                  </p>

                  <p className="pb-3">
                    Debido a la demanda de profesionalización en este sector la
                    Universidad Experimental de las Artes ofrece un espacio para
                    desarrollar competencias y proyectos editoriales de cara al
                    fortalecimiento de la práctica lectora, toda vez que
                    Venezuela fue declarada en el año 2005 territorio libre de
                    analfabetismo, y es hoy en día un país pionero en el diseño
                    y promoción de políticas de democratización del libro y la
                    lectura. Asimismo, la Unearte apuesta a la desconcentración
                    de sus espacios de estudio por lo que cuenta con sedes en
                    distintos estados del interior del país lo que permitirá
                    ofrecer el Diplomado en Edición y Promoción del Libro y la
                    Lectura a nivel nacional.
                  </p>
                </div>

                <div>
                  <h6 className="py-3">Propósitos</h6>

                  <ul className="list-unstyled">
                    <li className="pb-3">
                      <p className="pl-5">
                        Transmitir y afianzar en los estudiantes, de manera
                        dialógica, nociones básicas del trabajo editorial, la
                        función de la edición y el editor como productor de
                        contenidos y promotor de la lectura, destrezas en la
                        producción de textos impresos y otros soportes y
                        estrategias para desarrollar un proyecto editorial desde
                        su concepción hasta la promoción estratégica de los
                        productos generados.
                      </p>
                    </li>
                  </ul>
                </div>

                <div className="">
                  <h6 className="py-3">Oferta académica</h6>

                  <table className="table table-bordered">
                    <thead class="thead-dark">
                      <tr>
                        <th scope="col" className="text-center">
                          UNIDAD CURRICULAR
                        </th>
                        <th scope="col" className="text-center">
                          N° DE HORAS
                        </th>
                      </tr>
                    </thead>

                    <tbody>
                      <tr>
                        <th scope="" className="text-center align-middle">
                          ARTE Y SOCIEDAD
                        </th>

                        <td className="text-center align-middle">
                          <p>15</p>
                        </td>
                      </tr>

                      <tr>
                        <th scope="" className="text-center align-middle">
                          DIRECCIÓN EDITORIAL
                        </th>

                        <td className="text-center align-middle">
                          <p>25</p>
                        </td>
                      </tr>

                      <tr>
                        <th scope="" className="text-center align-middle">
                          COORDINACIÓN Y PRODUCCIÓN EDITORIAL
                        </th>

                        <td className="text-center align-middle">
                          <p>30</p>
                        </td>
                      </tr>

                      <tr>
                        <th scope="" className="text-center align-middle">
                          CIRCULACIÓN Y PROMOCIÓN
                        </th>

                        <td className="text-center align-middle">
                          <p>25</p>
                        </td>
                      </tr>

                      <tr>
                        <th scope="" className="text-center align-middle">
                          PROYECTO EDITORIAL
                        </th>

                        <td className="text-center align-middle">
                          <p>20</p>
                        </td>
                      </tr>

                      <tr>
                        <th scope="" className="text-center align-middle">
                          Total
                        </th>

                        <td className="text-center align-middle">
                          <p>115</p>
                        </td>
                      </tr>
                    </tbody>
                  </table>

                  <div>
                    <h6 className="py-3">Facilitadores(as)</h6>
                    <p className="pb-5">
                      Estela Aganchul/ Juan A. Calzadilla/ Raúl Cazal/ Elizabeth
                      Coronado/ Iván Dieguez/ Carlos Duque/ Carina Falcone/
                      Giordana García/ Elis Labrador/ Yéssica La Cruz/ Ángel
                      Liendo/ Enna Olivar/ Carlos Ortiz/ Simón Sánchez/ Rongny
                      Sotillo/ Eduardo Valderrama/ Javier Véliz/ Melissa
                      Fernández.
                    </p>
                  </div>

                  <table className="table table-bordered">
                    <thead class="thead-dark">
                      <tr>
                        <th scope="col" className="text-center">
                          DESCARGA
                        </th>
                        <th scope="col" className="text-center">
                          DESCRIPCIÓN
                        </th>
                      </tr>
                    </thead>

                    <tbody>
                      <tr>
                        <th scope="" className="text-center align-middle">
                          <a
                            href={triptico_edicion}
                            download={"Triptico_educacion"}
                          >
                            <img src={pdfImg} alt="" />
                          </a>
                        </th>

                        <td className="align-middle">
                          <p>
                            Tríptico Edición y promoción del Libro y la Lectura
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <th scope="" className="text-center align-middle">
                          <a href={tutorial_postulado}>
                            <img src={pdfImg} alt="" />
                          </a>
                        </th>

                        <td className="align-middle">
                          <p>
                            Instructivo para el registro, postulación e
                            inscripción
                          </p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Diplomados;
