import React from "react";

const Diplomados = () => {
  return (
    <>
      <div>
        <div className="py-3 border-bottom">
          <p>
            Los diplomados tienen el propósito de brindar información en los
            distintos temas vinculado las Artes y las Culturas, con el propósito
            de contribuir con brindar información sobre área vinculadas con las
            artes y las culturas.
          </p>
        </div>

        <div className="py-3 border-bottom">
          <h6 className="text-info py-3">Economía Cultural</h6>

          <p className="py-3">
            Dirigido a: productores, gestores culturales, creadores,
            especialistas, emprendedores, estudiantes, licenciados y
            profesionales egresados en carreras referentes a la economía
            cultural; con desempeño en el ámbito público o privado,
            desarrollando actividades características o conexas a los procesos
            de creación, producción y circulación de bienes y servicios
            culturales de las industrias creativas del país
          </p>

          <p className="py-3">
            Centro de Estudio y Creación Artística: Plaza Morelos
          </p>

          <p className="py-3">Coordinador: Gaston Fortis</p>

          <div id="accordion">
            <div class="py-3">
              <div class="" id="headingEC">
                <h5 class="mb-0">
                  <button
                    class="bg-transparent border-0 h6"
                    data-toggle="collapse"
                    data-target="#collapseEC"
                    aria-expanded="true"
                    aria-controls="collapseEC"
                  >
                    DETALLES
                  </button>
                </h5>
              </div>

              <div
                id="collapseEC"
                class="collapse show"
                aria-labelledby="headingEC"
                data-parent="#accordion"
              >
                <div class="card-body"></div>
              </div>
            </div>
          </div>
        </div>

        <div className="py-3 border-bottom">
          <h6 className="text-info py-3">Edición y Promoción de Lectura</h6>

          <p className="py-3">
            Dirigido a: productores, gestores culturales, creadores,
            especialistas, emprendedores, estudiantes, licenciados y
            profesionales egresados en carreras referentes a la economía
            cultural; con desempeño en el ámbito público o privado,
            desarrollando actividades características o conexas a los procesos
            de creación, producción y circulación de bienes y servicios
            culturales de las industrias creativas del país
          </p>

          <p className="py-3">
            Centro de Estudio y Creación Artística: Plaza Morelos
          </p>

          <p className="py-3">Coordinadora: Ximena Benítez</p>

          <div id="accordion">
            <div class="py-3">
              <div class="" id="headingEPL">
                <h5 class="mb-0">
                  <button
                    class="bg-transparent border-0 h6"
                    data-toggle="collapse"
                    data-target="#collapseEPL"
                    aria-expanded="true"
                    aria-controls="collapseEPL"
                  >
                    DETALLES
                  </button>
                </h5>
              </div>

              <div
                id="collapseEPL"
                class="collapse show"
                aria-labelledby="headingEPL"
                data-parent="#accordion"
              >
                <div class="card-body"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Diplomados;
