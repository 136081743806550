import React, { useEffect } from "react";
import opsu from "../../assets/images/opsu.png";
import ret from "../../assets/images/return.png";
import experience from "../../assets/images/experience.png";
import deal from "../../assets/images/deal.png";
import { Link } from "react-router-dom";

const Ingreso = () => {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  return (
    <>
      <div
        className="container mt-3 p-4 d-flex flex-column"
        style={{ backgroundColor: "#55803e" }}
      >
        <h2 className="w-100 text-white m-0">INGRESO</h2>
        <span
          style={{ width: "100%", height: "3px", backgroundColor: "#fff" }}
        ></span>
      </div>
      <div className="">
        <article className="container">
          <p className="text-center p-5">
            De acuerdo con Artículo 6 del{" "}
            <strong>
              Reglamento de Ingreso, Permanencia, y Egreso para los y las
              Estudiantes de los Programas Nacionales de Formación
            </strong>{" "}
            el ingreso como estudiante de la Universidad Nacional Experimental
            de las Artes requiere ser Bachiller y consignar todos los documentos
            solicitados por la Secretaría General de la Universidad según lo
            estipulado en los marcos legales vigentes.
          </p>
        </article>

        <div className="py-5" style={{ backgroundColor: "#f3f3f3" }}>
          <div className="container">
            <h2 className="text-info text-center border-bottom pb-3">
              Modalidades de Ingreso
            </h2>
            <div className="row">
              <div className="col-md-8 p-5">
                <a href="http://ingreso.opsu.gob.ve/" target="_blank">
                  <h5>
                    Asignación a través del Sistema Nacional de Ingreso (SNI) de
                    la Oficina de Planificación del Sector Universitario (OPSU)
                  </h5>
                </a>
                <p>
                  Esta será en la disciplina y en el Centro de Estudios y
                  Creación Artística asignado. En relación a las menciones
                  dentro de cada Programa de Estudio, se establecerá la
                  ubicación según planificación académica de cada Centro de
                  Estudios y aptitudes para las disciplinas.
                </p>
              </div>
              <picture className="col-md-3 d-flex align-items-center justify-content-center">
                <a href="http://ingreso.opsu.gob.ve/">
                  <img src={opsu} alt="OPSU" />
                </a>
              </picture>
            </div>

            <div className="row">
              <picture className="col-md-3 d-flex align-items-center justify-content-center">
                <img src={ret} alt="Ingresos por Reincorporación" />
              </picture>
              <div className="col-md-8 p-5">
                <h5 className="text-right">Ingresos por Reincorporación</h5>

                <p className="text-right">
                  Aplicada a cualquier bachiller que tenga registro de ingreso
                  dentro de algún Programa Nacional de Formación (PNF) de la
                  universidad, que por alguna razón haya dejado la universidad
                  con estatus de inactivo. Este ingreso está sujeto a la
                  aprobación del Consejo de Centro de Estudios y Creación
                  Artística, de acuerdo a los lapsos establecidos por la
                  Secretaría General para su evaluación. Finalmente el ingreso
                  por reincorporación se realizará dentro del mismo plan de
                  estudio y mención.
                </p>
              </div>
            </div>

            <div className="row">
              <div className="col-md-8 p-5">
                <h5 className="">Ingresos por Acreditación por Experiencia</h5>

                <p className="">
                  Aplicada a cualquier bachiller que a través de procesos
                  internos de validación de experiencia de especialistas en las
                  distintas unidades curriculares del PNF evidencien
                  competencias en el área. Este ingreso está sujeto a los lapsos
                  establecidos por la Secretaría General, parámetros académicos
                  y aprobación del Consejo de Centro de Estudios y Creación
                  Artística (CECA).
                </p>
              </div>
              <picture className="col-md-3 d-flex align-items-center justify-content-center">
                <img
                  src={experience}
                  alt="Ingresos por Acreditación por Experiencia"
                />
              </picture>
            </div>

            <div className="row">
              <picture className="col-md-3 d-flex align-items-center justify-content-center">
                <img src={deal} alt="Ingresos por Situaciones Especiales" />
              </picture>
              <div className="col-md-8 p-5">
                <h5 className="text-right">
                  Ingresos por Situaciones Especiales
                </h5>

                <p className="text-right">
                  Aplica de acuerdo a lo establecido en la Convención Colectiva
                  de los trabajadores universitarios y convenios debidamente
                  subscritos por la Universidad Nacional Experimental de las
                  Artes (UNEARTE) y postulaciones de bachilleres provenientes
                  del Sistema de Formación Venezolano de las Artes y las
                  Culturas (SIFAC).
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="container p-5">
          <h2 className="text-info text-center border-bottom pb-4">
            Requisitos de Ingreso
          </h2>

          <p className="pb-3 pt-4 h5 text-center">
            Las Pruebas Internas de Admisión existentes en las universidades
            públicas quedan eliminadas, según lo establecido por el CNU en
            acuerdo publicado en Gaceta Oficial N° 38.896 de la República
            Bolivariana de Venezuela, de fecha 26 de marzo de 2008.
          </p>
          <p className="py-3 text-center h5">
            Pautas para el Sistema Nacional de Ingreso a la Educación Superior
            2009. Gaceta Oficial de la República Bolivariana de Venezuela Número
            39.190 de fecha 1 de junio de 2009:
          </p>
          <p className="py-3 text-center h5">
            "-Artículo 5: para formalizar su inscripción en una Institución
            Superior oficial o privada, los o las bachilleres presentarán como
            recaudos:
          </p>
          <ul className="px-5 py-3 h5">
            <li className="py-2 px-5">
              <p>● Título de bachiller</p>
            </li>
            <li className="py-2 px-5">
              <p>
                ● El Certificado de Participación, emitido por la Oficina de
                Planificación del Sector Universitario
              </p>
            </li>
            <li className="py-2 px-5">
              <p>
                ● Cualquier otro recaudo exigido por las Instituciones de
                Educación Superior, de acuerdo con su Reglamento de Inscripción,
                sin desmedro de lo contemplado en el Artículo 103 de la
                Constitución de la República Bolivariana de Venezuela."
              </p>
            </li>
          </ul>

          <p className="py-3 text-center h5">
            "-Artículo 7: el o la asignado(a) en una Institución de Educación
            Superior (IES) por el Programa Nacional de Ingreso llevado a cabo
            por la Oficina de Planificación del Sector Universitario, tiene
            derecho a inscribirse con la sola presentación de los recaudos
            señalados en el Artículo 5 de estas pautas, sin participar en
            procesos de selección internos propios de las IES."
          </p>

          <p className="py-3 text-center h5">
            Una vez aprobado el trayecto inicial se iniciará su prosecución
            académica en la mención del{" "}
            <Link to="/pnf" className="">
              <u className="text-info">Programa Nacional de Formación</u>
            </Link>{" "}
            seleccionado, de acuerdo a la planificación académica.
          </p>
        </div>
      </div>
    </>
  );
};

export default Ingreso;
