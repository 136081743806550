import React, { useEffect } from "react";
import estudiosAvanzados from "../../assets/images/estudios_avanzados.jpg";

import PostGrados from "./PostGrados/PostGrados";
import CursoDePerfeccionamiento from "./CursosDePerfeccionamiento/CursoDePerfeccionamiento";
import Diplomados from "./Diplomados/Diplomados";

const EstudiosAvanzados = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div
        className="container mt-3 p-4 d-flex flex-column"
        style={{ backgroundColor: "#f5b80e" }}
      >
        <h2 className="w-100 text-white m-0">ESTUDIOS AVANZADOS</h2>
        <span
          style={{ width: "100%", height: "3px", backgroundColor: "#fff" }}
        ></span>
      </div>
      <div className="container">
        <div className="py-5">
          <img className="w-100 pb-5" src={estudiosAvanzados} alt="" />
          <p className="pb-3">
            Los estudios avanzados, o de cuarto nivel, están orientados a
            profundizar la formación actual en el campo de las artes, los
            estudios sobre y desde las culturas, la experiencia sensible y
            afectiva, la función poética, política, educativa, erótica y
            económica de la cultura y la producción simbólica, la dimensión
            operativa de la creación sensible --su lugar social y comunitario--
            y los bienes comunes de la producción artística, haciendo énfasis en
            las más diversas prácticas de creación populares, urbanas,
            periurbanas y rurales, insurgentes y marginales.
          </p>

          <p className="pb-3">
            Los estudios avanzados son estudios continuos, plenos y acuciosos,
            enfocados en los movimientos de la sensibilidad y en los procesos de
            producción de enunciados culturales, como elementos indispensables
            para alcanzar soberanía de vida, y para fortalecer las luchas por la
            emancipación y transformación revolucionaria de los pueblos.
          </p>
        </div>

        <div>
          <nav>
            <div class="nav nav-tabs" id="nav-tab" role="tablist">
              <a
                class="nav-item nav-link active w-25 text-center"
                id="nav-home-tab"
                data-toggle="tab"
                href="#nav-home"
                role="tab"
                aria-controls="nav-home"
                aria-selected="true"
              >
                POST-GRADOS
              </a>
              <a
                class="nav-item nav-link w-50 text-center"
                id="nav-profile-tab"
                data-toggle="tab"
                href="#nav-profile"
                role="tab"
                aria-controls="nav-profile"
                aria-selected="false"
              >
                CURSOS DE PERFECCIONAMIENTO
              </a>
              <a
                class="nav-item nav-link w-25 text-center"
                id="nav-contact-tab"
                data-toggle="tab"
                href="#nav-contact"
                role="tab"
                aria-controls="nav-contact"
                aria-selected="false"
              >
                DIPLOMADOS
              </a>
            </div>
          </nav>
          <div class="tab-content" id="nav-tabContent">
            <div
              class="tab-pane fade show active p-5"
              id="nav-home"
              role="tabpanel"
              aria-labelledby="nav-home-tab"
            >
              <PostGrados />
            </div>
            <div
              class="tab-pane fade p-5"
              id="nav-profile"
              role="tabpanel"
              aria-labelledby="nav-profile-tab"
            >
              <CursoDePerfeccionamiento />
            </div>
            <div
              class="tab-pane fade"
              id="nav-contact"
              role="tabpanel"
              aria-labelledby="nav-contact-tab"
            >
              <Diplomados />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EstudiosAvanzados;
