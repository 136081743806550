import React, { useEffect } from "react";
import { a } from "react-router-dom";
import { asteriskSVG } from "../../assets/svg";
import Estructura from "./Estructura/Estructura";

// Files
import {
  artes_audiovisuales_cinematografia,
  artes_audiovisuales_direccion_de_arte,
  artes_audiovisuales_direccion_de_audiovisuales,
  artes_audiovisuales_diseño_de_sonido,
  artes_audiovisuales_guion_audiovisual,
  artes_audiovisuales_montaje_y_postproduccion,
  artes_audiovisuales_produccion_de_audiovisuales,
  artes_plasticas_artes_del_diseño,
  artes_plasticas_artes_graficas,
  artes_plasticas_ceramica,
  artes_plasticas_escultura,
  artes_plasticas_fotografia,
  artes_plasticas_medios_mixtos,
  artes_plasticas_museologia,
  artes_plasticas_pintura,
  danzas_interprete_de_danza_clasica,
  danzas_interprete_de_danza_contemporanea,
  danzas_danzas_tradicionales_venezolanas,
  danzas_gestion_y_produccion_cultural_en_danza,
  educacion_para_las_artes_artes_plasticas,
  educacion_para_las_artes_danza_clasica,
  educacion_para_las_artes_danza_contemporanea,
  educacion_para_las_artes_danzas_tradicionales_venezolanas,
  educacion_para_las_artes_musica,
  educacion_para_las_artes_teatro,
  musica_canto_jazz,
  musica_canto_lirico,
  musica_canto_popular,
  musica_composicion,
  musica_direccion_coral,
  musica_direccion_orquestal,
  musica_ejecucion_instrumental_jazz,
  musica_ejecucion_instrumental_popular,
  musica_ejecucion_instrumental_solista_sinfonico,
  musica_musicologia,
  musica_produccion_musical,
  musica_tradicion_y_contexto,
  teatro_actuacion,
  teatro_direccion_teatral,
  teatro_diseño_teatral,
  teatro_dramaturgia,
  teatro_gerencia_y_produccion_teatral,
} from "../../assets/files";

const Formacion = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div
        className="container mt-3 p-4 d-flex flex-column"
        style={{ backgroundColor: "#0092a2" }}
      >
        <h2 className="w-100 text-white m-0">PROGRAMA NACIONAL DE FORMACIÓN</h2>
        <span
          style={{ width: "100%", height: "3px", backgroundColor: "#fff" }}
        ></span>
      </div>
      <div className="container">
        <p className="p-5">
          Creados en{" "}
          <strong>
            Gaceta Oficial Nº 38.930 del 14 de Mayo del 2008/Resolución Nº 2.963
          </strong>
          , es el proceso académico que tiene como propósito la realización de
          experiencias formativas de naturaleza éticas, culturales,
          humanísticas, artísticas, sociocomunitarias, territoriales,
          socioproductivas, políticas, de innovación, científicas y tecnológicas
          conducente a Títulos, Grados, Certificaciones de Estudios, en áreas
          específicas del saber creadas por iniciativa del Ejecutivo Nacional, a
          través del{" "}
          <strong>
            Ministerio del Poder Popular para la Educación Universitaria,
            Ciencia y Tecnología (MPPEUCT)
          </strong>{" "}
          diseñados con la cooperación de instituciones de educación
          universitaria, atendiendo a los lineamientos del{" "}
          <strong>Plan de Desarrollo Económico y Social de la Nación</strong>,
          para ser gestionados en distintos espacios educativos del territorio
          nacional y así propiciar la toma de conciencia de las y los
          estudiantes para el desarrollo y la transformación de conocimientos,
          saberes, saberes hacedores, modos para ser y convivir, a fin de
          favorecer y afianzar proyectos y procesos que impliquen y transformen
          sustantivamente a la sociedad.
        </p>

        <div className="border py-4 mb-5 bg-white">
          <nav className="px-5 ">
            <div class="nav nav-tabs" id="nav-tab" role="tablist">
              <a
                class="nav-item nav-link active"
                id="nav-home-tab"
                data-toggle="tab"
                href="#nav-home"
                role="tab"
                aria-controls="nav-home"
                aria-selected="true"
              >
                BASES
              </a>
              <a
                class="nav-item nav-link"
                id="nav-curriculo-tab"
                data-toggle="tab"
                href="#nav-curriculo"
                role="tab"
                aria-controls="nav-curriculo"
                aria-selected="false"
              >
                CURRÍCULO
              </a>
              <a
                class="nav-item nav-link"
                id="nav-estructura-tab"
                data-toggle="tab"
                href="#nav-estructura"
                role="tab"
                aria-controls="nav-estructura"
                aria-selected="false"
              >
                ESTRUCTURA
              </a>
              <a
                class="nav-item nav-link"
                id="nav-objetivos-tab"
                data-toggle="tab"
                href="#nav-objetivos"
                role="tab"
                aria-controls="nav-objetivos"
                aria-selected="false"
              >
                OBJETIVOS
              </a>
              <a
                class="nav-item nav-link"
                id="nav-principios-tab"
                data-toggle="tab"
                href="#nav-principios"
                role="tab"
                aria-controls="nav-principios"
                aria-selected="false"
              >
                PRINCIPIOS
              </a>
            </div>
          </nav>

          <div class="tab-content" id="nav-tabContent">
            <div
              class="tab-pane fade show active p-5"
              id="nav-home"
              role="tabpanel"
              aria-labelledby="nav-home-tab"
            >
              <p className="mb-4">
                {asteriskSVG} La universalización y la municipalización exigen
                que la Educación Superior se constituya como un espacio abierto
                de formación permanente, articulado al mundo del trabajo y la
                vida social, que brinde posibilidades de acceso, permanencia y
                salida del sistema y se adapte a las necesidades diversas de las
                personas y comunidades, con una amplia gama de oportunidades de
                formación.
              </p>

              <p>
                {asteriskSVG} La cooperación solidaria entre las instituciones,
                la armonización de los diseños curriculares, la realización de
                planes conjuntos de investigación y formación, la producción y
                uso compartidos de distintos recursos educativos y la movilidad
                académica son condiciones indispensables para la calidad de la
                educación superior
              </p>
            </div>

            <div
              class="tab-pane fade p-5"
              id="nav-curriculo"
              role="tabpanel"
              aria-labelledby="nav-curriculo-tab"
            >
              <p className="mb-4">
                El currículo se centra en los valores y en este campo ofrece una
                gama de unidades curriculares que buscan en cada campo artístico
                la formación no sólo para el arte sino para la vida, el quehacer
                social, el desempeño en el mundo real, sin desmedro de las
                especificaciones curriculares “medulares” para las Artes, con
                temáticas novedosas que dan paso a la nueva era de la enseñanza
                de las artes como asunto social y no de laboratorio. Los PNF en
                la Unearte, son programas de grado que profundizan en los
                problemas fundamentales de las artes en las comunidades y sus
                culturas, no solamente de nuestro país, sino también en la
                realidad internacional. No se trata de estudios para los
                personalismos profesionales, sino de formación para la vida y la
                cultura, para la comprensión, aplicación, interpretación y
                creación de la sociedad participativa que se quiere.
              </p>

              <p className="pb-5">
                En su flexibilidad curricular, los programas nacionales de
                formación en la Unearte propone unidades curriculares que son
                comunes para todos los programas de grado: transdiscilinarios,
                interdisciplinario y multidisciplinario. De igual manera las
                asignaturas disciplinarias de un programa pueden ser
                transdisciplinarias de otro.
              </p>

              <div className="py-4 border-top border-bottom">
                <h4 className="text-info text-center p-3">
                  Componentes de las Unidades Curriculares
                </h4>

                <article className="py-3">
                  <h5>Interdisciplinario</h5>
                  <p>
                    Lo integran un conjunto de unidades curriculares básicas,
                    dirigidas a lograr que el estudiante desarrolle la capacidad
                    de autorrealización, autoaprendizaje, comunicación efectiva,
                    así como fomentar el pensamiento crítico en cuanto al
                    ejercicio de la ciudadanía y su responsabilidad social.
                    Contribuyen al desarrollo de actitudes que le permitan
                    adaptarse al nivel universitario.
                  </p>
                </article>

                <article className="py-3">
                  <h5>Multidisciplinario</h5>
                  <p>
                    Conjunto de unidades curriculares dirigidas a dotar al
                    estudiante de los conocimientos teóricos, instrumentales y
                    culturales que cimienten las bases para la producción
                    artística, la creación intelectual y la vinculación social.
                  </p>
                </article>

                <article className="py-3">
                  <h5>Disciplinario</h5>
                  <p>
                    Son las unidades curriculares que agrupan conocimientos,
                    habilidades y valores técnico-artísticos de formación
                    laboral específicos en cada una de las áreas. Permiten al
                    egresado el ejercicio de su profesión, así como el
                    desenvolvimiento en los ámbitos artísticos, social y
                    cultural. Es el único componente sujeto al sistema de
                    prelación.
                  </p>
                </article>

                <article className="py-3">
                  <h5>Transdisciplinario</h5>
                  <p>
                    Conjunto de unidades curriculares que integran componentes
                    cognitivos y prácticos relacionados con las especificidades
                    artísticas. Son de libre elección y tienen el propósito de
                    ampliar el perfil profesional del egresado. Incluye las
                    unidades curriculares disciplinarias de otras áreas
                    distintas a la que curse el estudiante.
                  </p>
                </article>
              </div>

              <div>
                <h4 className="text-info text-center pt-5">
                  Programas Nacionales de Formación con Salidas en Técnico
                  Superior Universitario y Licenciatura Administrados por la
                  Unearte
                </h4>

                <div id="accordion">
                  <div class="">
                    <div class="p-3" id="headingOne">
                      <h6 class="mb-0">
                        <button
                          class="bg-transparent border-0"
                          data-toggle="collapse"
                          data-target="#collapseOne"
                          aria-expanded="true"
                          aria-controls="collapseOne"
                        >
                          Artes Audiovisuales
                        </button>
                      </h6>
                    </div>

                    <div
                      id="collapseOne"
                      class="collapse show"
                      aria-labelledby="headingOne"
                      data-parent="#accordion"
                    >
                      <div class="card-body">
                        <p>
                          Los profesionales del área audiovisual, al igual que
                          los de otras áreas, están inmersos en una sociedad con
                          importante presencia de valores extranjeros, muchas
                          veces contrarios a nuestra cultura y tradiciones. En
                          esta realidad, el programa nacional de formación en
                          Artes Audiovisuales formará profesionales que estarán
                          en capacidad de planificar y organizar producciones
                          audiovisuales, generar colectivos de creación
                          independientes, así como integrarse a los diversos
                          espacios sociales, instituciones publicas y privadas,
                          entes y organismos vinculados a la creación,
                          investigación, documentación y producción audiovisual.
                        </p>

                        <ul className="py-4 list-unstyled">
                          <p>Menciones y sus competencias profesionales:</p>

                          <li className="py-2">
                            <p className="py-2">
                              <strong>Cinematografía</strong>: Interpreta la
                              propuesta estética en una propuesta técnica de
                              alta calidad aplicando conocimientos e innovando
                              soluciones y posee conocimientos técnicos
                              esenciales en las áreas de electricidad,
                              fotografía, iluminación, colores y cámara.
                            </p>
                            <a
                              className="text-info"
                              href={artes_audiovisuales_cinematografia}
                              download={"Artes Audiovisuales - Cinematografía"}
                            >
                              Descargar Guía de Navegación
                            </a>
                          </li>

                          <li className="py-2">
                            <p className="py-2">
                              <strong>Dirección de Arte</strong>: Domina las
                              diferentes áreas del diseño: vestuario,
                              caracterización, escenografía, utilería e
                              iluminación en lo técnico y conceptual
                              integrándolas en una visión estética , artística e
                              investiga referentes para desarrollar y argumentar
                              el concepto artístico del producto audiovisual.
                            </p>
                            <a
                              className="text-info"
                              href={artes_audiovisuales_direccion_de_arte}
                              download={
                                "Artes Audiovisuales - Dirección de Arte"
                              }
                            >
                              Descargar Guía de Navegación
                            </a>
                          </li>

                          <li className="py-2">
                            <p className="py-2">
                              <strong>Dirección de Audiovisuales</strong>:
                              Traduce en imagen y sonidos un guión combinando su
                              propio lenguaje discursivo y el compromiso
                              artístico y cultural con nuestra sociedad.
                            </p>
                            <a
                              className="text-info"
                              href={
                                artes_audiovisuales_direccion_de_audiovisuales
                              }
                              download={
                                "Artes Audiovisuales - Dirección de Audiovisuales"
                              }
                            >
                              Descargar Guía de Navegación
                            </a>
                          </li>

                          <li className="py-2">
                            <p className="py-2">
                              <strong>Diseño de Sonido</strong>: Conoce de
                              manera teórica y práctica las técnicas de registro
                              sonoro así como los procesos de mezcla de sonido
                              en una obra audiovisual y domina las herramientas
                              tecnológicas y el lenguaje técnico asociado a la
                              cadena de producción sonora.
                            </p>
                            <a
                              className="text-info"
                              href={artes_audiovisuales_diseño_de_sonido}
                              download={
                                "Artes Audiovisuales - Diseño de Sonido"
                              }
                            >
                              Descargar Guía de Navegación
                            </a>
                          </li>

                          <li className="py-2">
                            <p className="py-2">
                              <strong>Guión Audiovisual</strong>: Crea guiones
                              audiovisuales, ya sea una historia original, una
                              adaptación de un guión precedente o de otra obra
                              literaria y desarrolla aptitudes para la escritura
                              creativa y la estructuración dramática.
                            </p>
                            <a
                              className="text-info"
                              href={artes_audiovisuales_guion_audiovisual}
                              download={
                                "Artes Audiovisuales - Guión Audiovisual"
                              }
                            >
                              Descargar Guía de Navegación
                            </a>
                          </li>

                          <li className="py-2">
                            <p className="py-2">
                              <strong>Montaje y Postproducción</strong>: Posee
                              conocimientos teóricos-prácticos sobre el montaje,
                              la dramaturgia y la postproducción de
                              audiovisuales y diseña junto con el director, con
                              el director de fotografía, y el sonidista, el
                              acabado técnico, comunicacional, estético y
                              expresivo de la obra audiovisual.
                            </p>
                            <a
                              className="text-info"
                              href={
                                artes_audiovisuales_montaje_y_postproduccion
                              }
                              download={
                                "Artes Audiovisuales - Montaje y Postproducción"
                              }
                            >
                              Descargar Guía de Navegación
                            </a>
                          </li>

                          <li className="py-2">
                            <p className="py-2">
                              <strong>Producción de Audiovisuales</strong>:
                              Planifica y organiza el desarrollo de proyectos
                              audiovisuales atendiendo el plan estético y
                              artístico del proyecto.
                            </p>
                            <a
                              className="text-info"
                              href={
                                artes_audiovisuales_produccion_de_audiovisuales
                              }
                              download={
                                "Artes Audiovisuales - Producción de Audiovisuales"
                              }
                            >
                              Descargar Guía de Navegación
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>

                  <div class="">
                    <div class="p-3" id="headingTwo">
                      <h6 class="mb-0">
                        <button
                          class="bg-transparent border-0"
                          data-toggle="collapse"
                          data-target="#collapseTwo"
                          aria-expanded="true"
                          aria-controls="collapseTwo"
                        >
                          Artes Plásticas
                        </button>
                      </h6>
                    </div>

                    <div
                      id="collapseTwo"
                      class="collapse show"
                      aria-labelledby="headingTwo"
                      data-parent="#accordion"
                    >
                      <div class="card-body">
                        <p>
                          El Programa Nacional de Formación en Artes Plásticas,
                          es una plataforma programática de contenidos inter y
                          transdisciplinarios abordados en un proceso de
                          enseñanza y aprendizaje relacionados con todos los
                          contextos artísticos de la plástica del país, enfocado
                          en la construcción de un nuevo ser, centrado en
                          generar nuevos conocimientos de vanguardia, de
                          identificación cultural y transformación social a
                          través del conocimiento, del hacer y de la creación,
                          orientado hacia el logro de competencias de diálogo
                          intercultural e integración a la diversidad de
                          saberes, en el devenir substancial de una conciencia
                          transformadora, plena hacia la crítica y reflexión de
                          las bases establecidas de los principios curriculares
                          de las Artes Plásticas en la memoria del colectivo que
                          brinde una identidad de país en procesos definitorios
                          en espacios de aprendizaje.
                        </p>

                        <ul className="py-4 list-unstyled">
                          <p>Menciones y sus competencias profesionales:</p>

                          <li className="py-2">
                            <p className="py-2">
                              <strong>Artes del Diseño</strong>: Diseña objetos
                              de interés y utilidad social, donde se valora y
                              aprecia la innovación, el sentido estético, la
                              técnica, la complejidad, la preservación y el uso
                              racional de los materiales. Así como también
                              elabora bocetos estructurados en las normas del
                              dibujo básico y analítico.
                            </p>
                            <a
                              className="text-info"
                              href={artes_plasticas_artes_del_diseño}
                              download={"Artes Plásticas - Artes del Diseño"}
                            >
                              Descargar Guía de Navegación
                            </a>
                          </li>

                          <li className="py-2">
                            <p className="py-2">
                              <strong>Artes Gráficas</strong>: Crea elementos
                              visuales a partir del dibujo y el grabado y
                              desarrolla la expresión artística mediante la
                              transferencia de una imagen; para transmitir
                              conocimientos, emociones y pensamientos, con un
                              sentido de alta valoración estética.
                            </p>
                            <a
                              className="text-info"
                              href={artes_plasticas_artes_graficas}
                              download={"Artes Plásticas - Artes Gráficas"}
                            >
                              Descargar Guía de Navegación
                            </a>
                          </li>

                          <li className="py-2">
                            <p className="py-2">
                              <strong>Cerámica</strong>: Conceptualiza elementos
                              visuales a partir de la transformación de la
                              materia, comprendiendo la relación entre el
                              volumen y el espacio (piedra, madera, metales u
                              otros materiales), aplicando las técnicas del
                              tallado, el modelado, el ensamblaje, la
                              instalación, entre otros y ejerce como escultores
                              realizando obras, que por sus temáticas,
                              creatividad y valor estético, constituyen obras
                              representativas de nuestro arte autóctono.
                            </p>
                            <a
                              className="text-info"
                              href={artes_plasticas_ceramica}
                              download={"Artes Plásticas - Cerámica"}
                            >
                              Descargar Guía de Navegación
                            </a>
                          </li>

                          <li className="py-2">
                            <p className="py-2">
                              <strong>Escultura</strong>: Diseña proyectos de
                              creación fotográfica utilizando diferentes
                              métodos, tecnologías y promueve la integración de
                              las artes y el trabajo colectivo como posibilidad
                              para generar propuestas artísticas.
                            </p>
                            <a
                              className="text-info"
                              href={artes_plasticas_escultura}
                              download={"Artes Plásticas - Escultura"}
                            >
                              Descargar Guía de Navegación
                            </a>
                          </li>

                          <li className="py-2">
                            <p className="py-2">
                              <strong>Fotografía</strong>: Diseña proyectos de
                              creación fotográfica utilizando diferentes
                              métodos, tecnologías y promueve la integración de
                              las artes y el trabajo colectivo como posibilidad
                              para generar propuestas artísticas.
                            </p>
                            <a
                              className="text-info"
                              href={artes_plasticas_fotografia}
                              download={"Artes Plásticas - Fotografía"}
                            >
                              Descargar Guía de Navegación
                            </a>
                          </li>

                          <li className="py-2">
                            <p className="py-2">
                              <strong>Medios Mixtos</strong>: Genera elementos
                              visuales a partir de la aplicación de las
                              tecnologías multimedia, integrando diversas
                              disciplinas artísticas en la construcción de
                              medios alternativos, tales como el vídeo, la
                              fotografía, el performance, entre otros, como
                              parte de los nuevos lenguajes contemporáneos y
                              posee conocimientos, habilidades y destrezas que
                              le permiten realizar tareas y funciones de
                              investigación, creación, producción vinculadas con
                              el área de las artes plásticas.
                            </p>
                            <a
                              className="text-info"
                              href={artes_plasticas_medios_mixtos}
                              download={"Artes Plásticas - Medios Mixtos"}
                            >
                              Descargar Guía de Navegación
                            </a>
                          </li>

                          <li className="py-2">
                            <p className="py-2">
                              <strong>Museología</strong>: Diseña diferentes
                              proyectos y procesos que se ejecutan en los museos
                              en las áreas de: investigación, educación y
                              exhibición del patrimonio custodiado por la
                              institución y evalúa proyectos y procesos que se
                              ejecutan en los museos en las áreas de:
                              investigación, educación, registro, conservación y
                              exhibición del patrimonio custodiado por la
                              institución.
                            </p>
                            <a
                              className="text-info"
                              href={artes_plasticas_museologia}
                              download={"Artes Plásticas - Museología"}
                            >
                              Descargar Guía de Navegación
                            </a>
                          </li>

                          <li className="py-2">
                            <p className="py-2">
                              <strong>Pintura</strong>: Crea elementos visuales
                              a partir de la aplicación de las técnicas propias
                              de la pintura, dominando los elementos de
                              composición plástica y desarrolla como medio de
                              expresión el hecho pictórico para transmitir
                              conocimientos, emociones y pensamientos, con un
                              sentido de alta valoración estética.
                            </p>
                            <a
                              className="text-info"
                              href={artes_plasticas_pintura}
                              download={"Artes Plásticas - Pintura"}
                            >
                              Descargar Guía de Navegación
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>

                  <div class="">
                    <div class="p-3" id="headingThree">
                      <h6 class="mb-0">
                        <button
                          class="bg-transparent border-0"
                          data-toggle="collapse"
                          data-target="#collapseThree"
                          aria-expanded="true"
                          aria-controls="collapseThree"
                        >
                          Danzas
                        </button>
                      </h6>
                    </div>

                    <div
                      id="collapseThree"
                      class="collapse show"
                      aria-labelledby="headingTwo"
                      data-parent="#accordion"
                    >
                      <div class="card-body">
                        <p>
                          El Programa Nacional de Formación en Danza permite
                          abrir puertas y brindar oportunidades de estudios en
                          el área de la danza tradicional y folclóricas de
                          nuestra nación a un mayor número de estudiantes a
                          nivel nacional con énfasis en las poblaciones
                          históricamente excluidas. Asimismo se brindará
                          oportunidades de estudios al área de la danza clásica
                          y la danza contemporánea. También este programa
                          promueve distintos saberes facilitando la relación
                          intercultural con otros pueblos y promoviendo una
                          pluriculturalidad en el quehacer reflexivo en lo
                          académico.
                        </p>

                        <ul className="py-4 list-unstyled">
                          <p>Menciones y sus competencias profesionales:</p>

                          <li className="py-2">
                            <p className="py-2">
                              <strong>Intérprete de Danza Clásica</strong>:
                              Adquiere conocimientos y habilidades técnicas en
                              cuanto a la ejecución de los movimientos
                              corporales para la realización de la danza
                              clásica. Es capaz de ser una bailarina o bailarín
                              en compañías y en agrupaciones de ballet clásico y
                              neoclásico, preparado para introducirse en el
                              campo de la creación coreográfica.
                            </p>
                            <a
                              className="text-info"
                              href={danzas_interprete_de_danza_clasica}
                              download={"Danzas - Intérprete de Danza Clásica"}
                            >
                              Descargar Guía de Navegación
                            </a>
                          </li>

                          <li className="py-2">
                            <p className="py-2">
                              <strong>Intérprete de Danza Contemporánea</strong>
                              : Posee conocimientos y habilidades técnicas en
                              cuanto a la ejecución de los movimientos
                              corporales para la realización de la danza
                              contemporánea y ostenta habilidades para la
                              interpretación adecuada de obras coreográficas con
                              destrezas técnicas y manejo expresivo en su área
                              de desempeño.
                            </p>
                            <a
                              className="text-info"
                              href={danzas_interprete_de_danza_contemporanea}
                              download={
                                "Danzas - Intérprete de Danza Contemporánea"
                              }
                            >
                              Descargar Guía de Navegación
                            </a>
                          </li>

                          <li className="py-2">
                            <p className="py-2">
                              <strong>Danzas Tradicionales Venezolanas</strong>:
                              Conoce los diferentes saberes tradicionales
                              recreados en la cultura popular de la sociedad
                              venezolana; a nivel comunal, local, regional y
                              nacional e intercambia herramientas teórico –
                              prácticas sobre las diversas danzas y bailes
                              tradicionales y populares de Venezuela.
                            </p>
                            <a
                              className="text-info"
                              href={danzas_danzas_tradicionales_venezolanas}
                              download={
                                "Danzas - Danzas Tradicionales Venezolanas"
                              }
                            >
                              Descargar Guía de Navegación
                            </a>
                          </li>

                          <li className="py-2">
                            <p className="py-2">
                              <strong>
                                Gestión y Producción Cultural en Danza
                              </strong>
                              : Gestiona la producción cultural en danza a
                              través del uso de las TICS en la creación
                              artística musical y orienta la reflexión sobre los
                              nuevos escenarios de las políticas culturales en
                              nuestro país y la visión que se pueda proyectar en
                              el ámbito global.
                            </p>
                            <a
                              className="text-info"
                              href={
                                danzas_gestion_y_produccion_cultural_en_danza
                              }
                              download={
                                "Danzas - Gestión y Producción Cultural en Danza"
                              }
                            >
                              Descargar Guía de Navegación
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>

                  <div class="">
                    <div class="p-3" id="headingFour">
                      <h6 class="mb-0">
                        <button
                          class="bg-transparent border-0"
                          data-toggle="collapse"
                          data-target="#collapseFour"
                          aria-expanded="true"
                          aria-controls="collapseFour"
                        >
                          Educación para las Artes
                        </button>
                      </h6>
                    </div>

                    <div
                      id="collapseFour"
                      class="collapse show"
                      aria-labelledby="headiFour"
                      data-parent="#accordion"
                    >
                      <div class="card-body">
                        <p>
                          El Programa Nacional de Formación en Educación para
                          las Artes, es uno de los planes de estudio de
                          fundamento curricular más importantes y fundamental de
                          los programas de formación que integran el eje de
                          instrucción de la Universidad Nacional Experimental de
                          las Artes, ya que de ahí se desprende en verdadero y
                          genuino espíritu filosófico deL quehacer de la
                          enseñanza y aprendizaje de todo el universo pedagógico
                          y didáctico cognitivo de las artes en su totalidad. Se
                          genera un intercambio de saberes y las propuestas de
                          educación de acción comunitaria, buscando la
                          consolidación del progreso del saber artístico,
                          cultural, humanístico y académico de los local,
                          regional y universal del nuevo paradigma de educación
                          en las y para las artes.
                        </p>

                        <ul className="py-4 list-unstyled">
                          <p>Menciones y sus competencias profesionales:</p>

                          <li className="py-2">
                            <p className="py-2">
                              <strong>Artes Plásticas</strong>: Genera diálogo
                              intercultural e integración a la diversidad de
                              saberes, en el devenir substancial de una
                              conciencia transformadora, plena hacia la crítica
                              y reflexión de las bases establecidas de los
                              principios curriculares de las Artes Plásticas en
                              la memoria del colectivo que brinde una identidad
                              de país en procesos definitorios en espacios de
                              aprendizaje.
                            </p>
                            <a
                              className="text-info"
                              href={educacion_para_las_artes_artes_plasticas}
                              download={
                                "Educación para las Artes - Artes Plásticas"
                              }
                            >
                              Descargar Guía de Navegación
                            </a>
                          </li>

                          <li className="py-2">
                            <p className="py-2">
                              <strong>Danza</strong>: Estudia, analiza e
                              investiga las teorías, técnico y práctico de la
                              danza, demostrando así aptitudes de la físicas ,
                              cognitivas y capacidad para el desarrollo de la
                              danza tales como: destrezas, psicomotoras,
                              espaciales, expresión artística, manejo versátil
                              del lenguaje corporal y desarrollo intelectual.
                            </p>
                          </li>

                          <li className="py-2">
                            <p className="py-2">
                              <strong>Danza Clásica</strong>: Investiga,
                              experimenta, genera y gerencia los procesos
                              educativos inmersos en la danza clásica.
                              Propiciando sentido crítico e innovador que
                              desarrolla el propio lenguaje discursivo del
                              estudiante mediante la identificación, producción
                              de lo estético de la creación escénica dancística.
                            </p>
                            <a
                              className="text-info"
                              href={educacion_para_las_artes_danza_clasica}
                              download={
                                "Educación para las Artes - Danza Clásica"
                              }
                            >
                              Descargar Guía de Navegación
                            </a>
                          </li>

                          <li className="py-2">
                            <p className="py-2">
                              <strong>Danza Contemporánea</strong>: Concebida
                              como una estructura contemporánea que integra el
                              complejo ámbito de conocimientos, metodologías,
                              técnicas, estéticas y manifestaciones dancísticas
                              enmarcadas dentro de la cultura de un país rico en
                              diversidad como el nuestro desde la pedagogía.
                            </p>
                            <a
                              className="text-info"
                              href={
                                educacion_para_las_artes_danza_contemporanea
                              }
                              download={
                                "Educación para las Artes - Danza Contemporánea"
                              }
                            >
                              Descargar Guía de Navegación
                            </a>
                          </li>

                          <li className="py-2">
                            <p className="py-2">
                              <strong>Danzas Tradicionales Venezolanas</strong>:
                              Desarrolla proyectos y habilidades que resalten
                              las creencias, valores y tradiciones de cada
                              región del país, asimismo impulsará el campo de la
                              enseñanza, incorporando desde el arte de la danza
                              líneas de investigación que apoyen a la
                              construcción de la cultura en los fines planteados
                              en el Plan de la nación para el surgimiento del
                              “nuevo ser” social, protagónico y participativo.
                            </p>
                            <a
                              className="text-info"
                              href={
                                educacion_para_las_artes_danzas_tradicionales_venezolanas
                              }
                              download={
                                "Educación para las Artes - Danzas Tradicionales Venezolanas"
                              }
                            >
                              Descargar Guía de Navegación
                            </a>
                          </li>

                          <li className="py-2">
                            <p className="py-2">
                              <strong>Música</strong>: Aplica métodos, técnicas,
                              procedimientos y recursos de las distintas ramas
                              de la pedagogía y construye soluciones a la
                              problemáticas educativas en el ámbito social.
                              Además investiga, experimenta, genera y gerencia
                              los procesos educativos inmersos en la creación
                              musical.
                            </p>
                            <a
                              className="text-info"
                              href={educacion_para_las_artes_musica}
                              download={"Educación para las Artes - Música"}
                            >
                              Descargar Guía de Navegación
                            </a>
                          </li>

                          <li className="py-2">
                            <p className="py-2">
                              <strong>Teatro</strong>: Participa en las acciones
                              y las prácticas institucionales para el
                              funcionamiento del proceso educativo en las artes.
                              Es un profesional capacitado para conducir
                              actividades educativas a niños, jóvenes, adultos y
                              tercera edad inmerso en la creación escénica.
                            </p>
                            <a
                              className="text-info"
                              href={educacion_para_las_artes_teatro}
                              download={"Educación para las Artes - Teatro"}
                            >
                              Descargar Guía de Navegación
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>

                  <div class="">
                    <div class="p-3" id="headingFive">
                      <h6 class="mb-0">
                        <button
                          class="bg-transparent border-0"
                          data-toggle="collapse"
                          data-target="#collapseFive"
                          aria-expanded="true"
                          aria-controls="collapsFive"
                        >
                          Historia
                        </button>
                      </h6>
                    </div>

                    <div
                      id="collapseFive"
                      class="collapse show"
                      aria-labelledby="headFive"
                      data-parent="#accordion"
                    >
                      <div class="card-body">
                        <p>
                          Este programa nacional de formación está llamado a
                          jugar un rol estelar, porque está concebido con la
                          finalidad de cubrir el vacío existente en nuestro país
                          en torno a los estudios universitarios, del área de
                          historia. En definitiva, una historia construida y
                          reconstruida desde las vivencias y conciencias
                          políticas de la gente del pueblo, haciéndole
                          descubrirse como sujetos actuantes y protagonistas de
                          la sociedad.
                        </p>
                      </div>
                    </div>
                  </div>

                  <div class="">
                    <div class="p-3" id="headingSix">
                      <h6 class="mb-0">
                        <button
                          class="bg-transparent border-0"
                          data-toggle="collapse"
                          data-target="#collapseSix"
                          aria-expanded="true"
                          aria-controls="collapseSix"
                        >
                          Música
                        </button>
                      </h6>
                    </div>

                    <div
                      id="collapseSix"
                      class="collapse show"
                      aria-labelledby="headiSix"
                      data-parent="#accordion"
                    >
                      <div class="card-body">
                        <p>
                          Los profesionales egresados de este programa de
                          formación, podrán desempeñarse en cualquier área del
                          quehacer musical. Están en capacidad de integrar los
                          popular y tradicional de nuestra cultura
                          Latinoamericana y Caribeña en sus creaciones e
                          interpretaciones. También está facultado para crear
                          instancias de trabajo, optimizar los recursos
                          estéticos y profundizar la compleja trama de la
                          formación por la vía de un ejercicio sistemático que
                          una el goce formal con los lenguajes y las técnicas,
                          en la línea de integración del cuerpo, sonido,
                          movimiento, creatividad y tecnología.
                        </p>

                        <ul className="py-4 list-unstyled">
                          <p>Menciones y sus competencias profesionales:</p>

                          <li className="py-2">
                            <p className="py-2">
                              <strong>Canto</strong>:Se capacita a un
                              profesional capaz de interpretar repertorio
                              vocal-académico de cualquier estilo o formato y
                              participa como solista y en grupo de ensambles o
                              agrupaciones de corales y orfeones a nivel
                              comunal, local, regional, nacional e
                              internacional.
                            </p>
                            <div className="d-flex flex-column">
                              <a
                                className="text-info"
                                href={musica_canto_jazz}
                                download={"Música - Canto Jazz"}
                              >
                                Descargar Guía de Navegación(Canto Jazz)
                              </a>
                              <a
                                className="text-info"
                                href={musica_canto_lirico}
                                download={"Música - Canto Lírico"}
                              >
                                Descargar Guía de Navegación(Canto Lírico)
                              </a>
                              <a
                                className="text-info"
                                href={musica_canto_popular}
                                download={"Música - Canto Popular"}
                              >
                                Descargar Guía de Navegación(Canto Popular)
                              </a>
                            </div>
                          </li>

                          <li className="py-2">
                            <p className="py-2">
                              <strong>Composición</strong>: Desarrolla
                              capacidades musicales expresivas en la escritura y
                              composición de la música. Además compone música en
                              formato de cámara, sinfónico, banda o cualquier
                              expresión popular o tradicional en el que desee
                              incursionar, con sentido de responsabilidad como
                              portador y transformador de nuestra cultura
                              musical.
                            </p>
                            <a
                              className="text-info"
                              href={musica_composicion}
                              download={"Música - Composición"}
                            >
                              Descargar Guía de Navegación
                            </a>
                          </li>

                          <li className="py-2">
                            <p className="py-2">
                              <strong>Dirección Coral</strong>: Se capacita a un
                              profesional que dirige ensambles vocales, música
                              de cámara y montajes sinfónicos, interpretando
                              cualquier género musical. Además forma
                              agrupaciones corales al servicio de las
                              comunidades para la promoción de la cultura
                              nacional y universal.
                            </p>
                            <a
                              className="text-info"
                              href={musica_direccion_coral}
                              download={"Música - Dirección Coral"}
                            >
                              Descargar Guía de Navegación
                            </a>
                          </li>

                          <li className="py-2">
                            <p className="py-2">
                              <strong>Dirección Orquestal</strong>: Dirige
                              orquestas en formatos sinfónicos y de cámara e
                              interpretando diferentes géneros y estilos
                              musicales. Debe dominar distintas corrientes
                              estilizadas que le permiten interpretar y ejecutar
                              de manera individual y orquestal la música. Además
                              de dominar la dirección de orquesta con capacidad
                              para poseer una concepción musical propia y
                              trasmitirla al público.
                            </p>
                            <a
                              className="text-info"
                              href={musica_direccion_orquestal}
                              download={"Música - Dirección Orquestal"}
                            >
                              Descargar Guía de Navegación
                            </a>
                          </li>

                          <li className="py-2">
                            <p className="py-2">
                              <strong>Ejecución Instrumental</strong>:
                              Capacitación de un profesional intérprete musical
                              que domina la lectura y escritura de la música en
                              sus diversas formas, géneros y estudios y domina
                              un determinado instrumento musical académico,
                              sinfónico, tradicional o popular.
                            </p>
                            <div className="d-flex flex-column">
                              <a
                                className="text-info"
                                href={musica_ejecucion_instrumental_jazz}
                                download={
                                  "Música - Ejecución Instrumental Jazz"
                                }
                              >
                                Descargar Guía de Navegación(Ejecución
                                Instrumental Jazz)
                              </a>
                              <a
                                className="text-info"
                                href={musica_ejecucion_instrumental_popular}
                                download={
                                  "Música - Ejecución Instrumental Popular"
                                }
                              >
                                Descargar Guía de Navegación (Ejecución
                                Instrumental Popular)
                              </a>
                              <a
                                className="text-info"
                                href={
                                  musica_ejecucion_instrumental_solista_sinfonico
                                }
                                download={
                                  "Música - Ejecución Instrumental Solista Sinfónico"
                                }
                              >
                                Descargar Guía de Navegación (Ejecución
                                Instrumental Solista Sinfónico)
                              </a>
                            </div>
                          </li>

                          <li className="py-2">
                            <p className="py-2">
                              <strong>Musicología</strong>: Realiza
                              investigaciones en el área musical aproximándose
                              al conocimiento desde el punto de vista cultural,
                              histórico y sistemático y produce investigaciones
                              inexploradas, para profundizar en las existentes a
                              fin de ordenar y sistematizar la disciplina, en la
                              consolidación de nuevas tendencias en el
                              pensamiento musical.
                            </p>
                            <a
                              className="text-info"
                              href={musica_musicologia}
                              download={"Música - Musicología"}
                            >
                              Descargar Guía de Navegación
                            </a>
                          </li>

                          <li className="py-2">
                            <p className="py-2">
                              <strong>Producción Musical</strong>: Promociona
                              artísticamente la obra de agrupaciones o solistas
                              a nivel comunal, local, regional, nacional e
                              internacional y realiza proyectos culturales donde
                              se promueve la perspectiva del desarrollo
                              artístico de la música, así como el desarrollo de
                              capacidad para la producción musical electrónica y
                              composición para el cine y diversos medios
                              alternativos comunitarios.
                            </p>
                            <a
                              className="text-info"
                              href={musica_produccion_musical}
                              download={"Música - Producción Musical"}
                            >
                              Descargar Guía de Navegación
                            </a>
                          </li>

                          <li className="py-2">
                            <p className="py-2">
                              <strong>Tradición y Contexto</strong>: Ejecuta
                              procesos formales que permiten el cultivo y
                              registro, de manifestaciones culturales de
                              tradición popular en Venezuela: criolla, afroide,
                              aborigen, mestiza, sincrética, según sea el caso.
                              También dirige proyectos y actividades de
                              preservación y difusión de manifestaciones
                              culturales de tradición popular en nuestro país.
                            </p>
                            <a
                              className="text-info"
                              href={musica_tradicion_y_contexto}
                              download={"Música - Tradición y Contexto"}
                            >
                              Descargar Guía de Navegación
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>

                  <div class="">
                    <div class="p-3" id="headingSix">
                      <h6 class="mb-0">
                        <button
                          class="bg-transparent border-0"
                          data-toggle="collapse"
                          data-target="#collapseSix"
                          aria-expanded="true"
                          aria-controls="collapseSix"
                        >
                          Teatro
                        </button>
                      </h6>
                    </div>

                    <div
                      id="collapseSix"
                      class="collapse show"
                      aria-labelledby="headiSix"
                      data-parent="#accordion"
                    >
                      <div class="card-body">
                        <p>
                          El Programa Nacional de Formación en Teatro ofrece un
                          conjunto de potencialidades, permitiéndole al sujeto
                          en formación expresarse, participar en proyectos
                          comunes vinculados con la diversidad cultural del país
                          y promover líneas de investigación desde las artes
                          escénicas que generen políticas de desarrollo
                          artístico, teatrales y culturales, impulsen la
                          industria cultural, profundice en la búsqueda de una
                          pedagogía teatral, así como el fomento de nuevos
                          lenguajes estéticos.
                        </p>

                        <ul className="py-4 list-unstyled">
                          <p>Menciones y sus competencias profesionales:</p>

                          <li className="py-2">
                            <p className="py-2">
                              <strong>Actuación</strong>: Conoce y utiliza
                              diferentes técnicas de actuación y de
                              entrenamiento en expresión corporal y vocal.
                              Domina los aspectos teóricos y técnicos
                              fundamentales para el análisis del texto
                              dramático. Así como la historia del teatro, sus
                              géneros y creadores principales y reconoce los
                              distintos saberes y tradiciones populares del país
                              y los vincula a su creación y expresión actoral.
                            </p>
                            <div className="d-flex flex-column">
                              <a
                                className="text-info"
                                href={teatro_actuacion}
                                download={"Teatro - Actuación"}
                              >
                                Descargar Guía de Navegación
                              </a>
                            </div>
                          </li>

                          <li className="py-2">
                            <p className="py-2">
                              <strong>Dirección Teatral</strong>: Fomenta la
                              creación que concibe, orienta y conduce todo el
                              proceso de la realización de un montaje teatral,
                              desde los aspectos conceptuales (propuestas
                              estéticas), los de carácter técnico (iluminación,
                              escenografía, sonido, maquillaje, vestuario,
                              recursos audiovisuales), y la orientación del
                              trabajo actoral. Su acción se basa en la
                              investigación, la reflexión, la creatividad y la
                              organización que lo lleven a presentar puestas en
                              escena que se relacionen de manera crítica con su
                              contexto histórico, social y aborden la concreción
                              de su propia dramaturgia escénica.
                            </p>
                            <a
                              className="text-info"
                              href={teatro_direccion_teatral}
                              download={"Teatro - Dirección Teatral"}
                            >
                              Descargar Guía de Navegación
                            </a>
                          </li>

                          <li className="py-2">
                            <p className="py-2">
                              <strong>Diseño Teatral</strong>: Se capacita a un
                              profesional creativo integral capaz de articular
                              lenguajes visuales para crear espacios expresivos
                              y sus componentes (escenografía, utilería,
                              vestuario, maquillaje, iluminación, utilería,
                              entre otros) en el ámbito del teatro, la danza,
                              medios audiovisuales, la música, las artes
                              plásticas, los eventos públicos y la arquitectura
                              efímera.
                            </p>
                            <a
                              className="text-info"
                              href={teatro_diseño_teatral}
                              download={"Teatro - Diseño Teatral"}
                            >
                              Descargar Guía de Navegación
                            </a>
                          </li>

                          <li className="py-2">
                            <p className="py-2">
                              <strong>Dramaturgia</strong>: Instruye a un
                              profesional preparado para construir, adaptar y
                              versionar textos teatrales de diferentes géneros,
                              enfocado en el dominio de la expresión escrita y
                              en el conocimiento de la estructura y componentes
                              del texto dramático. Conoce la historia del teatro
                              y de la dramaturgia venezolana, latinoamericana y
                              universal, su evolución, cambios y desarrollo, así
                              como la de sus personajes más representativos.
                              Conoce las nuevas tendencias de la dramaturgia
                              universal y está preparado para incorporarlas en
                              sus trabajos de investigación y en sus propias
                              creaciones. Comprende las artes teatrales desde el
                              punto de vista social y cultural, consciente de la
                              realidad venezolana, latinoamericana y universal.
                            </p>
                            <a
                              className="text-info"
                              href={teatro_dramaturgia}
                              download={"Teatro - Dramaturgia"}
                            >
                              Descargar Guía de Navegación
                            </a>
                          </li>

                          <li className="py-2">
                            <p className="py-2">
                              <strong>Gerencia y Producción Teatral</strong>:
                              Formular y ejecutar proyectos de carácter
                              artístico y social en el ámbito de las artes
                              escénicas y otras áreas relacionadas, tanto en el
                              sector público como en el privado. Es sensible
                              ante los procesos creativos. Tiene capacidad
                              gerencial, posee herramientas para liderar equipos
                              de trabajo y comprende las artes escénicas desde
                              el punto de vista jurídico, social y cultural.
                            </p>
                            <div className="d-flex flex-column">
                              <a
                                className="text-info"
                                href={teatro_gerencia_y_produccion_teatral}
                                download={
                                  "Teatro - Gerencia y Producción Teatral"
                                }
                              >
                                Descargar Guía de Navegación
                              </a>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div>
                <h4 className="text-info text-center pt-5 mb-0">
                  Planes de Estudio
                </h4>
                <h6 className="text-info text-center">
                  (Centro - Programa - Mención)
                </h6>

                <div id="accordion">
                  <div class="">
                    <div class="p-3" id="headingSeven">
                      <h6 class="mb-0">
                        <button
                          class="bg-transparent border-0"
                          data-toggle="collapse"
                          data-target="#collapseSeven"
                          aria-expanded="true"
                          aria-controls="collapseSeven"
                        >
                          Anzoátegui
                        </button>
                      </h6>
                    </div>

                    <div
                      id="collapseSeven"
                      class="collapse show"
                      aria-labelledby="headingSeven"
                      data-parent="#accordion"
                    >
                      <div class="card-body">
                        <ul className="list-unstyled">
                          <li className="">
                            <h6>
                              Programa Nacional de Formación en Artes Plásticas
                            </h6>
                            <p className="pl-5">• Artes del Diseño</p>
                            <p className="pl-5">• Pintura</p>
                          </li>
                          <li className="py-2">
                            <h6>Programa Nacional de Formación en Danzas</h6>
                            <p className="pl-5">
                              • Danzas Tradicionales Venezolanas
                            </p>
                            <p className="pl-5">
                              • Intérprete de Danza Contemporánea
                            </p>
                          </li>
                          <li className="py-2">
                            <h6>
                              Programa Nacional de Formación en Educación para
                              las Artes:
                            </h6>
                            <p className="pl-5">• Artes Plásticas</p>
                            <p className="pl-5">
                              • Danzas Tradicionales Venezolanas
                            </p>
                            <p className="pl-5">
                              • Intérprete de Danza Contemporánea
                            </p>
                            <p className="pl-5">
                              • Intérprete de Danza Clásica
                            </p>
                            <p className="pl-5">• Música</p>
                            <p className="pl-5">• Teatro</p>
                          </li>

                          <li className="py-2">
                            <h6>Programa Nacional de Formación en Música:</h6>
                            <p className="pl-5">• Canto</p>
                            <p className="pl-5">• Ejecución Instrumental</p>
                          </li>
                          <li className="py-2">
                            <h6>Programa Nacional de Formación en Teatro:</h6>
                            <p className="pl-5">• Actuación</p>
                            <p className="pl-5">• Producción Audiovisual</p>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>

                  <div class="">
                    <div class="p-3" id="heading8">
                      <h6 class="mb-0">
                        <button
                          class="bg-transparent border-0"
                          data-toggle="collapse"
                          data-target="#collapse8"
                          aria-expanded="true"
                          aria-controls="collapse8"
                        >
                          Caracas - Armando Reverón
                        </button>
                      </h6>
                    </div>

                    <div
                      id="collapse8"
                      class="collapse show"
                      aria-labelledby="heading8"
                      data-parent="#accordion"
                    >
                      <div class="card-body">
                        <ul className="list-unstyled">
                          <li className="">
                            <h6>
                              Programa Nacional de Formación en Artes Plásticas:
                            </h6>
                            <p className="pl-5">• Artes del Diseño</p>
                            <p className="pl-5">• Artes Gráficas</p>
                            <p className="pl-5">• Cerámica</p>
                            <p className="pl-5">• Escultura</p>
                            <p className="pl-5">• Fotografía</p>
                            <p className="pl-5">• Medios Mixtos</p>
                            <p className="pl-5">• Museología</p>
                            <p className="pl-5">• Pintura</p>
                          </li>
                          <li className="py-2">
                            <h6>
                              Programa Nacional de Formación en Educación para
                              las Artes:
                            </h6>
                            <p className="pl-5">• Artes Plásticas</p>
                          </li>
                          <li className="py-2">
                            <h6>Programa Nacional de Formación en Historia</h6>
                            <p className="pl-5">• Historia</p>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>

                  <div class="">
                    <div class="p-3" id="heading9">
                      <h6 class="mb-0">
                        <button
                          class="bg-transparent border-0"
                          data-toggle="collapse"
                          data-target="#collapse9"
                          aria-expanded="true"
                          aria-controls="collapse9"
                        >
                          Caracas - Plaza Morelos
                        </button>
                      </h6>
                    </div>

                    <div
                      id="collapse9"
                      class="collapse show"
                      aria-labelledby="heading9"
                      data-parent="#accordion"
                    >
                      <div class="card-body">
                        <ul className="list-unstyled">
                          <li className="">
                            <h6>
                              Programa Nacional de Formación en Artes
                              Audiovisuales:
                            </h6>
                            <p className="pl-5">• Cinematografía</p>
                            <p className="pl-5">• Dirección de Arte</p>
                            <p className="pl-5">• Dirección de Audiovisuales</p>
                            <p className="pl-5">• Diseño de Sonido</p>
                            <p className="pl-5">• Guión Audiovisual</p>
                            <p className="pl-5">• Montaje y Postproducción</p>
                            <p className="pl-5">
                              • Producción de Audiovisuales
                            </p>
                          </li>

                          <li className="py-2">
                            <h6>Programa Nacional de Formación en Danzas:</h6>
                            <p className="pl-5">
                              • Danzas Tradicionales Venezolanas
                            </p>
                            <p className="pl-5">
                              • Gestión y Producción Cultural en Danza
                            </p>
                            <p className="pl-5">
                              • Intérprete de Danza Clásica
                            </p>
                            <p className="pl-5">
                              • Intérprete de Danza Contemporánea
                            </p>
                          </li>

                          <li className="py-2">
                            <h6>
                              Programa Nacional de Formación en Educación para
                              las Artes:
                            </h6>
                            <p className="pl-5">• Danza Clásica</p>
                            <p className="pl-5">• Danza Contemporánea</p>
                            <p className="pl-5">
                              • Danzas Tradicionales Venezolanas
                            </p>
                            <p className="pl-5">• Teatro</p>
                          </li>

                          <li className="py-2">
                            <h6>Programa Nacional de Formación en Teatro:</h6>
                            <p className="pl-5">• Actuación</p>
                            <p className="pl-5">• Dirección Teatral</p>
                            <p className="pl-5">• Diseño Teatral</p>
                            <p className="pl-5">
                              • Gerencia y Producción Teatral
                            </p>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>

                  <div class="">
                    <div class="p-3" id="heading10">
                      <h6 class="mb-0">
                        <button
                          class="bg-transparent border-0"
                          data-toggle="collapse"
                          data-target="#collapse10"
                          aria-expanded="true"
                          aria-controls="collapse10"
                        >
                          Caracas - Sartenejas
                        </button>
                      </h6>
                    </div>

                    <div
                      id="collapse10"
                      class="collapse show"
                      aria-labelledby="heading10"
                      data-parent="#accordion"
                    >
                      <div class="card-body">
                        <ul className="list-unstyled">
                          <li className="">
                            <h6>
                              Programa Nacional de Formación en Educación para
                              las Artes:
                            </h6>
                            <p className="pl-5">• Música</p>
                          </li>

                          <li className="py-2">
                            <h6>Programa Nacional de Formación en Música:</h6>
                            <p className="pl-5">• Canto</p>
                            <p className="pl-5">• Composición</p>
                            <p className="pl-5">• Dirección Oral</p>
                            <p className="pl-5">• Dirección Orquestal</p>
                            <p className="pl-5">• Ejecución Instrumental</p>
                            <p className="pl-5">• Musicología</p>
                            <p className="pl-5">• Producción Musical</p>
                            <p className="pl-5">• Tradición y Contexto</p>
                          </li>

                          <li className="py-2">
                            <h6>
                              Programa Nacional de Formación en Educación para
                              las Artes:
                            </h6>
                            <p className="pl-5">• Danza Clásica</p>
                            <p className="pl-5">• Danza Contemporánea</p>
                            <p className="pl-5">
                              • Danzas Tradicionales Venezolanas
                            </p>
                            <p className="pl-5">• Teatro</p>
                          </li>

                          <li className="py-2">
                            <h6>Programa Nacional de Formación en Teatro:</h6>
                            <p className="pl-5">• Actuación</p>
                            <p className="pl-5">• Dirección Teatral</p>
                            <p className="pl-5">• Diseño Teatral</p>
                            <p className="pl-5">
                              • Gerencia y Producción Teatral
                            </p>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>

                  <div class="">
                    <div class="p-3" id="heading11">
                      <h6 class="mb-0">
                        <button
                          class="bg-transparent border-0"
                          data-toggle="collapse"
                          data-target="#collapse11"
                          aria-expanded="true"
                          aria-controls="collapse11"
                        >
                          Mérida
                        </button>
                      </h6>
                    </div>

                    <div
                      id="collapse11"
                      class="collapse show"
                      aria-labelledby="heading11"
                      data-parent="#accordion"
                    >
                      <div class="card-body">
                        <ul className="list-unstyled">
                          <li className="">
                            <h6>
                              Programa Nacional de Formación en Artes
                              Audiovisuales:
                            </h6>
                            <p className="pl-5">• Producción Audiovisual</p>
                          </li>

                          <li className="py-2">
                            <h6>
                              Programa Nacional de Formación en Artes Plásticas:
                            </h6>
                            <p className="pl-5">• Fotografía</p>
                            <p className="pl-5">• Pintura</p>
                          </li>

                          <li className="py-2">
                            <h6>Programa Nacional de Formación en Danzas:</h6>
                            <p className="pl-5">
                              • Danzas Tradicionales Venezolanas
                            </p>
                            <p className="pl-5">• Danza Contemporánea</p>
                          </li>

                          <li className="py-2">
                            <h6>
                              Programa Nacional de Formación en Educación para
                              las Artes:
                            </h6>
                            <p className="pl-5">• Artes Plásticas</p>
                            <p className="pl-5">
                              • Danzas Tradicionales Venezolanas
                            </p>
                            <p className="pl-5">
                              • Intérprete de Danza Comtemporánea
                            </p>
                            <p className="pl-5">
                              • Intérprete de Danza Clásica
                            </p>
                            <p className="pl-5">• Música</p>
                            <p className="pl-5">• Teatro</p>
                          </li>

                          <li className="py-2">
                            <h6>Programa Nacional de Formación en Música:</h6>
                            <p className="pl-5">• Canto</p>
                            <p className="pl-5">• Dirección Oral</p>
                            <p className="pl-5">• Ejecución Instrumental</p>
                            <p className="pl-5">• Musicología</p>
                          </li>

                          <li className="py-2">
                            <h6>Programa Nacional de Formación en Teatro:</h6>
                            <p className="pl-5">• Actuación</p>
                            <p className="pl-5">• Dirección Teatral</p>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>

                  <div class="">
                    <div class="p-3" id="heading12">
                      <h6 class="mb-0">
                        <button
                          class="bg-transparent border-0"
                          data-toggle="collapse"
                          data-target="#collapse12"
                          aria-expanded="true"
                          aria-controls="collapse12"
                        >
                          Nueva Esparta - Santiago Mariño
                        </button>
                      </h6>
                    </div>

                    <div
                      id="collapse12"
                      class="collapse show"
                      aria-labelledby="heading12"
                      data-parent="#accordion"
                    >
                      <div class="card-body">
                        <ul className="list-unstyled">
                          <li className="">
                            <h6>
                              Programa Nacional de Formación en Artes Plásticas:
                            </h6>
                            <p className="pl-5">• Escultura</p>
                            <p className="pl-5">• Pintura</p>
                          </li>

                          <li className="py-2">
                            <h6>Programa Nacional de Formación en Danzas:</h6>
                            <p className="pl-5">
                              • Danzas Tradicionales Venezolanas
                            </p>
                            <p className="pl-5">
                              • Intérprete de Danza Contemporánea
                            </p>
                          </li>

                          <li className="py-2">
                            <h6>Programa Nacional de Formación en Danzas:</h6>
                            <p className="pl-5">
                              • Danzas Tradicionales Venezolanas
                            </p>
                            <p className="pl-5">• Danza Contemporánea</p>
                          </li>

                          <li className="py-2">
                            <h6>
                              Programa Nacional de Formación en Educación para
                              las Artes:
                            </h6>
                            <p className="pl-5">• Artes Plásticas</p>
                            <p className="pl-5">
                              • Danzas Tradicionales Venezolanas
                            </p>

                            <p className="pl-5">
                              • Intérprete de Danza Comtemporánea
                            </p>
                            <p className="pl-5">
                              • Intérprete de Danza Clásica
                            </p>
                            <p className="pl-5">• Música</p>
                            <p className="pl-5">• Teatro</p>
                          </li>

                          <li className="py-2">
                            <h6>Programa Nacional de Formación en Música:</h6>
                            <p className="pl-5">• Canto</p>
                            <p className="pl-5">• Ejecución Instrumental</p>
                          </li>

                          <li className="py-2">
                            <h6>Programa Nacional de Formación en Teatro:</h6>
                            <p className="pl-5">• Actuación</p>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>

                  <div class="">
                    <div class="p-3" id="heading13">
                      <h6 class="mb-0">
                        <button
                          class="bg-transparent border-0"
                          data-toggle="collapse"
                          data-target="#collapse13"
                          aria-expanded="true"
                          aria-controls="collapse13"
                        >
                          Portuguesa
                        </button>
                      </h6>
                    </div>

                    <div
                      id="collapse13"
                      class="collapse show"
                      aria-labelledby="heading13"
                      data-parent="#accordion"
                    >
                      <div class="card-body">
                        <ul className="list-unstyled">
                          <li className="">
                            <h6>
                              Programa Nacional de Formación en Artes
                              Audiovisuales:
                            </h6>
                            <p className="pl-5">• Producción Audiovisual</p>
                          </li>

                          <li className="py-2">
                            <h6>
                              Programa Nacional de Formación en Artes Plásticas:
                            </h6>
                            <p className="pl-5">• Fotografía</p>
                            <p className="pl-5">• Pintura</p>
                          </li>

                          <li className="py-2">
                            <h6>Programa Nacional de Formación en Danzas:</h6>
                            <p className="pl-5">
                              • Danzas Tradicionales Venezolanas
                            </p>
                            <p className="pl-5">• Danza Contemporánea</p>
                          </li>

                          <li className="py-2">
                            <h6>
                              Programa Nacional de Formación en Educación para
                              las Artes:
                            </h6>
                            <p className="pl-5">• Artes Plásticas</p>
                            <p className="pl-5">
                              • Danzas Tradicionales Venezolanas
                            </p>

                            <p className="pl-5">
                              • Intérprete de Danza Comtemporánea
                            </p>
                            <p className="pl-5">• Música</p>
                            <p className="pl-5">• Teatro</p>
                          </li>

                          <li className="py-2">
                            <h6>Programa Nacional de Formación en Música:</h6>
                            <p className="pl-5">• Canto</p>
                            <p className="pl-5">• Ejecución Instrumental</p>
                            <p className="pl-5">• Musicología</p>
                          </li>

                          <li className="py-2">
                            <h6>Programa Nacional de Formación en Teatro:</h6>
                            <p className="pl-5">• Actuación</p>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              class="tab-pane fade p-5"
              id="nav-estructura"
              role="tabpanel"
              aria-labelledby="nav-estructura-tab"
            >
              <Estructura />
            </div>

            <div
              class="tab-pane fade p-5"
              id="nav-objetivos"
              role="tabpanel"
              aria-labelledby="nav-objetivos-tab"
            >
              <p className="pb-3">
                {asteriskSVG} Constituir redes de conocimiento y aprendizaje
                para la generación, transformación y apropiación social del
                conocimiento.
              </p>

              <p className="pb-3">
                {asteriskSVG} Promover activamente la articulación y cooperación
                solidaria entre las instituciones de educación superior.
              </p>

              <p className="pb-3">
                {asteriskSVG} Vincular la educación universitaria con los
                organismos del Estado, empresas y organizaciones sociales, en
                función de la pertinencia de la formación y la creación
                intelectual en el área.
              </p>

              <p className="pb-3">
                {asteriskSVG} Garantizar la movilidad nacional de estudiantes,
                profesores y profesoras; la producción, distribución y uso
                compartido de recursos educativos; así como la formación
                avanzada de profesores, profesoras y otros profesionales.
              </p>

              <p className="pb-3">
                {asteriskSVG} La formación humanista como aspecto de vital
                importancia para la formación integral, sustentada en la
                integración de contenidos y experiencias dirigidas a la
                formación en el ejercicio de la ciudadanía democrática, la
                solidaridad, la construcción colectiva y la acción profesional
                transformadora con responsabilidad ética y perspectiva
                sustentable.
              </p>

              <p className="pb-3">
                {asteriskSVG} La vinculación con las comunidades y el ejercicio
                profesional a lo largo de todo el trayecto formativo; el
                abordaje de la complejidad de los problemas en contextos reales
                con la participación de actores diversos; la consideración de la
                multidimensionalidad de los temas y problemas de estudio; así
                como el trabajo en equipos interdisciplinarios.
              </p>

              <p className="pb-3">
                {asteriskSVG} El desarrollo de visiones de conjunto,
                actualizadas y orgánicas de los campos de estudio, en
                perspectiva histórica, y apoyadas en soportes epistemológicos
                coherentes y críticamente fundados.
              </p>
              <p className="pb-3">
                {asteriskSVG} La conformación de los ambientes educativos como
                espacios comunicacionales abiertos, caracterizados por la libre
                expresión y el debate de las ideas, el respeto y la valoración
                de la diversidad, la multiplicidad de fuentes de información, la
                integración de todos los participantes como interlocutores y la
                reivindicación de la reflexión como elementos indispensables
                para la formación, asociados a ambientes de formación y
                prácticas educativas ligados a las necesidades y características
                de las distintas localidades que propicien el vínculo con la
                vida social y productiva.
              </p>

              <p className="pb-3">
                {asteriskSVG} La participación activa y comprometida de los
                estudiantes en los procesos de creación intelectual y
                vinculación social, relacionados con investigaciones e
                innovaciones educativas vinculadas con el perfil de desempeño
                profesional y conducentes a la solución de los problemas del
                entorno, en consideración de sus dimensiones éticas, políticas,
                culturales, sociales, económicas, técnicas y científicas,
                garantizando la independencia cognoscitiva y la creatividad de
                los estudiantes.
              </p>

              <p className="pb-3">
                {asteriskSVG} Modalidades curriculares flexibles, adaptadas a
                las distintas necesidades educativas, a las diferentes
                disponibilidades de tiempo para el estudio, a los recursos
                disponibles, a las características de cada municipio y al empleo
                de métodos de enseñanza que activen los modos de actuación
                profesional.
              </p>

              <p className="pb-3">
                {asteriskSVG} La definición de sistemas de evaluación que
                promuevan el aprendizaje, la reflexión y el mejoramiento
                continúo, considerando los distintos actores y aspectos del
                quehacer educativo y valorando su impacto social.
              </p>

              <p className="pb-3">
                {asteriskSVG} La promoción, el reconocimiento y la acreditación
                de experiencias formativas en distintos ámbitos.
              </p>
            </div>

            <div
              class="tab-pane fade p-5"
              id="nav-principios"
              role="tabpanel"
              aria-labelledby="nav-principios-tab"
            >
              <p className="pb-3">
                {asteriskSVG} Vinculación con los lineamientos establecidos en
                el Plan de Desarrollo Económico y Social de la Nación.
              </p>

              <p className="pb-3">
                {asteriskSVG} Formación integral del o la participante en el
                desarrollo de los saberes desde la construcción colectiva de la
                acción educativa transformadora que impulsará tanto el trabajo
                liberador como el desarrollo socio-productivo del país.
              </p>

              <p className="pb-3">
                {asteriskSVG} Dinamización del currículo, a través de los
                proyectos sociointegradores, que potencien la vinculación
                integral e integradora con las comunidades y la profesionalidad
                a lo largo de todo el trayecto formativo mediante la interacción
                e intercambio de experiencias y conocimientos en los distintos
                espacios territoriales, garantizando así la pertinencia.{" "}
              </p>

              <p className="pb-3">
                {asteriskSVG} Creación de espacios educativos abiertos asociados
                a necesidades y características geoterritoriales, que permitan
                la expresión del Poder Popular de todas y todos los
                participantes a fin de promover un diálogo de saberes,
                profundizando el ejercicio democrático caracterizado por el
                encuentro y debate de las ideas, la valoración a la diversidad,
                la multiplicidad de fuentes de información. La integración de
                todos los participantes, como elementos indispensables para la
                formación.
              </p>

              <p className="pb-3">
                {asteriskSVG} Participación activa y comprometida de los actores
                en los procesos de creación intelectual y vinculación
                territorial relacionados con investigaciones e innovaciones
                educativas comprometidas con el perfil de su futuro desempeño y
                conducentes a la presentación de propuestas de solución a los
                problemas socioterritoriales por la vía científica, artística,
                humanística y tecnológica a través del diálogo de saberes.
              </p>

              <p className="pb-3">
                {asteriskSVG} Concepción sociocultural crítica del currículo,
                promoviendo y facilitando modalidades curriculares flexibles,
                adaptadas a las distintas necesidades educativas, a los recursos
                disponibles, a la disponibilidad de tiempo y a las
                características geohistóricas propias de cada comunidad, estado
                o región.
              </p>

              <p className="pb-3">
                {asteriskSVG} Promoción de una pedagogía y didáctica en una
                concepción sociocultural crítica que permita la construcción de
                los aprendizajes significativos en todas y todos los
                participantes; donde se valora la cultura, los saberes y las
                experiencias como aportes al conocimiento, permitiendo así el
                rol de un docente que guía proyectos de vida en unión con los
                estudiantes, bajo principios de la democracia participativa y
                protagónica
              </p>

              <p className="pb-3">
                {asteriskSVG} Promoción, reconocimiento y acreditación de las
                experiencias de aprendizajes en distintos ámbitos.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Formacion;
